import React from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
} from 'react-flow-renderer';

function ERDTable({
  nodes,
  edges,
  nodeTypes,
  onConnect,
  onNodesChange,
  onEdgesChange,
  onLoad,
  onNodeDoubleClick,
  onEdgeDoubleClick,
  handleContextMenu,
  onDeleteEntity, // Pass the delete handler
}) {
  
  return (
    <div className="col-12" style={{ height: '70vh' }}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onLoad={onLoad}
          onNodeDoubleClick={onNodeDoubleClick}
          onEdgeDoubleClick={onEdgeDoubleClick}
          onEdgeContextMenu={handleContextMenu}
          style={{ width: '100%', height: '100%' }}
          connectionLineType="SmoothStep"
        >
          <MiniMap />
          <Controls />
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
}

export default ERDTable;
