import React, { useState } from 'react';
import axios from '../axiosConfig';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css';

const API_URL = process.env.REACT_APP_API_URL;

const SendMessage = ({ groupId, onMessageSent }) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const organizationId = localStorage.getItem('company'); // Include organization ID

  const handleSendMessage = async () => {
    try {
      const messageData = {
        subject,
        body,
        groupId,
        receiverId: localStorage.getItem('user'),
        senderId: localStorage.getItem('user'),
        organizationId,
      };
      await axios.post(`${API_URL}/groups/send-message`, messageData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSubject('');
      setBody('');
      toast.success('Message sent successfully'); // Show success notification
      if (onMessageSent) onMessageSent(); // Call the parent function to close modal
    } catch (error) {
      toast.error('Failed to send message'); // Show error notification
    }
  };

  return (
    <div>
      <Form>
        <Form.Group controlId="messageSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="messageBody">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            maxRows={6}
            placeholder="Enter your message"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Form.Group>
        <br />
        <Button variant="primary" onClick={handleSendMessage}>
          Send Message
        </Button>
      </Form>
    </div>
  );
};

export default SendMessage;
