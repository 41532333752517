import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function ThemeManagement() {
  const [themes, setThemes] = useState([]);
  const [newTheme, setNewTheme] = useState('');
  const [showAddThemeModal, setShowAddThemeModal] = useState(false);

  useEffect(() => {
    fetchThemes();
  }, []);

  const fetchThemes = async () => {
    try {
      const response = await axios.get(`${API_URL}/themes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setThemes(response.data);
    } catch (error) {
      toast.error('Failed to fetch themes');
    }
  };

  const handleAddTheme = async () => {
    try {
      await axios.post(`${API_URL}/themes`, { name: newTheme }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setNewTheme('');
      setShowAddThemeModal(false);
      fetchThemes();
      toast.success('Theme added successfully');
    } catch (error) {
      toast.error('Failed to add theme');
    }
  };

  const handleDeleteTheme = async (themeId) => {
    try {
      await axios.delete(`${API_URL}/themes/${themeId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchThemes();
      toast.success('Theme deleted successfully');
    } catch (error) {
      toast.error('Failed to delete theme');
    }
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Theme Management</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowAddThemeModal(true)}>
                  <i className="fas fa-plus"></i> Add Theme
                </Button>
              </div>
            </div>
            <div className="row" style={{width: "550px"}}>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{width: "20px"}}>ID</th>
                          <th>Name</th>
                          <th style={{width: "20px"}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {themes.map((theme) => (
                          <tr key={theme.id}>
                            <td>{theme.id}</td>
                            <td>{theme.name}</td>
                            <td>
                              <Button variant="danger" onClick={() => handleDeleteTheme(theme.id)}>
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Theme Modal */}
      <Modal show={showAddThemeModal} onHide={() => setShowAddThemeModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Add Theme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Theme Name"
            value={newTheme}
            onChange={(e) => setNewTheme(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddThemeModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddTheme}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default ThemeManagement;
