import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from '../axiosConfig';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

function RoleSetting({ show, handleClose, editData, fetchRoleSettings }) {
  const [roles, setRoles] = useState([]);
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState({
    role_id: localStorage.getItem('role'),
    form_id: '',
    is_edit: false,
    is_delete: false,
    is_clone: false,
    is_view: false,
    is_create: false,
    is_csv_download: false,
    is_xlsx_download: false,
    is_pdf_download: false,
    is_excel_upload: false,
    is_csv_upload: false,
    is_audit: false
  });

  useEffect(() => {
    fetchRoles();
    fetchForms();
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  };

  const fetchForms = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/all`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setForms(response.data);
    } catch (error) {
      toast.error('Failed to fetch forms');
    }
  };

  const handleSubmit = async () => {
    try {
      if (editData) {
        await axios.put(`${API_URL}/role-settings/${editData.id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        toast.success('Role setting updated successfully');
      } else {
        await axios.post(`${API_URL}/role-settings`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        toast.success('Role setting added successfully');
      }
      fetchRoleSettings();
      handleClose();
    } catch (error) {
      toast.error('Failed to save role setting');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>{editData ? 'Edit Role Setting' : 'Add Role Setting'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Role</Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                name="role_id"
                value={formData.role_id}
                onChange={handleChange}
              >
                <option value="">Select Role</option>
                {roles.map(role => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={3}>Form</Form.Label>
            <Col sm={9}>
              <Form.Control
                as="select"
                name="form_id"
                value={formData.form_id}
                onChange={handleChange}
              >
                <option value="">Select Form</option>
                {forms.map(form => (
                  <option key={form.id} value={form.id}>{form.orgname+" - "+form.name}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group>
            <Form.Label>Permissions</Form.Label>
            <Form.Check
              type="checkbox"
              label="Edit"
              name="is_edit"
              checked={formData.is_edit}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Delete"
              name="is_delete"
              checked={formData.is_delete}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Clone"
              name="is_clone"
              checked={formData.is_clone}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="View"
              name="is_view"
              checked={formData.is_view}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Create"
              name="is_create"
              checked={formData.is_create}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="CSV Download"
              name="is_csv_download"
              checked={formData.is_csv_download}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="XLSX Download"
              name="is_xlsx_download"
              checked={formData.is_xlsx_download}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="PDF Download"
              name="is_pdf_download"
              checked={formData.is_pdf_download}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Excel Upload"
              name="is_excel_upload"
              checked={formData.is_excel_upload}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="CSV Upload"
              name="is_csv_upload"
              checked={formData.is_csv_upload}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label="Audit"
              name="is_audit"
              checked={formData.is_audit}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RoleSetting;
