import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axiosConfig';
import { Modal, Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ReportBuilder.css'; // Create and import your custom CSS file for styling
import Layout from '../Layout';
import ReportList from './ReportList'

const ReportBuilder = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [entities, setEntities] = useState([]);
  const [rows, setRows] = useState([{ entity: '', joinType: 'Parent', attributes: [] }]);
  const [reportName, setReportName] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const reportNameInput = useRef(null); // Reference for report name input field

  // Fetch organizationId, userId, and token from localStorage
  const organizationId = localStorage.getItem('company');
  const userId = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchEntities();
  }, [organizationId]);

  useEffect(() => {
    if (reportNameInput.current) {
      reportNameInput.current.focus(); // Focus on the report name input field when component loads
    }
  }, [showAttributeModal]);

  // Fetch entities from the backend
  const fetchEntities = async () => {
    try {
        const response = await axios.get(`${API_URL}/erds/entities`, {
        params: { organizationId },
        headers: { Authorization: `Bearer ${token}` }
      });
      setEntities(response.data.nodes);
    } catch (error) {
      toast.error('Failed to fetch entities');
    }
  };

  const handleAddRow = () => {
    if (rows.length >= 5) {
      toast.error('You cannot add more than 5 rows');
      return;
    }
    setRows([...rows, { entity: '', joinType: '', attributes: [] }]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleEntityChange = (index, entityName) => {
    const updatedRows = [...rows];
    updatedRows[index].entity = entityName;
    setRows(updatedRows);
  };

  const handleJoinTypeChange = (index, joinType) => {
    const updatedRows = [...rows];
    updatedRows[index].joinType = joinType;
    setRows(updatedRows);
  };

  const handleAttributeSelection = (index) => {
    setCurrentRowIndex(index);
    setSelectedEntity(rows[index].entity);
    setShowAttributeModal(true);
  };

  const handleSaveAttributes = (selectedAttributes) => {
    const updatedRows = [...rows];
    updatedRows[currentRowIndex].attributes = selectedAttributes;
    setRows(updatedRows);
    setShowAttributeModal(false);
  };

  const handleSaveReport = async () => {
    if (!reportName.trim()) {
      toast.error('Please enter a report name.');
      reportNameInput.current.focus(); // Focus on the report name input field
      return;
    }

    try {
      const reportJson = {
        select: rows.map((row) => ({
          table: row.entity,
          columns: row.attributes
        })),
        joins: rows.slice(1).map((row, index) => ({
          type: row.joinType,
          source: {
            table: rows[index].entity,
            column: 'ID' // Replace with actual source column
          },
          target: {
            table: row.entity,
            column: 'ID' // Replace with actual target column
          }
        }))
      };

      await axios.post(`${API_URL}/reports/saveReport`, {
        reportName,
        reportJson,
        createdBy: userId,
        updatedBy: userId,
        organizationId
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Report saved successfully');
    } catch (error) {
      toast.error('Failed to save report');
    }
  };

  const handleRunReport = async (reportId) => {
    try {
        const response = await axios.post(`${API_URL}/reports/runReport`, {
        reportId,
        organizationId
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Report Result:', response.data);
      // Display the result in your preferred format
    } catch (error) {
      toast.error('Failed to run report');
    }
  };

  return (
      <Layout>
           <div className="content-wrapper">
      <div className="content">
        <div className="container-fluid">
    <Container className="report-builder">
      <h2 className="text-center my-4">Report Builder</h2>
      <Card className="mb-4">
        <Card.Body>
          <Form>
            <Form.Group as={Row} controlId="formReportName">
              <Form.Label column sm="2">Report Name</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Enter Report Name"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  ref={reportNameInput}
                />
              </Col>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          {rows.map((row, index) => (
            <Form key={index} className="mb-3">
              <Row>
            {index!=0&& (
              <Col md={4}>
                  <Form.Group controlId={`joinTypeSelect${index}`}>
                    <Form.Label>Join Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={row.joinType}
                      onChange={(e) => handleJoinTypeChange(index, e.target.value)}
                      disabled={index === 0} // First row join type is always 'Parent' and cannot be changed
                    >
                      
                      {index > 0 && <option value="INNER JOIN">Inner Join</option>}
                      {index > 0 && <option value="LEFT JOIN">Left Join</option>}
                    </Form.Control>
                  </Form.Group>
                </Col>
            )}
                <Col md={4}>
                  <Form.Group controlId={`entitySelect${index}`}>
                    <Form.Label>Entity</Form.Label>
                    <Form.Control
                      as="select"
                      value={row.entity}
                      onChange={(e) => handleEntityChange(index, e.target.value)}
                    >
                      <option value="">Select Entity</option>
                      {entities.map((entity) => (
                        <option key={entity.id} value={entity.entity}>
                          {entity.entity}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              
                <Col md={4} className="d-flex align-items-end">
                  <Button
                    variant="primary"
                    onClick={() => handleAttributeSelection(index)}
                    disabled={!row.entity}
                  >
                    {row.attributes.length > 0 ? 'Edit Attributes' : 'Select Attributes'}
                  </Button>
                  {index > 0 && (
                    <Button
                      variant="danger"
                      className="ml-2"
                      onClick={() => handleDeleteRow(index)}
                    >
                      Delete
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          ))}
          <Button variant="success" onClick={handleAddRow} className="mb-3">
            Add Row
          </Button>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body className="text-center">
          <Button variant="primary" onClick={handleSaveReport}>
            Save Report
          </Button>
          <Button variant="secondary" onClick={() => handleRunReport('report-id')} className="ml-2">
            Run Report
          </Button>
        </Card.Body>
      </Card>
      <ReportList/>
      <AttributeModal
        show={showAttributeModal}
        onHide={() => setShowAttributeModal(false)}
        entity={selectedEntity}
        onSave={handleSaveAttributes}
        entities={entities}
      />

      <ToastContainer position="top-right" autoClose={5000} />
    </Container>
	  </div>
      </div>
    </div>
  
    </Layout>
  );
};

// Attribute Modal Component
const AttributeModal = ({ show, onHide, entity, onSave, entities }) => {
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  useEffect(() => {
    if (entity) {
      const selectedEntity = entities.find((e) => e.entity === entity);
      setAttributes(selectedEntity ? selectedEntity.attributes : []);
    }
  }, [entity, entities]);

  const handleAttributeToggle = (attribute) => {
    setSelectedAttributes((prev) =>
      prev.includes(attribute)
        ? prev.filter((attr) => attr !== attribute)
        : [...prev, attribute]
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Select Attributes for {entity}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {attributes.map((attribute) => (
          <div key={attribute.name}>
            <input
              type="checkbox"
              checked={selectedAttributes.includes(attribute.name)}
              onChange={() => handleAttributeToggle(attribute.name)}
            />
            {attribute.name}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => onSave(selectedAttributes)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportBuilder;
