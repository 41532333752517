import React, { useState, useEffect } from 'react';
import axios from '../../axiosConfig';
import { Button } from 'react-bootstrap';


const ReportList = ({ organizationId }) => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    fetchReports();
  }, [organizationId]);

  const fetchReports = async () => {
    try {
      const response = await axios.get(`/api/getReportsByOrganization`, {
        params: { organizationId }
      });
      setReports(response.data);
    } catch (error) {
      console.error('Failed to fetch reports', error);
    }
  };

  return (
    <div className="report-list">
      <h3>Reports List</h3>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Updated By</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <tr key={report.id}>
              <td>{report.id}</td>
              <td>{report.name}</td>
              <td>{report.updated_by || 'N/A'}</td>
              <td>{report.updated_at }</td>
              <td>
                {/* Add Edit or Run Button Actions */}
                <Button variant="info" onClick={() => alert(`Edit Report: ${report.id}`)}>
                  Edit
                </Button>
                {' '}
                <Button variant="success" onClick={() => alert(`Run Report: ${report.id}`)}>
                  Run
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportList;
