import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuStructure from './MenuStructure';
import axios from '../axiosConfig';

const API_URL = process.env.REACT_APP_API_URL;

function Sidebar() {
  const [orgLogo, setOrgLogo] = useState(null); // State for organization logo
  const [orgName, setOrgName] = useState(''); // State for organization name
  const defaultApp = JSON.parse(localStorage.getItem('defaultApp'));
  useEffect(() => {
    const fetchOrgData = async () => {
      // Check if org data exists in localStorage
      const storedOrgLogo = localStorage.getItem('orgLogo');
      const storedOrgName = localStorage.getItem('orgName');
  
      if (storedOrgLogo && storedOrgName) {
        // Use data from localStorage
        setOrgLogo(storedOrgLogo);
        setOrgName(storedOrgName);
      } else {
        // Fetch data from server if not found in localStorage
        try {
          const response = await axios.get(
            `${API_URL}/organizations/${localStorage.getItem('company').trim()}`,
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            }
          );
  
          const organization = response.data;
          const orgLogoUrl = organization.logo
            ? `${API_URL.replace('/api/', '/')}${organization.logo}`
            : null;
  
          // Update state with fetched data
          setOrgLogo(orgLogoUrl);
          setOrgName(organization.name);
  
          // Store organization data in localStorage
          localStorage.setItem('orgLogo', orgLogoUrl || '');
          localStorage.setItem('orgName', organization.name);
        } catch (error) {
          console.error('Failed to fetch organization data', error);
        }
      }
    };
  
    fetchOrgData();
  }, []);
  

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 bg-primary">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',paddingTop:'10px' }}>
 
       {orgLogo ? (
          <img
            id="app-logo"
            src={orgLogo}
            alt={orgName || "Organization Logo"}
            className="brand-image img-circle elevation-3"
            style={{ opacity: '.8', width:'120px',height:'120px' }}
          />
        ) : (
          <i className="fas fa-image brand-image img-circle elevation-3" style={{ fontSize: '150px' }}></i>
        )}
         </div>
         <div className="brand-link">
       
        <span className="brand-text font-weight-light" id="app-name">
          {orgName || "Default Organization"}
        </span>
      </div>

      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <MenuStructure />
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
