import React from 'react';
import DashboardTile from './DashboardTile';  // Import the DashboardTile component
import Layout from '../Layout';  // Assuming Layout component is used for page layout

const Dashboard = () => {
  return (
    <Layout>
    <div className="content-wrapper">
      <div className="content">
        <div className="container-fluid">
          <DashboardTile /> {/* Render the DashboardTile component */}
        </div>
      </div>
    </div>
    </Layout>
  );
};

// Add this line if it's missing to export the Dashboard component as default
export default Dashboard;