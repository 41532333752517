import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../../axiosConfig';

const CloneComponent = ({ formId, submission, fetchSubmissions, roleSettings }) => {
  const handleCloneSubmission = async () => {
    try {
      const clonedSubmission = { ...submission.submission_data };
      await axios.post(`${process.env.REACT_APP_API_URL}/forms/${formId}/submissions`, { submission: clonedSubmission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Submission cloned successfully');
      fetchSubmissions();
    } catch (error) {
      toast.error('Failed to clone submission');
    }
  };

  return (
    roleSettings?.is_clone && (
      <Button variant="primary" onClick={handleCloneSubmission} className="me-2">Clone     
      </Button>
    )
  );
};

export default CloneComponent;
