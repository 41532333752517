import React, { useState } from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Card className="mb-3">
            <Card.Header
                className="d-flex align-items-center"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    cursor: 'pointer',
                    justifyContent: 'space-between',
                }}
            >
                <span>{title}</span>
                <span className="ml-auto">
                    <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                </span>
            </Card.Header>
            <Collapse in={isOpen}>
                <div>
                    <Card.Body>{children}</Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};

export default Accordion;
