import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ImportExport = ({ onImport, onExport,isButtonDisabled }) => {
  const [showModal, setShowModal] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [jsonFile, setJsonFile] = useState(null);

  const handleFileChange = (e) => {
    setJsonFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!applicationName.trim()) {
      toast.error('Please provide an application name.');
      return;
    }
    if (!jsonFile) {
      toast.error('Please upload a JSON file.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result);
        onImport(applicationName, jsonData);
        //toast.success('JSON data imported successfully!');
        setShowModal(false);
        setApplicationName('');
        setJsonFile(null);
      } catch (error) {
        toast.error('Invalid JSON file!');
      }
    };
    reader.readAsText(jsonFile);
  };

  return (
    <div style={{ margin: '10px 0' }}>
      <Button variant="primary" onClick={onExport} style={{ marginRight: '10px' }} >
        Download App JSON
      </Button>
      <Button variant="primary" onClick={() => setShowModal(true)} disabled={isButtonDisabled}>
        Upload App JSON
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload JSON</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Application Name</Form.Label>
            <Form.Control
              type="text"
              value={applicationName}
              onChange={(e) => setApplicationName(e.target.value)}
              placeholder="Enter application name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>JSON File</Form.Label>
            <Form.Control type="file" accept="application/json" onChange={handleFileChange} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportExport;
