import React, { useState, useEffect, useRef } from 'react';
import axios from '../axiosConfig';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Button, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Loader from './BLoader'; // Import the Loader component
const API_URL = process.env.REACT_APP_API_URL;
const demoid = process.env.REACT_APP_DEMOID;

function Profile() {
  const [user, setUser] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sex, setSex] = useState('');
  const [department, setDepartment] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const history = useHistory();
  const [specialNotes, setSpecialNotes] = useState('');

  const [isLoading, setIsLoading] = useState(false); // New state for loading
  // Refs for input fields to focus on errors
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const sexRef = useRef();
  const departmentRef = useRef();
  const dateOfBirthRef = useRef();
  const phoneNumberRef = useRef();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable logic
  const role = localStorage.getItem('role'); // Assuming the role is stored in 'role'


  useEffect(() => {
    fetchUserData();
    if (role && demoid && role === demoid) { // Adjust role check as needed
      setIsButtonDisabled(true);
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const userData = response.data;
      setUser(userData);

      setEmail(userData.email);
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setSex(userData.sex);
      setDepartment(userData.department);
      setDateOfBirth(userData.date_of_birth);
      setPhoneNumber(userData.phone_number);

      if (userData.profile_photo) {
        const photoUrl = `${API_URL.replace('/api/', '/')}${userData.profile_photo}`;
        setProfilePhotoPreview(photoUrl);
      } else {
        setProfilePhotoPreview(null);
      }
    } catch (error) {
      toast.error('Failed to fetch user data');
    }
  };

  const validateForm = () => {
    if (!firstName) {
      toast.error('First Name is mandatory');
      firstNameRef.current.focus();
      return false;
    }
    if (!lastName) {
      toast.error('Last Name is mandatory');
      lastNameRef.current.focus();
      return false;
    }
    if (!email) {
      toast.error('Email is mandatory');
      emailRef.current.focus();
      return false;
    }
    if (!sex) {
      toast.error('Sex is mandatory');
      sexRef.current.focus();
      return false;
    }
    if (!department) {
      toast.error('Department is mandatory');
      departmentRef.current.focus();
      return false;
    }
    if (!dateOfBirth) {
      toast.error('Date of Birth is mandatory');
      dateOfBirthRef.current.focus();
      return false;
    }
    if (!phoneNumber) {
      toast.error('Phone Number is mandatory');
      phoneNumberRef.current.focus();
      return false;
    }
    return true;
  };

  const handleUpdateProfile = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true); 
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('sex', sex);
      formData.append('department', department);
      formData.append('date_of_birth', dateOfBirth);
      formData.append('phone_number', phoneNumber);
      formData.append('special_notes', specialNotes);

      if (profilePhoto) {
        formData.append('profile_photo', profilePhoto);
      }

      const response = await axios.put(`${API_URL}/auth/profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setIsLoading(false); 
      const data = await response.data;
      if (data.redirectTo) {
        history.push(data.redirectTo);
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to update profile');
    }
  };

  const handleProfilePhotoDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const { getRootProps: getPhotoRootProps, getInputProps: getPhotoInputProps } = useDropzone({
    onDrop: handleProfilePhotoDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Profile</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
          {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
            {user && (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="form-group">
                          <label>Profile Photo</label>
                          <div>
                            {profilePhotoPreview ? (
                              <img
                                src={profilePhotoPreview}
                                alt="Profile Preview"
                                style={{ width: '200px', height: '200px' }}
                                className="mt-2"
                              />
                            ) : (
                              <div className="mt-2">
                                <i className="fas fa-user" style={{ fontSize: '200px', color: 'grey', padding: '20px' }}></i>
                              </div>
                            )}
                          </div>
                          <div
                            {...getPhotoRootProps({
                              className: 'dropzone',
                              style: {
                                border: '2px dashed #007bff',
                                borderRadius: '5px',
                                padding: '20px',
                                width: '200px',
                                height: '80px',
                                textAlign: 'center',
                              },
                            })}
                          >
                            <input {...getPhotoInputProps()} />
                            <p>Drag & drop a profile photo or click</p>
                          </div>
                        </div>
                        <Row>
                          <Col md={6}>
                            <div className="form-group">
                              <label>Username</label>
                              <input type="text" className="form-control" value={user.username} readOnly />
                            </div>
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                ref={firstNameRef}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                ref={lastNameRef}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                ref={emailRef}
                                required
                                readOnly
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="form-group">
                              <label>Sex</label>
                              <select
                                className="form-control"
                                value={sex}
                                onChange={(e) => setSex(e.target.value)}
                                ref={sexRef}
                                required
                              >
                                <option value="">Select Sex</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Department</label>
                              <input
                                type="text"
                                className="form-control"
                                value={department}
                                onChange={(e) => setDepartment(e.target.value)}
                                ref={departmentRef}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                className="form-control"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                ref={dateOfBirthRef}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>Phone Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                ref={phoneNumberRef}
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="form-group">
                          <label>About you</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            value={specialNotes}
                            onChange={(e) => setSpecialNotes(e.target.value)}
                          ></textarea>
                        </div>
                        <Button variant="primary" onClick={handleUpdateProfile} disabled={isButtonDisabled}>
                          Update Profile
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default Profile;
