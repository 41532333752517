import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import Loader from './BLoader'; // Import the Loader component
const API_URL = process.env.REACT_APP_API_URL;

function ClientManagement() {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch client credentials
  useEffect(() => {
    fetchClientCredentials();
  }, []);

  const fetchClientCredentials = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/oauth/credentials`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const { clientId, clientSecret, tenantId } = response.data;
      setClientId(clientId);
      setClientSecret(clientSecret);
      setTenantId(tenantId);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch client credentials');
      setLoading(false);
    }
  };

  // Regenerate client secret
  const regenerateClientSecret = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/oauth/regenerate-secret`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      const { newClientSecret } = response.data;
      setClientSecret(newClientSecret);
      toast.success('Client secret regenerated successfully');
    } catch (error) {
      toast.error('Failed to regenerate client secret');
    }
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">OAuth Management</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            {loading ? (
              <div><Loader /></div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Value</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Client ID</td>
                        <td>{clientId}</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>Client Secret</td>
                        <td>
                          <span
                            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                            onClick={regenerateClientSecret}
                          >
                            {clientSecret}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={regenerateClientSecret}
                          >
                            Regenerate
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>Tenant ID</td>
                        <td>{tenantId}</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default ClientManagement;
