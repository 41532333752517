import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
function AttributeDetailsForm({
  entities,
  selectedEntity,
  setSelectedEntity,
  selectedAttribute,
  setSelectedAttribute,
  attributeName,
  setAttributeName,
  attributeDatatype,
  setAttributeDatatype,
  attributeVisibility,
  setAttributeVisibility,
  attributeNullability,
  setAttributeNullability,
  minLength,
  setMinLength,
  maxLength,
  setMaxLength,
  caps,
  setCaps,
  tags,
  selectedTag,
  setSelectedTag,
  newTag,
  setNewTag,
  handleAddTag,
  onAddAttribute,  // Use a single function for both add and update
  isEditing,  // Boolean to check if we are editing
  setIsEditing,  // Set editing state
  resetAttributeForm,  // Pass the reset function to clear the form
  attributeOptions,
  setAttributeOptions,
  formulaValue,
  setFormulaValue,
  currentAttributes,
}) {
  const isLookupType = ['Lookup', 'Multi-Lookup'].includes(attributeDatatype);
  const showCaps = ['Text', 'Long Text'].includes(attributeDatatype);
  const showLengthFields = ['Text', 'Long Text', 'Password'].includes(attributeDatatype);
  const showOptionsField = ['Multi-Select', 'Select', 'Radio'].includes(attributeDatatype);
  const [showFormulaModal, setShowFormulaModal] = React.useState(false);
 // const [formulaValue, setFormulaValue] = React.useState('');
  const [selectedFormulaAttribute, setSelectedFormulaAttribute] = React.useState('');
  
  const handleEntitySelect = (e) => {
    const selectedId = e.target.value;
    const selectedEntityData = entities.find((entity) => entity.id === selectedId);
    if (selectedEntityData) {
      setSelectedEntity(selectedEntityData);
      setSelectedAttribute(''); // Clear the selectedAttribute field when entity changes
    }
  };
  const handleCancel = () => {
    resetAttributeForm();  // Reset form fields
    setIsEditing(false);  // Exit editing mode
  };
  const handleAttributeSelect = (e) => {
    setSelectedAttribute(e.target.value);
    if (isLookupType) {
      setAttributeNullability(false);
    }
  };

  const handleSubmit = () => {
    onAddAttribute();  // Use a single function for both add and update
    setIsEditing(false);  // Reset the editing state after submission
  };

  return (
    <div>
      <Form.Group>
        <Form.Label>Attribute Details</Form.Label>
        <div className="d-flex flex-wrap align-items-center">
          <Form.Group className="mr-2 mb-2" style={{ width: '150px' }}>
            <Form.Label> Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Attribute Name"
              value={attributeName}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/'/g, ''); // Remove single quotes
                setAttributeName(sanitizedValue);
              }}
              disabled={isLookupType} // Disable if Lookup or Multi-Lookup
            />
          </Form.Group>

          <Form.Group className="mr-2 mb-2" style={{ width: '150px' }}>
            <Form.Label>Datatype</Form.Label>
            <Form.Control
              as="select"
              value={attributeDatatype}
              onChange={(e) => setAttributeDatatype(e.target.value)}
            >
              <option>Select a Data Type</option>
              <option>ID</option>
              <option>Text</option>
              <option>Long Text</option>
              <option>Number</option>
              <option>Password</option>
              <option>E-mail</option>
              <option>Phone</option>
              <option>Radio</option>
              <option>Boolean</option>
              <option>Date</option>
              <option>Formula</option>
              <option>Address</option>
              <option>Lookup</option>
              <option>Multi-Lookup</option>
              <option>Day</option>
              <option>Time</option>
              <option>Select</option>
              <option>Multi-Select</option>
              <option>Document</option>
              <option>Img</option>
              <option>Currency</option>              
            </Form.Control>
          </Form.Group>
         
          {isLookupType && (
            <>
              <Form.Group className="mr-2 mb-2">
                <Form.Label>Select Entity</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedEntity ? selectedEntity.id : ''}
                  onChange={handleEntitySelect}
                >
                  <option value="" disabled>
                    Select an entity
                  </option>
                  {entities.map((entity) => (
                    <option key={entity.id} value={entity.id}>
                      {entity.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {selectedEntity && (
                <Form.Group className="mr-2 mb-2">
                  <Form.Label>Select Attribute</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedAttribute}
                    onChange={handleAttributeSelect}
                  >
                    <option value="" disabled>
                      Select an attribute
                    </option>
                    {selectedEntity.attributes.map((attribute) => (
                      <option key={attribute.name} value={attribute.name}>
                        {attribute.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </>
          )}

          <Form.Group className="mr-2 mb-2" style={{ width: '150px' }}>
            <Form.Label>Table Column Visibility</Form.Label>
            <Form.Check
              type="checkbox"
              label="Visible"
              checked={attributeVisibility}
              onChange={(e) => setAttributeVisibility(e.target.checked)}
            />
          </Form.Group>

          {!isLookupType && (
            <>
              <Form.Group className="mr-2 mb-2" style={{ width: '150px' }}>
                <Form.Label>Nullability</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Nullable"
                  checked={attributeNullability}
                  onChange={(e) => setAttributeNullability(e.target.checked)}
                />
              </Form.Group>
              {attributeDatatype === 'Formula' && (
                <>
                  <Form.Group className="mr-2 mb-2">
                    <Form.Label>Add Formula</Form.Label>
                    <div><Button onClick={() => setShowFormulaModal(true)}>Formula </Button>{`${formulaValue.substring(0, 8)}...`}</div>
                  </Form.Group>
                </>
              )}
              {showLengthFields && (
                <>
                  <Form.Group className="mr-2 mb-2">
                    <Form.Label>Min Length</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Min Length"
                      value={minLength}
                      onChange={(e) => setMinLength(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mr-2 mb-2">
                    <Form.Label>Max Length</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Max Length"
                      value={maxLength}
                      onChange={(e) => setMaxLength(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
            {showCaps && (
              <Form.Group className="mr-2 mb-2">
                <Form.Label>Caps</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Caps"
                  checked={caps}
                  onChange={(e) => setCaps(e.target.checked)}
                />
              </Form.Group>
              )}
              {showOptionsField && (
                <Form.Group className="mr-2 mb-2" style={{ width: '150px' }}>
                  <Form.Label>Options</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Comma-separated options"
                    value={attributeOptions}
                    onChange={(e) => setAttributeOptions(e.target.value)}
                  />
                </Form.Group>
              )}
            </>
          )}

          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Add'}
          </Button>
          {isEditing && (
            <Button variant="secondary" onClick={handleCancel} className="ml-2">
              Cancel
            </Button>
          )}
        </div>
      </Form.Group>
     
      <Modal show={showFormulaModal} onHide={() => setShowFormulaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="d-flex align-items-center">
          <div style={{ flex: 1, marginRight: '8px' }}>
            <Form.Label>Select Attribute</Form.Label>
            <Form.Control
              as="select"
              value={selectedFormulaAttribute}
              onChange={(e) => setSelectedFormulaAttribute(e.target.value)}
            >
              <option value="" disabled>Select an attribute</option>
              {currentAttributes
                ?.filter((attr) => 
                  ['Text', 'Long Text', 'Number', 'E-mail', 'Phone', 'Date'].includes(attr.datatype)
                )
                .map((attr) => (
                  <option key={attr.name} value={attr.name}>
                    {attr.name}
                  </option>
                ))}
            </Form.Control>
          </div>
          <Button
            style={{ height: 'fit-content', alignSelf: 'flex-end' }}
            onClick={() => {
              setFormulaValue((prev) => `${prev} Entity.${selectedFormulaAttribute}`);
            }}
          >
            Add
          </Button>
        </Form.Group>

          <Form.Group>
          <Form.Label>Formula</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formulaValue} // Use formulaValue here
            onChange={(e) => setFormulaValue(e.target.value)} // Update formulaValue here
          />
        </Form.Group>
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFormulaModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setFormulaValue(formulaValue); // Save the formula value
              setShowFormulaModal(false);
            }}
          >
            Save Formula
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AttributeDetailsForm;
