import React, { useEffect, useRef, useState } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function FormBuilder() {
  const formBuilderRef = useRef(null);
  const [forms, setForms] = useState([]);
  const [formName, setFormName] = useState('');
  const [formIcon, setFormIcon] = useState('');
  const [childForms, setChildForms] = useState([]);
  const [iconOptions, setIconOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [actionType, setActionType] = useState(''); // 'cancel' or 'delete'

  useEffect(() => {
    fetchForms();
    fetchIconOptions();
  }, []);

  useEffect(() => {
    if (showModal) {
      initializeFormBuilder();
    }
  }, [showModal]);

  const fetchForms = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setForms(response.data);
    } catch (error) {
      toast.error('Failed to fetch forms');
    }
  };

  const fetchIconOptions = async () => {
    try {
      const response = await axios.get('/icons.json'); // Ensure this path is correct
      setIconOptions(response.data);
    } catch (error) {
      toast.error('Failed to fetch icon options');
    }
  };

  const initializeFormBuilder = (formSchema = {}) => {
    if (formBuilderRef.current) {
      window.Formio.builder(formBuilderRef.current, formSchema).then(builder => {
        formBuilderRef.current.builder = builder;
      });
    }
  };

  const handleSaveForm = async () => {
    if (!formBuilderRef.current || !formBuilderRef.current.builder) {
      toast.error('Form Builder is not initialized');
      return;
    }

    try {
      const formSchema = formBuilderRef.current.builder.instance.schema;
      const formData = {
        name: formName,
        icon: formIcon,
        childForms: childForms.map(f => f.id),
        formSchema
      };

      if (currentFormId) {
        await axios.put(`${API_URL}/forms/${currentFormId}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        toast.success('Form updated successfully');
      } else {
        await axios.post(`${API_URL}/forms`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        toast.success('Form saved successfully');
      }

      fetchForms();
      setShowModal(false);
    } catch (error) {
      toast.error('Failed to save form');
    }
  };

  const handleEditForm = (form) => {
    setFormName(form.name);
    setFormIcon(form.icon);
    setChildForms(form.childForms);
    setCurrentFormId(form.id);
    initializeFormBuilder(form.formSchema);
    setShowModal(true);
  };

  const handleDeleteForm = async () => {
    try {
      await axios.delete(`${API_URL}/forms/${currentFormId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Form deleted successfully');
      fetchForms();
      setShowConfirmModal(false);
      setCurrentFormId(null);
    } catch (error) {
      toast.error('Failed to delete form');
    }
  };

  const handleConfirmAction = () => {
    if (actionType === 'delete') {
      handleDeleteForm();
    } else {
      setShowModal(false);
    }
    setShowConfirmModal(false);
  };

  const handleActionClick = (type) => {
    setActionType(type);
    setShowConfirmModal(true);
  };

  const resetForm = () => {
    setFormName('');
    setFormIcon('');
    setChildForms([]);
    setCurrentFormId(null);
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Form Builder</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <Button variant="primary" onClick={() => { resetForm(); setShowModal(true); }}>
              <i className="fas fa-plus"></i> Create Form
            </Button>
            <div className="row">
              <div className="col-12">
                <div className="card mt-3">
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Icon</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {forms.map((form) => (
                          <tr key={form.id}>
                            <td>{form.id}</td>
                            <td>{form.name}</td>
                            <td><i className={form.icon}></i></td>
                            <td>
                              <Button variant="warning" onClick={() => handleEditForm(form)}>
                                <i className="fas fa-edit"></i>
                              </Button>
                              <Button variant="danger" onClick={() => { setCurrentFormId(form.id); handleActionClick('delete'); }}>
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div ref={formBuilderRef} style={{ marginTop: '20px' }}></div>
            <Button variant="primary" onClick={handleSaveForm} style={{ marginTop: '20px' }}>
              Save Form
            </Button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => handleActionClick('cancel')} size="xl">
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Form Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Form Name</label>
            <input
              type="text"
              className="form-control"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Form Icon</label>
            <select
              className="form-control"
              value={formIcon}
              onChange={(e) => setFormIcon(e.target.value)}
              required
            >
              <option value="">Select Icon</option>
              {iconOptions.map((icon, index) => (
                <option key={index} value={icon.value}>
                  {icon.label}
                </option>
              ))}
            </select>
          </div>
        
          <div ref={formBuilderRef}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleActionClick('cancel')}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveForm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {actionType} this form?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={handleConfirmAction}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default FormBuilder;
