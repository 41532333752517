import React, { useState } from 'react';
import { Button, Offcanvas, Form } from 'react-bootstrap';

const FilterComponent = ({ headers, applyFilters, headerTypes }) => {
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const addFilter = () => {
    setFilters([...filters, { field: '', condition: 'equal', value: '', type: '', conjunction: 'AND' }]);
  };

  const removeFilter = (index) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;

    if (key === 'field') {
      newFilters[index].type = headerTypes[value];
    }

    setFilters(newFilters);
  };

  const handleApplyFilters = () => {
    applyFilters(filters);
    setShowFilter(false);
  };

  const renderConditionOptions = (type) => {
    if (type === 'number' || type === 'date') {
      return (
        <>
          <option value="equal">Equal</option>
          <option value="not_equal">Not Equal</option>
          <option value="greater_than">Greater Than</option>
          <option value="less_than">Less Than</option>
          <option value="greater_than_or_equal">Greater Than Or Equal</option>
          <option value="less_than_or_equal">Less Than Or Equal</option>
          <option value="in">In</option>
        </>
      );
    }
    return (
      <>
        <option value="equal">Equal</option>
        <option value="not_equal">Not Equal</option>
        <option value="contains">Contains</option>
        <option value="starts_with">Starts With</option>
        <option value="ends_with">Ends With</option>
        <option value="in">In</option>
      </>
    );
  };

  return (
    <>
      <Button variant="secondary" onClick={() => setShowFilter(true)} className="me-2">
        <i className="fas fa-filter"></i> Filter
      </Button>

      <Offcanvas show={showFilter} onHide={() => setShowFilter(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{top:'30px', maxHeight: '440px',height: '440px', overflowY: 'auto' }}>
            {filters.map((filter, index) => (
              <div key={index} className="mb-3" style={{border:"ridge"}} >
                <Form.Group controlId={`filterField${index}`}>
                  <div style={{padding:"2px" }}>
                  <Form.Control 
                    as="select"
                    value={filter.field}
                    onChange={(e) => handleFilterChange(index, 'field', e.target.value)}
                  >
                    <option value="">Select Field</option>
                    {headers.map(header => (
                      <option key={header} value={header}>{header}</option>
                    ))}
                  </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId={`filterCondition${index}`}>
                <div style={{padding:"2px" }}>
                  <Form.Control 
                    as="select"
                    value={filter.condition}
                    onChange={(e) => handleFilterChange(index, 'condition', e.target.value)}
                    disabled={!filter.field}
                  >
                    {renderConditionOptions(filter.type)}
                  </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group controlId={`filterValue${index}`}>
                <div style={{padding:"2px" }}>
                  <Form.Control
                    type={filter.type === 'date' ? 'date' : 'text'}
                    value={filter.value}
                    onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                    disabled={!filter.field}
                  />
                  </div>
                </Form.Group>
                <Form.Group controlId={`filterConjunction${index}`}>
                <div style={{padding:"2px" }}>
                  <Form.Control
                    as="select"
                    value={filter.conjunction}
                    onChange={(e) => handleFilterChange(index, 'conjunction', e.target.value)}
                  >
                    <option value="AND">AND</option>
                    <option value="OR">OR</option>
                  </Form.Control>
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-end me-2">
                <Button variant="danger" onClick={() => removeFilter(index)} className="m2-2">
                  <i className="fas fa-minus-circle"></i> Remove
                </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button variant="primary" onClick={addFilter} className="me-2">
              <i className="fas fa-plus-circle"></i> Add
            </Button>
            <Button variant="success" onClick={handleApplyFilters}>
              <i className="fas fa-check-circle"></i> Apply
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FilterComponent;
