import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import Layout from './Layout';

const API_URL = process.env.REACT_APP_API_URL;

function ChatManagement() {
  const [chatSettings, setChatSettings] = useState([]);

  useEffect(() => {
    fetchChatSettings();
  }, []);

  const fetchChatSettings = async () => {
    try {
      const response = await axios.get(`${API_URL}/chat/settings`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setChatSettings(response.data);
    } catch (error) {
      console.error('Failed to fetch chat settings', error);
    }
  };

  const handleChatToggle = async (settingId, isChatEnabled,company_id) => {
    try {
      const res = await axios.post(
        `${API_URL}/chat/settings`,
        { is_chat_enabled: !isChatEnabled ,company_id:company_id},
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchChatSettings(); // Refresh the settings after the update
    } catch (error) {
      console.error('Failed to update chat settings', error);
    }
  };

  return (
    <Layout>
      <Container fluid className="chat-management-container">
        <Row className="justify-content-md-center">
          
          <Col md={8}>
            <h3 className="mt-4">Chat Management</h3>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Company ID</th>
                  <th>Chat Enabled</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {chatSettings.map(setting => (
                  <tr key={setting.id}>
                    <td>{setting.company_id}</td>
                    <td>{setting.is_chat_enabled ? 'Enabled' : 'Disabled'}</td>
                    <td>
                      <Button style={{width:'auto'}}
                        variant={setting.is_chat_enabled ? 'danger' : 'success'}
                        onClick={() => handleChatToggle(setting.id, setting.is_chat_enabled,setting.company_id)}
                      >
                        {setting.is_chat_enabled ? 'Disable' : 'Enable'}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table></div></div></div></div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default ChatManagement;
