import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, Form, Table } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;
const demoid = process.env.REACT_APP_DEMOID;

function MenuManagement() {
  const [menus, setMenus] = useState([]);
  const [newMenu, setNewMenu] = useState(initializeMenu());
  const [editMenu, setEditMenu] = useState(initializeMenu());
  const [availableRoles, setAvailableRoles] = useState([]);
  const [iconOptions, setIconOptions] = useState([]);
  const [forms, setForms] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('name');
  const [currentSort, setCurrentSort] = useState({ field: 'name', direction: 'asc' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable logic
  const role = localStorage.getItem('role'); // Assuming the role is stored in 'role'

  useEffect(() => {
    fetchInitialData();
    if (role && demoid && role === demoid) { // Adjust role check as needed
      setIsButtonDisabled(true);
    }
  }, []);

  const fetchInitialData = async () => {
    try {
      const [menuResponse, roleResponse, iconResponse, formResponse] = await Promise.all([
        axios.get(`${API_URL}/menus/logic?user= ${localStorage.getItem('user')}`, { headers: getAuthHeaders() }),
        axios.get(`${API_URL}/roles/logic?user= ${localStorage.getItem('user')}`, { headers: getAuthHeaders() }),
        axios.get('/icons.json'), 
        axios.get(`${API_URL}/forms?organization= ${localStorage.getItem('company')}`, { headers: getAuthHeaders() }),
      ]);

      setMenus(menuResponse.data);
      setAvailableRoles(roleResponse.data);
      setIconOptions(iconResponse.data);
      setForms(formResponse.data);
    } catch (error) {
      //toast.error('Failed to fetch initial data');
    }
  };

  function initializeMenu() {
    return {
      id: null,
      name: '',
      icon: '',
    //  parent_id: null,
      order_num: '',
      role_id: '',
      url: '',
      is_tile: false,
      organization_id:localStorage.getItem('company'),
    };
  }

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  });
  
  const handleAddMenu = async () => {
    try {
      const requiredAttributes = ['name', 'icon', 'order_num', 'url'];
    
      // Check for missing attributes
      const missingAttributes = requiredAttributes.filter(attr => !newMenu[attr]);
      
      if (missingAttributes.length > 0) {
        // Throw an error if any attributes are missing
        throw new Error(`Missing mandatory attributes: ${missingAttributes.join(', ')}`);
      } 

      const payload = {
        ...newMenu,
        parent_id: newMenu.parent_id || null,
        form_id: newMenu.form_id || null,
      };
      await axios.post(`${API_URL}/menus`, payload, { headers: getAuthHeaders() });
      resetForm();
      setShowAddModal(false);
      fetchInitialData();
      toast.success('Menu added successfully');
    } catch (error) {
      if (error.message.startsWith('Missing mandatory attributes')) {
        toast.error(error.message);
      } else {
        // Generic error handling
        toast.error('Failed to add menu');
      }
    }
  };
  const handleToggleActive = async (menu) => {
    try {
      const updatedMenu = { ...menu, is_active: !menu.is_active };
      await axios.put(`${API_URL}/menus/${menu.id}`, updatedMenu, { headers: getAuthHeaders() });
      fetchInitialData();
      toast.success(`Menu ${updatedMenu.is_active ? 'activated' : 'deactivated'} successfully`);
    } catch (error) {
      toast.error('Failed to update menu status');
    }
  };
  
  const handleUpdateMenu = async () => {
    try {
      const requiredAttributes = ['name', 'icon', 'order_num', 'url'];
    
      // Check for missing attributes
      const missingAttributes = requiredAttributes.filter(attr => !editMenu[attr]);
      
      if (missingAttributes.length > 0) {
        // Throw an error if any attributes are missing
        throw new Error(`Missing mandatory attributes: ${missingAttributes.join(', ')}`);
      }
      const payload = {
        ...editMenu,
        parent_id: editMenu.parent_id || null,
        form_id: editMenu.form_id || null,
      };
      await axios.put(`${API_URL}/menus/${editMenu.id}`, payload, { headers: getAuthHeaders() });
      resetForm();
      setShowEditModal(false);
      fetchInitialData();
      toast.success('Menu updated successfully');
    } catch (error) {
      if (error.message.startsWith('Missing mandatory attributes')) {
        toast.error(error.message);
      } else {
        // Generic error handling
        toast.error('Failed to add menu');
      }
    }
  };

  const handleDeleteMenu = async (menuId) => {
    try {
      await axios.delete(`${API_URL}/menus/${menuId}`, { headers: getAuthHeaders() });
      fetchInitialData();
      toast.success('Menu deleted successfully');
    } catch (error) {
      toast.error('Failed to delete menu');
    }
  };

  const resetForm = () => {
    setNewMenu(initializeMenu());
    setEditMenu(initializeMenu());
  };

  const openAddMenuModal = () => {
    resetForm();
    setShowAddModal(true);
  };

  const openEditMenuModal = (menu) => {
    setEditMenu(menu);
    setShowEditModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSort = (field) => {
    const newDirection = currentSort.field === field && currentSort.direction === 'asc' ? 'desc' : 'asc';
    setCurrentSort({ field, direction: newDirection });
    // Call your sorting logic here, passing field and newDirection
    //sortData(field, newDirection);
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortColumn(field);
  };

  const sortedAndFilteredMenus = menus
    .filter(menu =>
      menu.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (menu.role_name && menu.role_name.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    });

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Menu Management</h1>
              </div>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by menu name or role"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <Button variant="primary" onClick={openAddMenuModal} className="mb-3" disabled={isButtonDisabled}>
              <i className="fas fa-plus"></i> Add Menu
            </Button>
            <div className="card">
              <div className="card-body">
              
<Table bordered>
<thead>
  <tr>
   {/*} <th onClick={() => handleSort('id')}>
      ID {currentSort.field === 'id' && (currentSort.direction === 'asc' ? '↑' : '↓')}
  </th>*/}
    <th onClick={() => handleSort('name')}>
      Name {currentSort.field === 'name' && (currentSort.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th className='style-50'>Icon</th>
    <th onClick={() => handleSort('order_num')} className='style-50'>
      Order {currentSort.field === 'order_num' && (currentSort.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('role_name')}>
      Role {currentSort.field === 'role_name' && (currentSort.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th className='style-50'>Tile</th>
    <th>URL</th>
    <th>Active</th> {/* New column for Active/Inactive */}
    <th>Actions</th>
  </tr>
</thead>

  <tbody>
    {sortedAndFilteredMenus.map(menu => (
      <tr key={menu.id}>
       {/* <td>{menu.id}</td>*/}
        <td>{menu.name}</td>
        <td className='style-middle'>
          <i  className={`fa ${menu.icon}  icon-style` }></i> 
        </td>
        <td  className='style-middle'>{menu.order_num}</td>
        <td>{menu.role_name=='User'?'Admin': menu.role_name}</td>
        <td  className='style-middle'>{menu.is_tile ? '✓' : '✗'}</td>
        <td>{menu.url}</td>
        <td className='style-100'>
          <Form.Check
            type="switch"
            checked={menu.is_active}
            onChange={() => handleToggleActive(menu)}
            label={menu.is_active ? 'Active' : 'Inactive'}
          />
        </td> {/* Active/Inactive toggle */}
        <td  className='style-middle'>
          <Button
            variant="primary"
            onClick={() => openEditMenuModal(menu)}
            className="me-2"
          >
            Edit
          </Button>
          {menu.role_name !== "User" && menu.role_name !== "CoWorker" && (
            <Button
              variant="primary"
              onClick={() => handleDeleteMenu(menu.id)}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Menu Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Add Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormFields
            menu={newMenu}
            setMenu={setNewMenu}
            menus={menus}
            iconOptions={iconOptions}
            forms={forms}
            availableRoles={availableRoles}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddMenu}>
            Save Menu
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Menu Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Edit Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormFields
            menu={editMenu}
            setMenu={setEditMenu}
            menus={menus}
            iconOptions={iconOptions}
            forms={forms}
            availableRoles={availableRoles}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateMenu} disabled={isButtonDisabled}>
            Update Menu
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

const FormFields = ({ menu, setMenu, menus, iconOptions, forms, availableRoles }) => (
  <>
    <Form.Group controlId="formName">
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        value={menu.name}
        onChange={(e) => setMenu({ ...menu, name: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="formIcon">
      <Form.Label>Icon</Form.Label>
      <Form.Control
        as="select"
        value={menu.icon}
        onChange={(e) => setMenu({ ...menu, icon: e.target.value })}
      >
        <option value="">Select Icon</option>
        {iconOptions.map((icon, index) => (
          <option key={index} value={icon.value}>
            {icon.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
 {/*   <Form.Group controlId="formParentId">
      <Form.Label>Parent Menu</Form.Label>
      <Form.Control
        as="select"
        value={menu.parent_id || ''}
        onChange={(e) => setMenu({ ...menu, parent_id: e.target.value })}
      >
        <option value="">None</option>
        {menus.map((m) => (
          <option key={m.id} value={m.id}>
            {m.name}
          </option>
        ))}
        </Form.Control>
    </Form.Group>*/}
    <Form.Group controlId="formOrderNum">
      <Form.Label>Order</Form.Label>
      <Form.Control
        type="number"
       value={menu.order_num}
        onChange={(e) => setMenu({ ...menu, order_num: e.target.value })}
      />
    </Form.Group>
    <Form.Group controlId="formRole">
      <Form.Label>Role</Form.Label>
      <Form.Control
        as="select"
        value={menu.role_id || ''}
        onChange={(e) => setMenu({ ...menu, role_id: e.target.value })}
      >
        <option value="">Select Role</option>
        {availableRoles.map((role) => (
          <option key={role.id} value={role.id}>
            {role.name=='User'?'Admin':role.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
    <Form.Group controlId="formIsTile">
      <Form.Check
        type="checkbox"
        label="Is Tile"
        checked={menu.is_tile}
        onChange={(e) => setMenu({ ...menu, is_tile: e.target.checked })}
      />
    </Form.Group>
    <Form.Group controlId="formFormSelect">
      <Form.Label>Form</Form.Label>
      <Form.Control
        as="select"
        value={menu.form_id}
        onChange={(e) => setMenu({ ...menu, form_id: e.target.value, url: e.target.value ? `forms/view/${e.target.value}` : '' })}
      >
        <option value="">Select Form</option>
        {forms.map((form) => (
          <option key={form.id} value={form.id}>
            {form.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
    <Form.Group controlId="formUrl">
      <Form.Label>URL</Form.Label>
      <Form.Control
        type="text"
       
        value={menu.url}
        onChange={(e) => setMenu({ ...menu, url: e.target.value })}
      />
    </Form.Group>
  </>
);

export default MenuManagement;
