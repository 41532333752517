import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ERDManagement from './ERD';
import Layout from '../Layout';
function ERDParentPage() {
  const [key, setKey] = useState('management');

  return (
    <Layout>
      <div className="content-wrapper">
    
                <ERDManagement />
             

      </div>
    </Layout>
  );
}

export default ERDParentPage;
