import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Register from './components/Register';
import UserList from './components/UserList';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
//import AdminHome from './components/AdminHome';
import RoleManagement from './components/RoleManagement'; // Add the RoleManagement component
import ApplicationManagement from './components/ApplicationManagement';
import ThemeManagement from './components/ThemeManagement';
import UserManagement from './components/UserManagement'; // Import UserManagement
import FormBuilder from './components/FormBuilder'; // Import FormBuilder
import FormList from './components/FormList';
import FormEditor from './components/FormEditor';
import FormViewer from './components/FormViewer';
import FormCreator from './components/FormCreator';
import SubmissionDetail from './components/SubmissionDetail';
import MenuManagement from './components/MenuManagement';
import PasswordPolicy from './components/PasswordPolicy';
import GroupManagement from './components/GroupManagement'; // Import UserManagement
import MessageSender from './components/MessageSender';
import ChatManagement from './components/ChatManagement'; // Import UserManagement
import OrgManagement from './components/OrganizationManagement'; // Import UserManagement
import ERD from './components/entity/ERDParentPage'; // Import UserManagement
import DashBoard from './components/dashboard/Dashboard'; // Import UserManagement
import ReportBuilder from './components/reports/ReportBuilder'; // Import UserManagement
import VerifyEmail from './components/VerifyEmail'; 
import ChangePassword from './components/ChangePassword';
import Unauthorized from './components/unauthorized';
import ProtectedRoute from './components/ProtectedRoute';
import OAuth from './components/OauthManagement';
import permissions from './components/FunctionPermissions';
import appRoles from './components/AppRoleManagement';

function App() {
  return (
    
    <Router>
      <Switch>
        <Route path="/login" component={Login} />    
        <Route exact path="/applications" component={ApplicationManagement} />
        <Route path="/register" component={Register} />
        <Route path="/users" component={UserList} />
        <Route path="/groupr-management" component={GroupManagement} /> {/* Add UserManagement route */}
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/user-management" component={UserManagement} /> {/* Add UserManagement route */}
        <Route path="/profile" component={Profile}/>
        <Route path="/profile" component={Profile} />
        <Route path="/erd" component={ERD} />
        <Route path="/permissions" component={permissions} />
       {/* <ProtectedRoute path="/dashboard" component={Dashboard} />  Role-based route */}
        <Route path="/oauth" component={OAuth} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/form-builder" component={FormBuilder} />
        <Route path="/dashboard" component={DashBoard} />
        <Route path="/report-builder" component={ReportBuilder} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/unauthorized" component={Unauthorized} />
      {/* <Route path="/admin" component={AdminHome} /> */}
        <Route path="/roles" component={RoleManagement} /> {/* Role management route */}
        <Route path="/themes" component={ThemeManagement} />
        <Route path="/menus" component={MenuManagement} />
        <Route path="/policy" component={PasswordPolicy} />
        <Route path="/chat" component={ChatManagement} />
        <Route path="/org" component={OrgManagement} />
        <Route path="/approles" component={appRoles} />
      
        <Route path="/sendmessage" component={MessageSender} />
        <Route exact path="/forms" component={FormList} />
        <Route exact path="/forms/create" component={FormCreator} />
        <Route exact path="/forms/edit/:formId" component={FormEditor} />
        <Route exact path="/forms/view/:formId" component={FormViewer} />
        <Route path="/forms/:formId/submissions/:submissionId" component={SubmissionDetail} />
        
        <Route path="/" component={Login} /> {/* Default route */}
        
      </Switch>
     
    </Router>
  );
}

export default App;
