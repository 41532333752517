import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Layout from './Layout';
import Toaster from '../ui-components/Toaster';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Loader from './BLoader'; // Import the Loader component
import Integration from './Integration'; // Import the Loader component
import Accordion from '../ui-components/Accordion';
import { Tabs, Tab } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;
const demoid = process.env.REACT_APP_DEMOID;


const OrganizationManagement = () => {
  const [organization, setOrganization] = useState(initializeOrganization());
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const history = useHistory();
  const [logoFile, setLogoFile] = useState(null);
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable logic
  const role = localStorage.getItem('role'); // Assuming the role is stored in 'role'
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const loggedInUserId = localStorage.getItem('user');
  const [key, setKey] = useState('organizationForm'); // State for active tab
  const [demoApplications, setDemoApplications] = useState([]);
  const [selectedDemoApp, setSelectedDemoApp] = useState('');

  useEffect(() => {
    fetchInitialData();
    fetchDemoApplications();
    fetchUsers();
    fetchRoles();
    if (role && demoid && role === demoid) { // Adjust role check as needed
      setIsButtonDisabled(true);
    }
  }, []);
   const handleAssignRole = async (userId, roleId) => {
    if (!roleId) {
      toast.error("Role ID can't be blank"); // Display an error if roleId is blank
      return; // Exit the function without making the API call
    }
    try {
      await axios.post(
        `${API_URL}/organizations/assignRole`,
        { userId, roleId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('Role assigned successfully');
      fetchUsers(); // Refresh the users list
    } catch (error) {
      toast.error('Failed to assign role');
    }
  };
  const fetchDemoApplications = async () => {
    try {
      const response = await axios.get(`${API_URL}/organizations/getdemoapps`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDemoApplications(response.data);
    } catch (error) {
      toast.error('Failed to fetch demo applications');
    }
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/roles/approles`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  };

  const fetchInitialData = async () => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('user');
      const authHeaders = {
        Authorization: `Bearer ${token}`,
      };

      const organizationResponse = await axios.get(`${API_URL}/organizations`, {
        headers: {
          ...authHeaders,
          'Content-Type': 'application/json',
          'X-User-ID': userId,
        },
        params: { user: userId },
      });

      if (organizationResponse.data && organizationResponse.data.length > 0) {
        const org = organizationResponse.data[0];
        org.role_id = org.roles;
        setOrganization(org);

        setIsEditing(true);
        if (org.logo) {
          setPreviewImage(`${API_URL.replace('/api/', '/')}${org.logo}`);
        }
      }
    } catch (error) {
      handleError(error, 'Failed to fetch organization data');
    }
  };

  const fetchUsers = async () => {
    try {
      const organizationId = localStorage.getItem('company');
      const response = await axios.get(`${API_URL}/users/${organizationId}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data.users);
      setLoadingUsers(false);
    } catch (error) {
      setLoadingUsers(false);
    }
  };

  const toggleUserStatus = async (userId, isActive) => {
    try {
      setIsLoading(true); 
      await axios.put(
        `${API_URL}/users/${userId}/status`,
        { is_active: !isActive },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setIsLoading(false); 
      toast.success(`User ${!isActive ? 'activated' : 'deactivated'} successfully.`);
      fetchUsers();
    } catch (error) {
      setIsLoading(false); 
      handleError(error, 'Failed to update user status');
    }
  };

  const handleUpdateDataSecurity = async (userId, value) => {
    try {
      await axios.put(
        `${API_URL}/users/${userId}/datasecurity`,
        { data_security: value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      toast.success('Data Security updated successfully');
      fetchUsers(); // Refresh users list to reflect changes
    } catch (error) {
      toast.error('Failed to update Data Security');
    }
  };
  
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setLogoFile(file);
    setPreviewImage(URL.createObjectURL(file));
    setOrganization({ ...organization, logo: file });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  const handleSendInvitation = async () => {
    // Clear previous errors
    setError('');
    
    // Validate the email before making the API call
    if (!inviteEmail.trim()) {
      setError('Email is required');
      toast.error('Email is required');
      return;
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inviteEmail)) {
      setError('Please enter a valid email address');
      toast.error('Invalid email address');
      return;
    }
  
    try {
      setIsLoading(true); // Start loading
  
      // Retrieve data from localStorage
      const organizationId = localStorage.getItem('company');
      const userId = localStorage.getItem('user');
      const token = localStorage.getItem('token');
  
      // Ensure critical data exists
      if (!organizationId || !userId || !token) {
        throw new Error('Missing required user or organization information.');
      }
  
      // Send API request
      await axios.post(
        `${API_URL}/invite/send-invite`,
        { email: inviteEmail, organizationId, userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Success
      toast.success('Invitation sent successfully');
      setShowInviteModal(false);
      setInviteEmail('');
    } catch (error) {
      // Enhanced error handling
      handleError(error, 'Failed to send the invitation');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  
  const handleSaveOrganization = async () => {
    // Validate mandatory fields
    if (!organization.name) {
      toast.error('Organization Name is mandatory');
      document.getElementById('organizationName').focus();
      return;
    }
    if (!organization.slogan) {
      toast.error('Slogan is mandatory');
      document.getElementById('organizationSlogan').focus();
      return;
    }
    if (!organization.industry) {
      toast.error('Industry is mandatory');
      document.getElementById('organizationIndustry').focus();
      return;
    }
    if (!organization.role_id) {
      toast.error('Role is mandatory');
      document.getElementById('organizationRole').focus();
      return;
    }
    if (!organization.address) {
      toast.error('Address is mandatory');
      document.getElementById('organizationAddress').focus();
      return;
    }
    if (!selectedDemoApp) {
      toast.error('Demo Application is mandatory');
      document.getElementById('demoApplication').focus();
      return;
    }
    if (!organization.logo) {
      toast.error('Logo is mandatory');
      document.getElementById('organizationLogo').focus();
      return;
    }

    const formData = new FormData();
    formData.append('name', organization.name);
    formData.append('user', localStorage.getItem('user'));
    formData.append('slogan', organization.slogan);
    formData.append('industry', organization.industry);
    formData.append('role_id', organization.role_id);
    formData.append('address', organization.address);
    formData.append('demo_application_id', selectedDemoApp);
    if (organization.logo) {
      formData.append('logo', organization.logo);
    }
    setIsLoading(true); 
    try {
      if (isEditing) {
        await axios.put(`${API_URL}/organizations/${organization.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        toast.success('Organization updated successfully');
        
      } else {
        const response = await axios.post(`${API_URL}/organizations`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        
        toast.success('Organization created successfully');
        localStorage.setItem('company', response.data.id);
        setIsEditing(true);
        history.push('/erd'); 
      }
    } catch (error) {
      setIsLoading(false); 
      handleError(error, 'Failed to save organization');
    }
    setIsLoading(false); 
  };

  const handleError = (error, defaultMessage) => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    toast.error(errorMessage);
  };

  return (
    <Layout>
      <div className="content-wrapper">
       

        <div className="content">
          <div className="container-fluid">
          <Tabs  id="organization-management-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}  className="mb-3">
          <Tab eventKey="organizationForm" title="Organization Details">
           <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">{isEditing ? 'Edit Organization' : 'Create Organization'}</h4>
                {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
              </div>
            
          
        </div>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Group controlId="organizationName">
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter organization name"
                      value={organization.name}
                      onChange={(e) => setOrganization({ ...organization, name: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="organizationSlogan">
                    <Form.Label>Slogan</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter slogan"
                      value={organization.slogan}
                      onChange={(e) => setOrganization({ ...organization, slogan: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="organizationIndustry">
                    <Form.Label>Industry</Form.Label>
                    <Form.Control
                      as="select"
                      value={organization.industry}
                      onChange={(e) => setOrganization({ ...organization, industry: e.target.value })}
                    >
                      <option value="">Select an Industry</option>
                      <option value="CRM">CRM</option>
                      <option value="Realestate">Realestate</option>
                      <option value="Retailer">Retailer</option>
                      <option value="Hospital">Hospital</option>
                      <option value="School">School</option>
                      <option value="Others">Others</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="organizationRole">
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      as="select"
                      value={organization.role_id || ''}
                      onChange={(e) => setOrganization({ ...organization, role_id: e.target.value })}
                    >
                      <option value="">Select a Role</option>
                      <option value="1">CEO</option>
                      <option value="2">Senior Manager</option>
                      <option value="3">Manager</option>
                      <option value="4">Director</option>
                      <option value="5">Employee</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="demoApplication">
                    <Form.Label>Demo Application</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedDemoApp}
                      onChange={(e) => setSelectedDemoApp(e.target.value)}
                      required
                    >
                      <option value="">Select a Demo Application</option>
                      {demoApplications.map((app) => (
                        <option key={app.id} value={app.id}>
                          {app.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="organizationAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter address"
                      value={organization.address}
                      onChange={(e) => setOrganization({ ...organization, address: e.target.value })}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    onClick={handleSaveOrganization}
                    disabled={isButtonDisabled}
                    style={{ marginTop: '12px' }} 
                  >{isEditing ? 'Update Organization' : 'Create Organization'}
                  </Button>

                </Form>
              </Col>
              <Col md={6}>
                <Form.Group controlId="organizationLogo">
                  <Form.Label>Logo</Form.Label>
                  <div {...getRootProps({ className: 'dropzone' })} className="dropzone mt-2" style={{ padding: '20px', border: '2px dashed #007bff', textAlign: 'center' }}>
                    <input {...getInputProps()} />
                    {previewImage ? (
                      <img src={previewImage} alt="Preview" style={{ maxHeight: '200px', maxWidth: '300px' }} />
                    ) : (
                      <p>Drag 'n' drop an image here, or click to select one</p>
                    )}
                  </div>
                </Form.Group>
                <br />
                {isEditing &&
                (<Button variant="primary" className="ml-2" onClick={() => setShowInviteModal(true)} >
                  Call Co-Workers
                </Button>)}
              </Col>
            </Row>
            </Tab>

            {/* Tab for Organization Users */}
            <Tab eventKey="organizationUsers" title="Organization Users">
            {/* User Management Table */}
            <br></br>
            
            <Row>
           
              <Col md={12}>
               {loadingUsers ? (
                  <p>Loading users...</p>
                ) : users.length === 0 ? (
                  <p>No users found for this organization.</p>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Assigned Role</th>
                        <th>Data Security</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>{user.is_active ? 'Active' : 'Inactive'}</td>
                          <td>
                            {user.id !== loggedInUserId && (
                              <>
                                <Form.Select
                                  value={user.role_id || ''}
                                  onChange={(e) => handleAssignRole(user.id, e.target.value)}
                                  disabled={["User", "Integration",""].includes(
                                    user.role_name || roles.find((role) => role.id === user.role_id)?.name || ''
                                  )} // Derive role name if missing
                                >
                                  <option value="">Assign Role</option>
                                  {roles.map((role) => (
                                    <option key={role.id} value={role.id}>
                                      {role.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </>
                            )}
                          </td>
                          <td>
                          {user.id !== loggedInUserId && (
                              <>
                            <Form.Select
                              value={user.data_security || 'None'}
                              onChange={(e) => handleUpdateDataSecurity(user.id, e.target.value)}
                            >
                              <option value="Users Level ">Users Level</option>
                              <option value="Role Level">Role Level</option>
                              <option value="None">None</option>
                            </Form.Select>
                            </>
                            )}
                          </td>
                          <td>
                            {user.id !== loggedInUserId && (
                              <Button
                                variant={user.is_active ? 'danger' : 'success'}
                                onClick={() => toggleUserStatus(user.id, user.is_active)}
                                disabled={isButtonDisabled}
                              >
                                {user.is_active ? 'Deactivate' : 'Activate'}
                                
                              </Button>
                            )}
                          </td>
                        
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Col>
            
            </Row> 
            </Tab>
            <Tab eventKey="Integration" title="Integration">
            <Row><Integration/></Row>
            </Tab>

            </Tabs>
          </div>
        </div>

        {/* Modal for Email Invitation */}
        <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} aria-labelledby="invite-modal-title">
  <Modal.Header closeButton className="custom-modal-header">
    <Modal.Title id="invite-modal-title">Invite Co-Worker</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form.Group controlId="coworkerEmail">
      <Form.Label>Enter Co-Worker's Email Address</Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        isInvalid={!!error} // Highlight input on error
        required
      />
      <Form.Control.Feedback type="invalid">
        {error || 'Please enter a valid email address.'}
      </Form.Control.Feedback>
    </Form.Group>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowInviteModal(false)} disabled={isLoading}>
      Close
    </Button>
    <Button variant="primary" onClick={handleSendInvitation} disabled={(isLoading || !inviteEmail.trim()) || isButtonDisabled} >
      {isLoading ? (
        <>
          <Loader/>
        </>
      ) : (
        'Send'
      )}
    </Button>
  </Modal.Footer>
</Modal>


        <Toaster />
      </div>
    </Layout>
  );
};

function initializeOrganization() {
  return {
    id: null,
    name: '',
    slogan: '',
    industry: '',
    role_id: '',
    address: '',
    logo: null,
    user: localStorage.getItem('user'),
  };
}

export default OrganizationManagement;
