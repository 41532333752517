import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'admin-lte/plugins/jquery/jquery.min.js';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
import 'admin-lte/dist/js/adminlte.min.js';
import './index.css';
import './styles.css';
import './styles-block.css';
import { BrowserRouter } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const API_URL = process.env.REACT_APP_API_URL;

const loadThemeAndRenderApp = async () => {
  try {
    let themeId = null;

    // Check if the theme is already applied by looking for the link element
    const existingThemeLink = document.getElementById('theme-css');

    if (!existingThemeLink) {
      // If theme is not already set, load from API or local storage
      const storedApp = JSON.parse(localStorage.getItem('defaultApp'));

      if (storedApp) {
        themeId = storedApp.themeId;
      } else {
        const response = await axios.get(`${API_URL}/applications/getapp`, {
          params: { isDefault: 'true' },
        });
        const appData = response.data.applications[0];
        localStorage.setItem('defaultApp', JSON.stringify(appData));
        themeId = appData.themeId;
      }

      if (themeId) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `/themes/theme${themeId}.css`;
        link.id = 'theme-css';
        document.head.appendChild(link);
      }
    }

    // Now render the app
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('root')
    );
  } catch (error) {
    console.error('Failed to load application settings', error);
    // Fallback to rendering the app without theme if fetching fails
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('root')
    );
  }
};

loadThemeAndRenderApp();
