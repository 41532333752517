import React, { useEffect, useRef, useState } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Layout from './Layout';

const API_URL = process.env.REACT_APP_API_URL;

function FormEditor() {
  const formBuilderRef = useRef(null);
  const [formName, setFormName] = useState('');
  const [formIcon, setFormIcon] = useState('');
  const [selectedChildForms, setSelectedChildForms] = useState([]);
  const [iconOptions, setIconOptions] = useState([]);
  const [forms, setForms] = useState([]);
  const [availableForms, setAvailableForms] = useState([]);
  const { formId } = useParams();
  const history = useHistory();
  const [formType, setFormType] = useState('form'); // State to manage form type

  useEffect(() => {
    //fetchForms();
    fetchIconOptions();
    if (formId) {
      fetchFormDetails();
    }
  }, [formId]);

  useEffect(() => {
    setAvailableForms(forms.filter(f => !selectedChildForms.some(cf => cf.id === f.id) && f.id !== parseInt(formId)));
  }, [forms, selectedChildForms]);

  const fetchForms = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setForms(response.data);
    } catch (error) {
      toast.error('Failed to fetch forms');
    }
  };

  const fetchIconOptions = async () => {
    try {
      const response = await axios.get('/icons.json');
      setIconOptions(response.data);
    } catch (error) {
      toast.error('Failed to fetch icon options');
    }
  };

  const fetchFormDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const form = response.data;
      setFormName(form.name);
      setFormIcon(form.icon);
      setSelectedChildForms(form.child_forms);
      setFormType(form.form_type);
      initializeFormBuilder(form.form_schema);
    } catch (error) {
      toast.error('Failed to fetch form details');
    }
  };

  const initializeFormBuilder = (formSchema = {}) => {
    if (formBuilderRef.current) {
      const options = formType === 'wizard' ? { display: 'wizard' } : {};
      window.Formio.builder(formBuilderRef.current, formSchema, options).then(builder => {
        formBuilderRef.current.builder = builder;
      });
    }
  };

  const handleSaveForm = async () => {
    if (!formBuilderRef.current || !formBuilderRef.current.builder) {
      toast.error('Form Builder is not initialized');
      return;
    }

    try {
      const formSchema = formBuilderRef.current.builder.schema;
      const formData = {
        name: formName,
        icon: formIcon,
        childForms: selectedChildForms.map(f => ({ id: f.id, name: f.name })),
        formSchema,
        formType
      };

      await axios.put(`${API_URL}/forms/${formId}`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Form updated successfully');
      history.push('/forms');
    } catch (error) {
      toast.error('Failed to save form');
    }
  };

  const handleCancel = () => {
    history.push('/forms');
  };

  const handleFormTypeChange = (e) => {
    const formSchema = formBuilderRef.current.builder.schema;
    setFormType(e.target.value);
    if (formBuilderRef.current) {
      const options = e.target.value === 'wizard' ? { display: 'wizard' } : {};
      window.Formio.builder(formBuilderRef.current, formSchema, options).then(builder => {
        formBuilderRef.current.builder = builder;
      });
    }
  };

  const handleSelectChildForm = (formId) => {
    const selectedForm = forms.find(f => f.id === formId);
    if (selectedForm && !selectedChildForms.some(f => f.id === selectedForm.id)) {
      setSelectedChildForms([...selectedChildForms, selectedForm]);
      setAvailableForms(availableForms.filter(f => f.id !== selectedForm.id));
    }
  };

  const handleRemoveChildForm = (formId) => {
    const removedForm = selectedChildForms.find(f => f.id === formId);
    setSelectedChildForms(selectedChildForms.filter(f => f.id !== formId));
    setAvailableForms([...availableForms, removedForm]);
  };

  const handleMoveRight = () => {
    const selectedOptions = Array.from(document.getElementById('availableForms').selectedOptions);
    selectedOptions.forEach(o => handleSelectChildForm(parseInt(o.value)));
  };

  const handleMoveLeft = () => {
    const selectedOptions = Array.from(document.getElementById('selectedChildForms').selectedOptions);
    selectedOptions.forEach(o => handleRemoveChildForm(parseInt(o.value)));
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edit Form</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="form-group">
              <label>Form Name</label>
              <input
                type="text"
                className="form-control"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                required disabled
              />
            </div>
            <div className="form-group">
              <label>Form Icon</label>
              <select
                className="form-control"
                value={formIcon}
                onChange={(e) => setFormIcon(e.target.value)}
                required
              >
                <option value="">Select Icon</option>
                {iconOptions.map((icon, index) => (
                  <option key={index} value={icon.value}>
                    {icon.label}
                  </option>
                ))}
              </select>
            </div>
         
            <div className="form-group">
              <label>Form Type</label>
              <select
                className="form-control"
                value={formType}
                onChange={handleFormTypeChange}
                required
              >
                <option value="form">Form</option>
                <option value="wizard">Wizard</option>
              </select>
            </div>
            <div ref={formBuilderRef}></div>
            <div className="d-flex justify-content-end" style={{ marginTop: '20px' }}>
              <Button variant="secondary" onClick={handleCancel} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveForm}>
                Save Form
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default FormEditor;
