// Carousel.js
import React, { useState, useEffect } from 'react';
import './Carousel.css';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalDuration, setIntervalDuration] = useState(30000); // Default to 30 seconds
  const totalSlides = 5; // Set the number of slides

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    }, intervalDuration);

    // Cleanup interval on component unmount
    return () => clearInterval(slideInterval);
  }, [intervalDuration, totalSlides]);

  const goToImage = (index) => setCurrentIndex(index);

  return (
    <div className="carousel-wrapper">
      {/* Application logo in the top-left corner */}
      <div className="carousel-logo"></div>

      <div className={`carousel-image slide-${currentIndex}`}>
        {/* Optional content for each slide */}
      </div>
      
      <div className="carousel-controls">
        {Array.from({ length: totalSlides }).map((_, index) => (
          <button
            key={index}
            className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => goToImage(index)}
          ></button>
        ))}
      </div>

      {/* Interval control (if needed) */}
      <div className="interval-control">
       
      </div>
    </div>
  );
};

export default Carousel;
