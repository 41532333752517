import React from 'react';
import { Pagination, Form } from 'react-bootstrap';

const PaginationComponent = ({ page, totalPages, handlePageChange, pageSize, handlePageSizeChange }) => {
  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
     <Form.Group controlId="pageSizeSelect" className="d-flex align-items-center">
  <Form.Label className="mb-0 mr-2">Page Size</Form.Label>
  <Form.Control as="select" value={pageSize} onChange={handlePageSizeChange} style={{ width: 'auto' }}>
    <option value={10}>10</option>
    <option value={50}>50</option>
    <option value={100}>100</option>
    <option value={200}>200</option>
    <option value={300}>300</option>
  </Form.Control>
</Form.Group>

      <Pagination>
        {[...Array(totalPages).keys()].map(number => (
          <Pagination.Item key={number + 1} active={number + 1 === page} onClick={() => handlePageChange(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
