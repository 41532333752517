import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Tab, Tabs, Alert } from 'react-bootstrap';
import FormSubmissions from './FormSubmissions';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

const FormTabs = ({ formId, childForms,parentId,parentForm,parentName }) => {
  const [roleSettings, setRoleSettings] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [activeKey, setActiveKey] = useState(childForms && childForms.length > 0 ? childForms[0].formId.toString() : null);

  useEffect(() => {
    const role = (localStorage.getItem('role'));
    if (role) {
      fetchRoleSettings(role);
    }
  }, []);

  const fetchRoleSettings = async () => {
    const role = localStorage.getItem('role'); // Retrieve role from local storage
    const orgId = localStorage.getItem('company'); // Retrieve organization ID
  
    try {
      // Validate role and orgId
      if (!formId || !role || !orgId) {
        toast.error('Form ID, Role, and Organization ID are required');
        return;
      }
  
      const response = await axios.get(
        `${API_URL}/role-settings/${formId}`, 
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}` 
          },
          params: { 
            role, // Pass role as a query parameter
            orgId // Pass organization ID as a query parameter
          }
        }
      );
  
      const settings = response.data;
  
      if (settings && settings.length > 0) {
        setRoleSettings(settings); // Populate role settings if found
        setIsAuthorized(true);
      } else {
        setRoleSettings([]); // Clear settings if none are found
        setIsAuthorized(false); // Mark as unauthorized
      }
    } catch (error) {
      console.error('Failed to fetch role settings:', error);
      toast.error('Failed to fetch role settings');
      setIsAuthorized(false);
    }
  };

  if (!isAuthorized) {
    return (
      <Alert variant="danger">
        You are not authorized to view this page.
      </Alert>
    );
  }

  if (!childForms || childForms.length === 0) {
    return (
      <Alert variant="warning">
        No child forms available.
      </Alert>
    );
  }

  return (
    <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
      {childForms.map((childForm) => (
        <Tab eventKey={childForm.formId?.toString()} title={childForm.entityName} key={childForm.formId}>
          <FormSubmissions parentField={childForm.key} formId={childForm.formId} roleSettings={roleSettings?.find(setting => setting.id === childForm.formId)} formName={childForm.entityName} parentId={parentId} parentForm={parentForm} parentName={parentName} parentFormId={formId}/>
        </Tab>
      ))}
    </Tabs>
  );
};

export default FormTabs;
