import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, FormControl, InputGroup, ListGroup, Form } from 'react-bootstrap';
import SendMessage from './MessageSender'; // Import MessageSender component

const API_URL = process.env.REACT_APP_API_URL;

function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false); // For message popup
  const [messageGroupId, setMessageGroupId] = useState(null); // Store selected group for messaging
  const [type, setType] = useState('Notification'); // Default type is Notification
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchGroups();
    fetchUsers();
  }, [page, search]);

  const fetchGroups = useCallback(async () => {
    try {
      const organizationId = localStorage.getItem('company');
      const response = await axios.get(`${API_URL}/groups`, {
        params: { page, limit, search, organizationId },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setGroups(response.data.groups || []); // Handle case where response is not an array
      setTotal(response.data.total);
    } catch (error) {
      toast.error('Failed to fetch groups');
    }
  }, [page, limit, search]);

  const fetchUsers = useCallback(async () => {
    try {
      const organizationId = localStorage.getItem('company');
      const response = await axios.get(`${API_URL}/users/${organizationId}/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUsers(response.data.users);
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  }, []);

  const handleCreateOrUpdateGroup = async () => {
    if (editingGroup) {
      await handleUpdateGroup();
    } else {
      await handleCreateGroup();
    }
    setShowModal(false);
  };

  const handleCreateGroup = async () => {
    try {
      const organizationId = localStorage.getItem('company'); // Include organizationId
      if(groups.length>4){
        toast.error('Only 5 groups allowed'); // Success message using Toaster
        return
      }
      const response = await axios.post(
        `${API_URL}/groups`,
        { name: newGroupName, users: selectedUsers, organizationId, type },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      setGroups([...groups, response.data]);
      setNewGroupName('');
      setSelectedUsers([]);
      toast.success('Group created successfully'); // Success message using Toaster
    } catch (error) {
      toast.error('Failed to create group');
    }
  };

  const handleUpdateGroup = async () => {
    try {
      const organizationId = localStorage.getItem('company');
      await axios.put(
        `${API_URL}/groups/${editingGroup.id}`,
        { name: newGroupName, users: selectedUsers, organizationId, type },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      fetchGroups();
      setNewGroupName('');
      setSelectedUsers([]);
      setEditingGroup(null);
      toast.success('Group updated successfully'); // Success message using Toaster
    } catch (error) {
      toast.error('Failed to update group');
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      await axios.delete(`${API_URL}/groups/${groupId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setGroups(groups.filter((group) => group.id !== groupId));
      toast.success('Group deleted successfully'); // Success message using Toaster
    } catch (error) {
      toast.error('Failed to delete group');
    }
  };

  const handleEditGroup = (group) => {
    setEditingGroup(group);
    setNewGroupName(group.name);
    setSelectedUsers(group.users.map((user) => user));
    setShowModal(true);
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers(
      selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleSendMessageToGroup = (groupId) => {
    setMessageGroupId(groupId);
    setShowMessageModal(true); // Open the message modal
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page when searching
  };

  const totalPages = Math.ceil(total / limit);

  // Handle close modal after success in SendMessage component
  const handleMessageSent = () => {
    setShowMessageModal(false); // Close the message modal on success
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Group Management</h1>
              </div>
              <div className="col-sm-6">
            {/*}    <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search Groups"
                    aria-label="Search Groups"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <Button variant="outline-secondary">Search</Button>
  </InputGroup>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  <i className="fas fa-plus"></i> Add Group
                </Button>
              </div>
            </div>

            {groups.length === 0 ? (
              <div className="alert alert-warning">No group configured for the organization</div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            
                            <th>Name</th>
                            <th>Type</th>
                            <th>Users</th>
                            <th style={{ width: '250px' }}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groups?.map((group) => (
                            <tr key={group.id}>
                              
                              <td>{group.name}</td>
                              <td>{group.type || 'Notification'}</td> {/* Default Type */}
                              <td>{group?.users?.map((user) => user).join(', ')}</td>
                              <td>
                             
                                <Button
                                  variant="primary"
                                  onClick={() => handleDeleteGroup(group.id)}
                                  className="me-2"
                                >
                                  Delete
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => handleSendMessageToGroup(group.id)} // Open message modal
                                >
                                  Message to Group
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                
                   {/*   <div class="card-footer clearfix">      <div className="pagination">
                        {Array.from({ length: totalPages }, (_, i) => (
                          <button
                            key={i}
                            className={`page-link ${i + 1 === page ? 'active' : ''}`}
                            onClick={() => setPage(i + 1)}
                          >
                            {i + 1}
                          </button>
                        ))}
                        </div></div>*/}
                    </div>
                  </div>
                </div>
              </div>
            )}
            
          </div>
          
        </div>
      </div>

      {/* Add/Edit Group Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{editingGroup ? 'Edit Group' : 'Create Group'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table ">
            <tbody>
              <tr>
                <td>
                  <label>Group Name</label>
                </td>
                <td>
                  <input
                    type="text" 
                    className="form-control"                  
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Type</label> {/* Added Type Attribute */}
                </td>
                <td>
                  <Form.Control as="select" value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="Notification">Notification</option>
                    <option value="Others">Others</option>
                  </Form.Control>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Select Users</label>
                </td>
                <td>
                  <ListGroup>
                    {users.map((user) => (
                      <ListGroup.Item
                        key={user.id}
                        active={selectedUsers.includes(user.id)}
                        onClick={() => handleUserSelection(user.id)}
                      >
                        {user.username}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateOrUpdateGroup}>
            {editingGroup ? 'Update Group' : 'Create Group'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Send Message to Group Modal */}
      <Modal show={showMessageModal} onHide={() => setShowMessageModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Send Message to Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SendMessage groupId={messageGroupId} onMessageSent={handleMessageSent} /> {/* Pass groupId and onMessageSent */}
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default GroupManagement;
