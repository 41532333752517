import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import { toast } from 'react-toastify';
import { Button, Modal, Form, Collapse } from 'react-bootstrap'; // Added Collapse

const API_URL = process.env.REACT_APP_API_URL;

const ApplicationList = ({ selectedApplication, onApplicationSelect, showJsonModal,isButtonDisabled,jsonContent,applicationName }) => {
  const [isCollapsed, setIsCollapsed] = useState(true); // This will toggle vertical collapse
  const [applications, setApplications] = useState([]); // State for storing application data
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [defaultApplication, setDefaultApplication] = useState(null);
  const [selectedApplicationId, setSelectedApplicationId] = useState(selectedApplication);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Toggle collapse state
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchApplications();
    }, 1000); // Set a delay of 500ms
  
    // Cleanup function to clear the timeout if dependencies change
    return () => clearTimeout(timer);
  }, [showJsonModal]);
  

  const fetchApplications = async () => {
    try {
      const response = await axios.get(`${API_URL}/erds`, {
        params: { userId: localStorage.getItem('user') },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const applicationsData = Array.isArray(response.data) ? response.data : [];
      setApplications(applicationsData);

      const defaultApplication = applicationsData.find((app) => app.is_default) || applicationsData[0];
      const groupedApplications = groupAndSortApplications(applicationsData);
      if (defaultApplication) {
        setDefaultApplication(defaultApplication.id);
        setSelectedApplicationId(defaultApplication.id);
        onApplicationSelect(defaultApplication); // Trigger the callback
      }
    } catch (error) {
      toast.error('Failed to fetch applications');
    }
  };

  const handleDelete = async () => {
    if (!applicationToDelete) return;

    try {
      await axios.delete(`${API_URL}/erds/${applicationToDelete.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const updatedApplications = applications.filter((app) => app.id !== applicationToDelete.id);
      setApplications(updatedApplications);

      if (defaultApplication === applicationToDelete.id) {
        const newDefaultApplication = updatedApplications[0];
        setDefaultApplication(newDefaultApplication?.id || null);
        setSelectedApplicationId(newDefaultApplication?.id || null);
      }

      setShowDeleteModal(false);
      toast.success('Application deleted successfully');
    } catch (error) {
      toast.error('Failed to delete application');
    }
  };

  const handleSetDefault = async (applicationId) => {
    try {
      await axios.post(`${API_URL}/erds/default`, { companyId: localStorage.getItem('company'), id: applicationId }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      setDefaultApplication(applicationId);
      setSelectedApplicationId(applicationId);
      toast.success('Default application set successfully');
    } catch (error) {
      toast.error('Failed to set default application');
    }
  };
  const groupAndSortApplications = (applications) => {
    const groupedApplications = {};

    // Group applications by name
    applications.forEach((app) => {
      if (!groupedApplications[app.name]) {
        groupedApplications[app.name] = [];
      }
      groupedApplications[app.name].push(app);
    });

    // Sort each group by created_at date and assign versions
    Object.keys(groupedApplications).forEach((appName) => {
      groupedApplications[appName].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      groupedApplications[appName].forEach((app, index) => {
        app.version = `v${index + 1}`; // Assign version based on order in group
      });
    });

    // Flatten grouped applications back into a single array
    return Object.values(groupedApplications).flat();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={toggleCollapse}
        style={{
          position: 'fixed',
          left: '1420px',
          zIndex: 1050, // High z-index to ensure it appears above other elements
        }}
      >
        {isCollapsed ? 'Show Application List' : 'Hide Application List'}
      </Button>

      {/* The overlayed application list */}
      <div
        style={{
          padding:'5px',
          position: 'fixed',
          top: '50px',
          right: isCollapsed ? '-300px' : '0px', // Slide in/out from the right
          width: '300px',
          height: '760px', // Full height of the viewport
          backgroundColor: '#f8f9fa',
          transition: 'right 0.3s ease',
          zIndex: 1049, // Slightly lower than the toggle button so it doesn't cover it
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', // Add a shadow for a floating effect
          overflowY: 'auto', // Scroll if content overflows
        }}
      >
       <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: '20px' }}>Select</th>
              <th style={{ width: '300px' }}>Application Name</th>
              <th>Version</th> {/* New column for Version */}
              <th style={{ width: '10px' }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {applications.map((application) => (
              <tr
                key={application.id}
                style={{
                  backgroundColor: selectedApplicationId === application.id ? '#7e80df' : 'transparent',
                  color: selectedApplicationId === application.id ? '#fff' : '#000',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedApplicationId(application.id);
                  onApplicationSelect(application);
                }}
              >
                <td style={{ textAlign: 'center' }}>
                  <Form.Check
                    type="radio"
                    name="defaultApplication"
                    id={`application-${application.id}`}
                    checked={defaultApplication === application.id}
                    onChange={() => handleSetDefault(application.id)}
                  />
                </td>
                <td>{application.name}</td>
                <td>{application.version}</td> {/* Display Version */}
                <td style={{ textAlign: 'center' }}>
                  <Button
                    variant="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setApplicationToDelete(application);
                      setShowDeleteModal(true);
                    }}
                    style={{ padding: '0', color: 'red' }}
                    title="Delete"
                  >
                    <i className="fas fa-trash-alt icon-style"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Delete Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this application?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete} disabled={isButtonDisabled}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApplicationList;
