import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Button, Modal } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

const MessageNotification = () => {
  const userId = localStorage.getItem('user');
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    fetchUnreadMessagesCount();
  }, []);

  const fetchUnreadMessagesCount = async () => {
    try {
      const response = await axios.get(`${API_URL}/messages/unread/count/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUnreadCount(response.data);
    } catch (error) {
      console.error('Failed to fetch unread messages count', error);
    }
  };

  const fetchUnreadMessages = async () => {
    try {
      const response = await axios.get(`${API_URL}/messages/unread/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUnreadMessages(response.data);
    } catch (error) {
      console.error('Failed to fetch unread messages', error);
    }
  };

  const markMessageAsRead = async () => {
    try {
      await axios.post(`${API_URL}/messages/read`, { userId, messageId: selectedMessage.id }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUnreadMessages(unreadMessages.filter((message) => message.id !== selectedMessage.id));
      setUnreadCount(unreadCount - 1);
      setShowModal(false);
      setSelectedMessage(null);
    } catch (error) {
      console.error('Failed to mark message as read', error);
    }
  };

  const handleShowMessage = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Button variant="" onClick={() => { setShowPopover(!showPopover); fetchUnreadMessages(); }}>
          <i  style={{ color: '#292b3c!important' }}  className="fa fa-bell icon-colored "></i> {unreadCount > 0 && <span style={{ color: 'red' }}>{unreadCount}</span>}
        </Button>
        {showPopover && (
          <div style={{
            zIndex: 6002,
            position: 'absolute',
            top: '50px',
            right: '0px',
            width: '350px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            overflow: 'hidden'
          }}>
            <div style={{ padding: '10px', borderBottom: '1px solid #e5e5e5', backgroundColor: '#f8f9fa' }}>
              <h4 style={{ margin: '0', fontSize: '16px', color: '#333' }}>New Messages</h4>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {unreadMessages.length === 0 ? (
                <div style={{ padding: '10px', textAlign: 'center', color: '#888' }}>
                  No unread messages
                </div>
              ) : (
                unreadMessages.map(message => (
                  <div key={message.id} style={{ padding: '10px', borderBottom: '1px solid #e5e5e5', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => handleShowMessage(message)}>
                    <div style={{ marginRight: '10px' }}>
                      <img src={`https://ui-avatars.com/api/?name=${message.sender_name}`} alt={message.sender_name} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <div style={{ fontWeight: 'bold', color: '#333' }}>{message.subject}</div>
                      <div style={{ fontSize: '12px', color: '#888' }}>from {message.sender_name}</div>
                      <div style={{ fontSize: '12px', color: '#888' }}>{new Date(message.created_at).toLocaleString()}</div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f8f9fa', borderTop: '1px solid #e5e5e5' }}>
            </div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{selectedMessage?.subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>From: </strong>{selectedMessage?.sender_name}</p>
          <p>{selectedMessage?.body}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={markMessageAsRead}>
            Mark as Read
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MessageNotification;
