import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const API_URL = process.env.REACT_APP_API_URL;
function initializePermission() {
    return {
      role_id: '',
      module: '',
      function_name: '',
      access: true,
    };
  }
function FunctionPermissions() {
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]); // List of existing modules
  const [functions, setFunctions] = useState([]); // List of existing functions
  const [newPermission, setNewPermission] = useState(initializePermission());
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Determines if editing mode is active
  const [editingId, setEditingId] = useState(null); // Stores the ID of the permission being edited

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
    fetchModules();
    fetchFunctions();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/roles`, { headers: getAuthHeaders() });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${API_URL}/function-permissions`, { headers: getAuthHeaders() });
      setPermissions(response.data);
    } catch (error) {
      toast.error('Failed to fetch permissions');
    }
  };

  const fetchModules = async () => {
    try {
      const response = await axios.get(`${API_URL}/function-permissions/modules/view`, { headers: getAuthHeaders() });
      setModules(response.data.map((module) => ({ label: module.name, value: module.name })));
    } catch (error) {
      toast.error('Failed to fetch modules');
    }
  };

  const fetchFunctions = async () => {
    try {
      const response = await axios.get(`${API_URL}/function-permissions/functions/view`, { headers: getAuthHeaders() });
      setFunctions(response.data.map((func) => ({ label: func.name, value: func.name })));
    } catch (error) {
      toast.error('Failed to fetch functions');
    }
  };

  //const initializePermission = () => ({
  //  role_id: '',
  //  module: '',
  //  function_name: '',
  //  access: true,
  //});
const handleAddOrUpdatePermission = async () => {
    try {
        const apiUrl = isEditing
            ? `${API_URL}/function-permissions/${editingId}`
            : `${API_URL}/function-permissions`;
        const method = isEditing ? 'put' : 'post';
        await axios[method](apiUrl, newPermission, { headers: getAuthHeaders() });
        fetchPermissions();
        setShowModal(false);
        toast.success(isEditing ? 'Permission updated successfully' : 'Permission added successfully');
        setIsEditing(false); // Reset editing state
        setEditingId(null);  // Clear editing ID
    } catch (error) {
        toast.error(isEditing ? 'Failed to update permission' : 'Failed to add permission');
    }
};

const handleEditPermission = (permission) => {
    setNewPermission({
        module: permission.module,
        function_name: permission.function_name,
        roles: permission.roles,
        access: permission.access,
    });
    setEditingId(permission.id); // Set the ID of the permission being edited
    setIsEditing(true);         // Enable editing mode
    setShowModal(true);         // Show the modal
};

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  });
  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditing(false);
    setEditingId(null);
    setNewPermission(initializePermission()); // Reset permission data
};

  const handleAddPermission = async () => {
    try {
      await axios.post(`${API_URL}/function-permissions`, newPermission, { headers: getAuthHeaders() });
      fetchPermissions();
      setShowModal(false);
      toast.success('Permission added successfully');
    } catch (error) {
      toast.error('Failed to add permission');
    }
  };

  const handleDeletePermission = async (id) => {
    try {
      await axios.delete(`${API_URL}/function-permissions/${id}`, { headers: getAuthHeaders() });
      fetchPermissions();
      toast.success('Permission deleted successfully');
    } catch (error) {
      toast.error('Failed to delete permission');
    }
  };

  const filteredPermissions = permissions.filter((permission) =>
    permission.module.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="content-wrapper1">
        <div className="content-header1">
          <div className="container-fluid1">
            <div className="row mb-1">
              <div className="col-sm-6">
                <h3 className="m-10">Role level Function Permissions</h3>
              </div>
           
            </div>
          </div>
        </div>
        <div className="content1">
          <div className="container-fluid">
            <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
              <i className="fas fa-plus"></i> Add Permission
            </Button>
            <Form.Group controlId="formModuleFilter" className="mb-3">
                <Form.Control
                    as="select"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                >
                    <option value="">All Modules</option>
                    {modules?.map((module) => (
                        <option key={module.value} value={module.value}>
                            {module.label}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <div className="card">
              <div className="card-body">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>Function</th>
                      <th>Role</th>
                      <th>Access</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPermissions.map((permission) => (
                      <tr key={permission.id}>
                        <td>{permission.module}</td>
                        <td>{permission.function_name}</td>
                        <td>{permission.roles.map((role) => role.role).join(', ')}</td>
                        <td>
                            <Form.Check
                                type="switch"
                                checked={permission.access}
                                onChange={async (e) => {
                                    try {
                                        const updatedPermission = { ...permission, access: e.target.checked };
                                        await axios.put(`${API_URL}/function-permissions/${permission.id}`, updatedPermission, {
                                            headers: getAuthHeaders(),
                                        });
                                        fetchPermissions();
                                    } catch (error) {
                                        toast.error('Failed to update access');
                                    }
                                }}
                            />
                        </td>
                        <td>
                        <Button variant="secondary" onClick={() => handleEditPermission(permission)}>
                            Edit
                        </Button>
                        <Button variant="secondary" onClick={() => handleDeletePermission(permission.id)}>
                            Delete
                        </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Permission Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formModule">
            <Form.Label>Module</Form.Label>
            <CreatableSelect
              isClearable
              options={modules}
              value={modules.find((m) => m.value === newPermission.module)}
              onChange={(selected) =>
                setNewPermission({ ...newPermission, module: selected ? selected.value : '' })
              }
            />
          </Form.Group>
          <Form.Group controlId="formFunction">
            <Form.Label>Function</Form.Label>
            <CreatableSelect
              isClearable
              options={functions}
              value={functions.find((f) => f.value === newPermission.function_name)}
              onChange={(selected) =>
                setNewPermission({ ...newPermission, function_name: selected ? selected.value : '' })
              }
            />
          </Form.Group>
          <Form.Group controlId="formRoles">
                <Form.Label>Roles</Form.Label>
                <CreatableSelect
                    isMulti
                    options={roles?.map((role) => ({ label: role.name, value: role.id }))}
                    value={newPermission?.roles?.map((role) => ({
                        label: role.role,
                        value: role.role_id,
                    }))}
                    onChange={(selected) =>
                        setNewPermission({
                            ...newPermission,
                            roles: selected
                                ? selected.map((s) => ({ role_id: s.value, role: s.label }))
                                : [],
                        })
                    }
                />
            </Form.Group>
          <Form.Group controlId="formAccess">
            <Form.Check
              type="checkbox"
              label="Access Allowed"
              checked={newPermission.access}
              onChange={(e) => setNewPermission({ ...newPermission, access: e.target.checked })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddPermission}>
            Save Permission
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default FunctionPermissions;
