import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Table } from 'react-bootstrap';
import Layout from './Layout';
import RoleSetting from './AppRoleSetting';

const API_URL = process.env.REACT_APP_API_URL;

const AppRoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [roleSettings, setRoleSettings] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [showRoleSettingModal, setShowRoleSettingModal] = useState(false);
  const [editRoleSetting, setEditRoleSetting] = useState(null);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/roles/approles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  };

  const fetchFilteredRoleSettings = async () => {
    try {
      const response = await axios.get(`${API_URL}/role-settings/approles`, {
        params: { role: selectedRole },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRoleSettings(response.data);
    } catch (error) {
      toast.error('Failed to fetch filtered role settings');
    }
  };

  const handleDeleteRoleSetting = async (roleSettingId) => {
    try {
      await axios.delete(`${API_URL}/role-settings/${roleSettingId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchFilteredRoleSettings();
      toast.success('Role setting deleted successfully');
    } catch (error) {
      toast.error('Failed to delete role setting');
    }
  };

  const openEditRoleSettingModal = (roleSetting) => {
    setEditRoleSetting(roleSetting);
    setShowRoleSettingModal(true);
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h4 className="m-0">App Role Assignment</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-3 align-items-center">
              <div className="col-md-8">
                <select
                  className="form-control"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 text-end">
                <Button variant="primary" onClick={fetchFilteredRoleSettings}>
                  Search
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Table bordered>
                      <thead>
                        <tr>
                        
                          <th>Role</th>
                          <th>Form</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th>Clone</th>
                          <th>Create</th>
                          <th style={{width:'60px'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleSettings.map((setting) => (
                          <tr key={setting.id}>                           
                            <td>{setting.role_name}</td>
                            <td>{setting.form_name}</td>
                            <td>{setting.is_edit ? 'Yes' : 'No'}</td>
                            <td>{setting.is_delete ? 'Yes' : 'No'}</td>
                            <td>{setting.is_clone ? 'Yes' : 'No'}</td>
                            <td>{setting.is_create ? 'Yes' : 'No'}</td>
                            <td>
                              <Button
                                variant="warning"
                                onClick={() => openEditRoleSettingModal(setting)}
                                className="me-2"
                              >
                                <i className="fas fa-edit"></i>
                              </Button>                                                       
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RoleSetting
          show={showRoleSettingModal}
          handleClose={() => {
            setShowRoleSettingModal(false);
            setEditRoleSetting(null);
          }}
          editData={editRoleSetting}
          fetchRoleSettings={fetchFilteredRoleSettings}
        />

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Layout>
  );
};

export default AppRoleManagement;
