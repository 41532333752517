import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    username: '',
    email: '',
    password: '',
    role_id: '',
    profile_photo: '',
    first_name: '',
    last_name: '',
    sex: '',
    department: '',
    date_of_birth: '',
    phone_number: '',
    company_id: '',
    membership: '',
    permanent_address_street1: '',
    permanent_address_street2: '',
    permanent_address_city: '',
    permanent_address_state: '',
    permanent_address_country: '',
    permanent_address_zip: '',
    current_address_street1: '',
    current_address_street2: '',
    current_address_city: '',
    current_address_state: '',
    current_address_country: '',
    current_address_zip: '',
    special_notes: '',
    attachments: [],
  });

  const [editingUser, setEditingUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [page, search]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        params: { page, limit, search },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(response.data.users);
      setTotal(response.data.total);
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  }, [page, limit, search]);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  }, []);

  const handleLoginAsUser = async (userId) => {
    try {
      const response = await axios.post(
        `${API_URL}/auth/login-as-user`,
        { userId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      localStorage.clear();
      // Store the new token and redirect
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', response.data.user.id);
      localStorage.setItem('role', response.data.user.role_id);
      localStorage.setItem('company', response.data.user.company_id);
      toast.success('Logged in as user successfully');
      window.location.href = '/dashboard'; // Or the desired page for the logged-in user
    } catch (error) {
      toast.error('Failed to login as user');
    }
  };

  const handleCloneUser = (user) => {
    // Create a clone of the user with slight modifications (e.g., username and email)
    const clonedUser = {
      ...user,
      username: `${user.username}_clone`,
      email: `${user.email.split('@')[0]}_clone@${user.email.split('@')[1]}`,
    };
    setNewUser(clonedUser);
    setShowAddModal(true);
  };

  const handleAddUser = async () => {
    try {
      const formData = new FormData();
      Object.entries(newUser).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((file, index) => formData.append(`${key}[${index}]`, file));
        } else {
          formData.append(key, value);
        }
      });
      await axios.post(`${API_URL}/users${newUser.id?"/clone":""}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setNewUser({
        username: '',
        email: '',
        password: '',
        role_id: '',
        profile_photo: '',
        first_name: '',
        last_name: '',
        sex: '',
        department: '',
        date_of_birth: '',
        phone_number: '',
        company_id: '',
        membership: '',
        permanent_address_street1: '',
        permanent_address_street2: '',
        permanent_address_city: '',
        permanent_address_state: '',
        permanent_address_country: '',
        permanent_address_zip: '',
        current_address_street1: '',
        current_address_street2: '',
        current_address_city: '',
        current_address_state: '',
        current_address_country: '',
        current_address_zip: '',
        special_notes: '',
        attachments: [],
      });
      setShowAddModal(false);
      fetchUsers();
      toast.success('User added successfully');
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setShowEditModal(true);
  };

  const handleUpdateUser = async () => {
    if (!editingUser || !editingUser.id) {
      toast.error('Invalid user ID');
      return;
    }

    try {
      const formData = new FormData();
      Object.entries(editingUser).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((file, index) => formData.append(`${key}[${index}]`, file));
        } else {
          formData.append(key, value);
        }
      });

      await axios.put(`${API_URL}/users/${editingUser.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setEditingUser(null);
      setShowEditModal(false);
      fetchUsers();
      toast.success('User updated successfully');
    } catch (error) {
      toast.error('Failed to update user');
    }
  };

  const handleDeleteUser = async () => {
    if (!currentUser || !currentUser.id) {
      toast.error('Invalid user ID');
      return;
    }

    try {
      await axios.delete(`${API_URL}/users/${currentUser.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setShowDeleteModal(false);
      fetchUsers();
      toast.success('User deleted successfully');
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const totalPages = Math.ceil(total / limit);

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
              <div className="col-sm-6">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search Users"
                    aria-label="Search Users"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <Button variant="outline-secondary">Search</Button>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowAddModal(true)}>
                  <i className="fas fa-plus"></i> Add User
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                  <table className="table table-bordered">
    <thead>
      <tr>
        <th  style={{ width: '50px', height: '50px' }}>Photo</th>
        <th>Username</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Login as</th>
        <th style={{ width: '150px' }}>Actions</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user.id}>
          <td>
            {user.profile_photo && (
              <img  className="img-circle elevation-3"
                src={`${API_URL.replace('/api', '')}${user.profile_photo}`}
                alt="Profile"
                style={{ width: '30px', height: '30px' }}
              />
            )}
          </td>
          <td>{user.username}</td>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td>{user.email}</td>
          <td>{user.role_id ? roles.find(role => role.id === user.role_id)?.name : 'N/A'}</td>
          <td><Button
              variant="primary"
              onClick={() => handleLoginAsUser(user.id)}
            >
              Login as User
            </Button></td>
          <td>
            <Button variant="primary" onClick={() => handleEditUser(user)} className="me-2 ">
              <i className="fas fa-edit "></i>
            </Button>
            <Button className="me-2 "
              variant="primary"
              onClick={() => {
                setCurrentUser(user);
                setShowDeleteModal(true);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
            <Button className="me-2 "
                                variant="primary"
                                onClick={() => handleCloneUser(user)}
                              >
                                <i className="fas fa-clone"></i>
                              </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
                    <div className="pagination">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i}
                          className={`page-link ${i + 1 === page ? 'active' : ''}`}
                          onClick={() => setPage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>{newUser.id ? 'Clone User' : 'Add User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td><label>Username</label></td>
                <td><input
                  type="text"
                  placeholder="Username"
                  value={newUser.username}
                  onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Email</label></td>
                <td><input
                  type="email"
                  placeholder="Email"
                  value={newUser.email}
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Password</label></td>
                <td><input
                  type="password"
                  placeholder="Password"
                  value={newUser.password}
                  onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Profile Photo</label></td>
                <td><input
                  type="file"
                  onChange={(e) => setNewUser({ ...newUser, profile_photo: e.target.files[0] })}
                /></td>
              </tr>
              <tr>
                <td><label>Role</label></td>
                <td>
                  <select
                    value={newUser.role_id}
                    onChange={(e) => setNewUser({ ...newUser, role_id: e.target.value })}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {/* Add additional fields for first name, last name, etc. */}
              <tr>
                <td><label>First Name</label></td>
                <td><input
                  type="text"
                  placeholder="First Name"
                  value={newUser.first_name}
                  onChange={(e) => setNewUser({ ...newUser, first_name: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Last Name</label></td>
                <td><input
                  type="text"
                  placeholder="Last Name"
                  value={newUser.last_name}
                  onChange={(e) => setNewUser({ ...newUser, last_name: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Sex</label></td>
                <td>
                  <select
                    value={newUser.sex}
                    onChange={(e) => setNewUser({ ...newUser, sex: e.target.value })}
                  >
                    <option value="">Select Sex</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Department</label></td>
                <td><input
                  type="text"
                  placeholder="Department"
                  value={newUser.department}
                  onChange={(e) => setNewUser({ ...newUser, department: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Date of Birth</label></td>
                <td><input
                  type="date"
                  value={newUser.date_of_birth}
                  onChange={(e) => setNewUser({ ...newUser, date_of_birth: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Phone Number</label></td>
                <td><input
                  type="text"
                  placeholder="Phone Number"
                  value={newUser.phone_number}
                  onChange={(e) => setNewUser({ ...newUser, phone_number: e.target.value })}
                /></td>
              </tr>
         {/*     <tr>
                <td><label>Permanent Address</label></td>
                <td>
                  <input
                    type="text"
                    placeholder="Street 1"
                    value={newUser.permanent_address_street1}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_street1: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Street 2"
                    value={newUser.permanent_address_street2}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_street2: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    value={newUser.permanent_address_city}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_city: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    value={newUser.permanent_address_state}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_state: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    value={newUser.permanent_address_country}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_country: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="ZIP Code"
                    value={newUser.permanent_address_zip}
                    onChange={(e) => setNewUser({ ...newUser, permanent_address_zip: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td><label>Current Address</label></td>
                <td>
                  <input
                    type="text"
                    placeholder="Street 1"
                    value={newUser.current_address_street1}
                    onChange={(e) => setNewUser({ ...newUser, current_address_street1: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Street 2"
                    value={newUser.current_address_street2}
                    onChange={(e) => setNewUser({ ...newUser, current_address_street2: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="City"
                    value={newUser.current_address_city}
                    onChange={(e) => setNewUser({ ...newUser, current_address_city: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="State"
                    value={newUser.current_address_state}
                    onChange={(e) => setNewUser({ ...newUser, current_address_state: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="Country"
                    value={newUser.current_address_country}
                    onChange={(e) => setNewUser({ ...newUser, current_address_country: e.target.value })}
                  />
                  <input
                    type="text"
                    placeholder="ZIP Code"
                    value={newUser.current_address_zip}
                    onChange={(e) => setNewUser({ ...newUser, current_address_zip: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td><label>Special Notes</label></td>
                <td><textarea
                  className="form-control"
                  rows="3"
                  value={newUser.special_notes}
                  onChange={(e) => setNewUser({ ...newUser, special_notes: e.target.value })}
                ></textarea></td>
              </tr>
              <tr>
                <td><label>Attachments</label></td>
                <td>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => setNewUser({ ...newUser, attachments: [...newUser.attachments, ...Array.from(e.target.files)] })}
                  />
                  {newUser?.attachments?.length > 0 && (
                    <ul>
                      {newUser?.attachments?.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  )}
                </td>
                      </tr>*/}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingUser && (
            <table className="table">
              <tbody>
                <tr>
                  <td><label>Username</label></td>
                  <td><input
                    type="text"
                    placeholder="Username"
                    value={editingUser.username}
                    onChange={(e) => setEditingUser({ ...editingUser, username: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Email</label></td>
                  <td><input
                    type="email"
                    placeholder="Email"
                    value={editingUser.email}
                    onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Password</label></td>
                  <td><input
                    type="password"
                    placeholder="Password"
                    value={editingUser.password}
                    onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Profile Photo</label></td>
                  <td><input
                    type="file"
                    onChange={(e) => setEditingUser({ ...editingUser, profile_photo: e.target.files[0] })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Role</label></td>
                  <td>
                    <select
                      value={editingUser.role_id}
                      onChange={(e) => setEditingUser({ ...editingUser, role_id: e.target.value })}
                    >
                      <option value="">Select Role</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                {/* Add additional fields for first name, last name, etc. */}
                <tr>
                  <td><label>First Name</label></td>
                  <td><input
                    type="text"
                    placeholder="First Name"
                    value={editingUser.first_name}
                    onChange={(e) => setEditingUser({ ...editingUser, first_name: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Last Name</label></td>
                  <td><input
                    type="text"
                    placeholder="Last Name"
                    value={editingUser.last_name}
                    onChange={(e) => setEditingUser({ ...editingUser, last_name: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Sex</label></td>
                  <td>
                    <select
                      value={editingUser.sex}
                      onChange={(e) => setEditingUser({ ...editingUser, sex: e.target.value })}
                    >
                      <option value="">Select Sex</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td><label>Department</label></td>
                  <td><input
                    type="text"
                    placeholder="Department"
                    value={editingUser.department}
                    onChange={(e) => setEditingUser({ ...editingUser, department: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Date of Birth</label></td>
                  <td><input
                    type="date"
                    value={editingUser.date_of_birth}
                    onChange={(e) => setEditingUser({ ...editingUser, date_of_birth: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Phone Number</label></td>
                  <td><input
                    type="text"
                    placeholder="Phone Number"
                    value={editingUser.phone_number}
                    onChange={(e) => setEditingUser({ ...editingUser, phone_number: e.target.value })}
                  /></td>
                </tr>
           {/*      <tr>
                  <td><label>Permanent Address</label></td>
                  <td>
                    <input
                      type="text"
                      placeholder="Street 1"
                      value={editingUser.permanent_address_street1}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_street1: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="Street 2"
                      value={editingUser.permanent_address_street2}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_street2: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="City"
                      value={editingUser.permanent_address_city}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_city: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="State"
                      value={editingUser.permanent_address_state}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_state: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="Country"
                      value={editingUser.permanent_address_country}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_country: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="ZIP Code"
                      value={editingUser.permanent_address_zip}
                      onChange={(e) => setEditingUser({ ...editingUser, permanent_address_zip: e.target.value })}
                    />
                  </td>
                </tr>
               <tr>
                  <td><label>Current Address</label></td>
                  <td>
                    <input
                      type="text"
                      placeholder="Street 1"
                      value={editingUser.current_address_street1}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_street1: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="Street 2"
                      value={editingUser.current_address_street2}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_street2: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="City"
                      value={editingUser.current_address_city}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_city: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="State"
                      value={editingUser.current_address_state}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_state: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="Country"
                      value={editingUser.current_address_country}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_country: e.target.value })}
                    />
                    <input
                      type="text"
                      placeholder="ZIP Code"
                      value={editingUser.current_address_zip}
                      onChange={(e) => setEditingUser({ ...editingUser, current_address_zip: e.target.value })}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>Special Notes</label></td>
                  <td><textarea
                    className="form-control"
                    rows="3"
                    value={editingUser.special_notes}
                    onChange={(e) => setEditingUser({ ...editingUser, special_notes: e.target.value })}
                  ></textarea></td>
                </tr>
                <tr>
                  <td><label>Attachments</label></td>
                  <td>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => setEditingUser({ ...editingUser, attachments: [...editingUser.attachments, ...Array.from(e.target.files)] })}
                    />
                    {editingUser?.attachments?.length > 0 && (
                      <ul>
                        {editingUser?.attachments?.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    )}
                  </td>
                        </tr>*/}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {currentUser && currentUser.username}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default UserManagement;
