import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, Table } from 'react-bootstrap';
import RoleSetting from './RoleSetting';
import PermissionManagement from './FunctionPermissions';
import Accordion from '../ui-components/Accordion'
import { Tabs, Tab } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL;

function RoleManagement() {
  const [roles, setRoles] = useState([]);
  const [roleSettings, setRoleSettings] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [showRoleSettingModal, setShowRoleSettingModal] = useState(false);
  const [editRoleSetting, setEditRoleSetting] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const [key, setKey] = useState('roles'); // State for active tab
  useEffect(() => {
    fetchRoles();
   // fetchRoleSettings();
    fetchOrganizations();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_URL}/roles`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setRoles(response.data);
    } catch (error) {
      toast.error('Failed to fetch roles');
    }
  };
  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${API_URL}/organizations`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setOrganizations(response.data);
    } catch (error) {
      toast.error('Failed to fetch organizations');
    }
  };
  const fetchFilteredRoleSettings = async () => {
    if (!selectedOrg || !selectedRole) return;
  
    try {
      const response = await axios.get(`${API_URL}/role-settings`, {
        params: { orgId: selectedOrg, role: selectedRole },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRoleSettings(response.data);
    } catch (error) {
      toast.error('Failed to fetch filtered role settings');
    }
  };
  
  const fetchRoleSettings = async () => {
    try {
      const response = await axios.get(`${API_URL}/role-settings`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setRoleSettings(response.data);
    } catch (error) {
      toast.error('Failed to fetch role settings');
    }
  };

  const handleAddRole = async () => {
    try {
      await axios.post(`${API_URL}/roles`, { name: newRole }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setNewRole('');
      setShowAddRoleModal(false);
      fetchRoles();
      toast.success('Role added successfully');
    } catch (error) {
      toast.error('Failed to add role');
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      await axios.delete(`${API_URL}/roles/${roleId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchRoles();
      toast.success('Role deleted successfully');
    } catch (error) {
      toast.error('Failed to delete role');
    }
  };

  const handleDeleteRoleSetting = async (roleSettingId) => {
    try {
      await axios.delete(`${API_URL}/role-settings/${roleSettingId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchRoleSettings();
      toast.success('Role setting deleted successfully');
    } catch (error) {
      toast.error('Failed to delete role setting');
    }
  };

  const handleCloneRoleSetting = async (roleSettingId) => {
    try {
      await axios.post(`${API_URL}/role-settings/clone/${roleSettingId}`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      fetchRoleSettings();
      toast.success('Role setting cloned successfully');
    } catch (error) {
      toast.error('Failed to clone role setting');
    }
  };

  const openEditRoleSettingModal = (roleSetting) => {
    setEditRoleSetting(roleSetting);
    setShowRoleSettingModal(true);
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Role Management</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
          <Tabs  id="organization-management-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}  className="mb-3">
          <Tab eventKey="roles" title="Roles Creation">
            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowAddRoleModal(true)}>
                  <i className="fas fa-plus"></i> Add Role
                </Button>               
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roles.map((role) => (
                          <tr key={role.id}>
                            <td>{role.id}</td>
                            <td>{role.name}</td>
                            <td>
                              <Button variant="danger" onClick={() => handleDeleteRole(role.id)}>
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            </Tab>
            <Tab eventKey="assignPermission" title="Assign Permission">
            <PermissionManagement/>
            </Tab>
            <Tab eventKey="assignRoles" title="Assign Roles">
            <div className="row mb-3 align-items-center">
  <div className="col-md-4">
    <select
      className="form-control"
      value={selectedOrg}
      onChange={(e) => setSelectedOrg(e.target.value)}
    >
      <option value="">Select Organization</option>
      {organizations.map((org) => (
        <option key={org.id} value={org.id}>{org.name}</option>
      ))}
    </select>
  </div>
  <div className="col-md-4">
    <select
      className="form-control"
      value={selectedRole}
      onChange={(e) => setSelectedRole(e.target.value)}
    >
      <option value="">Select Role</option>
      {roles.map((role) => (
        <option key={role.id} value={role.id}>{role.name}</option>
      ))}
    </select>
  </div>
  <div className="col-md-4 text-end">
    <Button variant="primary" onClick={fetchFilteredRoleSettings}>
      Search
    </Button>
  </div>
</div>



            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowRoleSettingModal(true)} className="ms-2">
                  <i className="fas fa-plus"></i> Create Role Setting
                </Button>
              </div>
            </div>
           
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Role</th>
                          <th>Form</th>
                          <th>Edit</th>
                          <th>Delete</th>
                          <th>Clone</th>
                          <th>View</th>
                          <th>Create</th>
                          <th>CSV <i className="fas fa-file-download"></i></th>
                          <th>XLSX  <i className="fas fa-file-download"></i></th>
                          <th>PDF  <i className="fas fa-file-download"></i></th>
                          <th>Excel <i className="fas fa-file-upload"></i></th>
                          <th>CSV <i className="fas fa-file-upload"></i></th>
                          <th>Audit</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleSettings.map((setting) => (
                          <tr key={setting.id}>
                            <td>{setting.id}</td>
                            <td>{setting.role_name}</td>
                            <td>{setting.form_name}</td>
                            <td>{setting.is_edit ? 'Yes' : 'No'}</td>
                            <td>{setting.is_delete ? 'Yes' : 'No'}</td>
                            <td>{setting.is_clone ? 'Yes' : 'No'}</td>
                            <td>{setting.is_view ? 'Yes' : 'No'}</td>
                            <td>{setting.is_create ? 'Yes' : 'No'}</td>
                            <td>{setting.is_csv_download ? 'Yes' : 'No'}</td>
                            <td>{setting.is_xlsx_download ? 'Yes' : 'No'}</td>
                            <td>{setting.is_pdf_download ? 'Yes' : 'No'}</td>
                            <td>{setting.is_excel_upload ? 'Yes' : 'No'}</td>
                            <td>{setting.is_csv_upload ? 'Yes' : 'No'}</td>
                            <td>{setting.is_audit ? 'Yes' : 'No'}</td>
                            <td>
                              <Button variant="warning" onClick={() => openEditRoleSettingModal(setting)} className="me-2">
                                <i className="fas fa-edit"></i>
                              </Button>
                              <Button variant="danger" onClick={() => handleDeleteRoleSetting(setting.id)} className="me-2">
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                              <Button variant="info" onClick={() => handleCloneRoleSetting(setting.id)}>
                                <i className="fas fa-clone"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {/* Add Role Modal */}
      <Modal show={showAddRoleModal} onHide={() => setShowAddRoleModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Role Name"
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddRoleModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddRole}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Role Setting Modal */}
      <RoleSetting
        show={showRoleSettingModal}
        handleClose={() => {
          setShowRoleSettingModal(false);
          setEditRoleSetting(null);
        }}
        editData={editRoleSetting}
        fetchRoleSettings={fetchRoleSettings}
      />

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default RoleManagement;
