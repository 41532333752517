import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

function ApplicationManagement() {
  const [applications, setApplications] = useState([]);
  const [themes, setThemes] = useState([]); // State to store themes
  const [newApplication, setNewApplication] = useState({ name: '', logo: '', isDefault: false, themeId: '' });
  const [editingApplication, setEditingApplication] = useState(null);
  const [currentApplication, setCurrentApplication] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchApplications();
    fetchThemes(); // Fetch themes when the component mounts
  }, [page, search]);

  const fetchApplications = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/applications`, {
        params: { page, limit, search },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setApplications(response.data.applications);
      setTotal(response.data.total);
    } catch (error) {
      toast.error('Failed to fetch applications');
    }
  }, [page, limit, search]);

  const fetchThemes = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/themes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setThemes(response.data);
    } catch (error) {
      toast.error('Failed to fetch themes');
    }
  }, []);

  const handleAddApplication = async () => {
    try {
      const formData = new FormData();
      formData.append('name', newApplication.name);
      formData.append('logo', newApplication.logo);
      formData.append('isDefault', newApplication.isDefault);
      formData.append('themeId', newApplication.themeId); // Add themeId to the form data
      await axios.post(`${API_URL}/applications`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setNewApplication({ name: '', logo: '', isDefault: false, themeId: '' });
      setShowAddModal(false);
      fetchApplications();
      toast.success('Application added successfully');
    } catch (error) {
      toast.error('Failed to add application');
    }
  };

  const handleEditApplication = (application) => {
    setEditingApplication(application);
    setShowEditModal(true);
  };

  const handleUpdateApplication = async () => {
    if (!editingApplication || !editingApplication.id) {
      toast.error('Invalid application ID');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', editingApplication.name);
      formData.append('logo', editingApplication.logo);
      formData.append('isDefault', editingApplication.isDefault);
      formData.append('themeId', editingApplication.themeId); // Add themeId to the form data
      await axios.put(`${API_URL}/applications/${editingApplication.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setEditingApplication(null);
      setShowEditModal(false);
      fetchApplications();
      toast.success('Application updated successfully');
    } catch (error) {
      toast.error('Failed to update application');
    }
  };

  const handleDeleteApplication = async () => {
    if (!currentApplication || !currentApplication.id) {
      toast.error('Invalid application ID');
      return;
    }

    try {
      await axios.delete(`${API_URL}/applications/${currentApplication.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setShowDeleteModal(false);
      fetchApplications();
      toast.success('Application deleted successfully');
    } catch (error) {
      toast.error('Failed to delete application');
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to the first page when searching
  };

  const totalPages = Math.ceil(total / limit);

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Application Management</h1>
              </div>
              <div className="col-sm-6">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Search Applications"
                    aria-label="Search Applications"
                    aria-describedby="basic-addon2"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <Button variant="outline-secondary">Search</Button>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12">
                <Button variant="primary" onClick={() => setShowAddModal(true)}>
                  <i className="fas fa-plus"></i> Add Application
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Logo</th>
                          <th>Default</th>
                          <th>Theme</th>
                          <th style={{width: "120px"}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applications.map((application) => (
                          <tr  key={application.id}>
                            <td>{application.id}</td>
                            <td>{application.name}</td>
                            <td>
                              <img src={`${API_URL.replace('/api', '')}${application.logo}`} alt="Logo" style={{ width: '50px', height: '50px' }} />
                            </td>
                            <td>{application.isDefault ? 'Yes' : 'No'}</td>
                            <td>{application.themeId ? themes.find(theme => theme.id === application.themeId)?.name : 'N/A'}</td>
                            <td>
                              <Button variant="warning" onClick={() => handleEditApplication(application)}>
                                <i className="fas fa-edit"></i>
                              </Button>
                              <Button 
                               variant="danger" onClick={() => { setCurrentApplication(application); setShowDeleteModal(true); }}>
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i}
                          className={`page-link ${i + 1 === page ? 'active' : ''}`}
                          onClick={() => setPage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Application Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton  className="custom-modal-header">
          <Modal.Title>Add Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td><label>Application Name</label></td>
                <td><input
                  type="text"
                  placeholder="Application Name"
                  value={newApplication.name}
                  onChange={(e) => setNewApplication({ ...newApplication, name: e.target.value })}
                /></td>
              </tr>
              <tr>
                <td><label>Logo</label></td>
                <td><input
                  type="file"
                  onChange={(e) => setNewApplication({ ...newApplication, logo: e.target.files[0] })}
                /></td>
              </tr>
              <tr>
                <td><label>Set as Default</label></td>
                <td>
                  <input
                    type="checkbox"
                    checked={newApplication.isDefault}
                    onChange={(e) => setNewApplication({ ...newApplication, isDefault: e.target.checked })}
                  />
                </td>
              </tr>
              <tr>
                <td><label>Theme</label></td>
                <td>
                  <select
                    value={newApplication.themeId}
                    onChange={(e) => setNewApplication({ ...newApplication, themeId: e.target.value })}
                  >
                    <option value="">Select Theme</option>
                    {themes.map((theme) => (
                      <option key={theme.id} value={theme.id}>
                        {theme.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddApplication}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Application Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Edit Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingApplication && (
            <table className="table">
              <tbody>
                <tr>
                  <td><label>Application Name</label></td>
                  <td><input
                    type="text"
                    placeholder="Application Name"
                    value={editingApplication.name}
                    onChange={(e) => setEditingApplication({ ...editingApplication, name: e.target.value })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Logo</label></td>
                  <td><input
                    type="file"
                    onChange={(e) => setEditingApplication({ ...editingApplication, logo: e.target.files[0] })}
                  /></td>
                </tr>
                <tr>
                  <td><label>Set as Default</label></td>
                  <td>
                    <input
                      type="checkbox"
                      checked={editingApplication.isDefault}
                      onChange={(e) => setEditingApplication({ ...editingApplication, isDefault: e.target.checked })}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>Theme</label></td>
                  <td>
                    <select
                      value={editingApplication.themeId}
                      onChange={(e) => setEditingApplication({ ...editingApplication, themeId: e.target.value })}
                    >
                      <option value="">Select Theme</option>
                      {themes.map((theme) => (
                        <option key={theme.id} value={theme.id}>
                          {theme.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateApplication}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Delete Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {currentApplication && currentApplication.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteApplication}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default ApplicationManagement;
