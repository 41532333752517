import React, { useState,useEffect } from 'react';
import axios from '../axiosConfig';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import zxcvbn from 'zxcvbn'; // Password strength library
import './Login.css'; // Use the same CSS from registration for consistency
import Toaster from '../ui-components/Toaster'; // Import the Toaster component
import Loader from './BLoader'; // Import the Loader component

const API_URL = process.env.REACT_APP_API_URL;

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(null); // Track password strength
  const [appName, setAppName] = useState('');
  const [appLogo, setAppLogo] = useState('');
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const token = query.get('token'); // Extract token from URL
  useEffect(() => {
    const fetchAppSettings = async () => {
    const response = await axios.get(`${API_URL}/applications/getapp`, {
    params: { isDefault: 'true' },
    });

    const appData = response.data.applications[0];
  const appLogoUrl = `${API_URL.replace('/api', '')}${appData.logo}`;
  setAppName(appData.name.replace(/_/g, ' '));
  setAppLogo(appLogoUrl);
    }
    fetchAppSettings();
  })

  // Password strength checker
  const evaluatePasswordStrength = (password) => {
    if (password) {
      const evaluation = zxcvbn(password);
      setPasswordStrength(evaluation);
    } else {
      setPasswordStrength(null);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    evaluatePasswordStrength(newPassword); // Check password strength
  };
 
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/reset-password`, {
        token,
        password,
        confirmPassword,
      });
      toast.success(response.data.message); // Show success message
      history.push('/login'); // Redirect to login after successful reset
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.error || 'Error resetting password'); // Show error message
    }
  };

  const getStrengthColor = (score) => {
    switch (score) {
      case 0:
        return 'red';
      case 1:
        return 'orange';
      case 2:
        return 'yellow';
      case 3:
        return 'lightgreen';
      case 4:
        return 'green';
      default:
        return 'grey';
    }
  };

  return (
    <div className="auth-page">
       {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
        <div className="login-wrapper">
      {/* Toaster Component for displaying toast notifications */}
      <Toaster />

      <div className="login-box">
      <div className="login-logo">
      <div class="centered-logo-container">
  <div class="logo"></div>
</div>    
          <b>{appName}</b>
        </div>
        <div className="card">
          <div className="card-body auth-card-body">
            <p className="auth-box-msg">Reset your password</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  value={password}
                  onChange={handlePasswordChange} // Track password changes
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>

             

              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
               {/* Password Strength Bar */}
               {passwordStrength && (
                <div className="password-strength-bar">
                  <div
                    className="password-strength-indicator"
                    style={{
                      backgroundColor: getStrengthColor(passwordStrength.score),
                      width: `${(passwordStrength.score + 1) * 20}%`,
                    }}
                  ></div>
                </div>
              )}<br></br>
              <div className="row">
                <div className="col-4">
                <div className="icheck-primary">
                  <button
                    type="submit" 
                    className="btn btn-primary btn-block"
                  >
                    Reset
                  </button>
                  </div>
                </div>
              </div>
            </form>
            <p className="mb-1">
            <div className="icheck-primary">
              <Link to="/login"> {/* Custom link color */}
                Back to login
              </Link>
              </div>
            </p>
          </div>
        </div>
      </div>
      </div>
    </div>
    
  );
}

export default ResetPassword;
