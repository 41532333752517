import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const filterSchema = (schema) => {
  const filteredComponents = schema.components.filter(component => !component.mask);
  return { ...schema, components: filteredComponents };
};

const CreateComponent = ({ formSchema, showModal, setShowModal, handleSubmit, formName }) => {
  const formRef = useRef(null);
  const [formInstance, setFormInstance] = useState(null);

  useEffect(() => {
    if (showModal && formSchema && formRef.current) {
      const filteredSchema = filterSchema(formSchema);
      window.Formio.createForm(formRef.current, filteredSchema).then(formioForm => {
        formioForm.on('submit', handleSubmit);
        setFormInstance(formioForm);
      });
    }
  }, [showModal, formSchema]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>{formName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div ref={formRef}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => formInstance.submit()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateComponent;
