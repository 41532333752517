import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from React Router
import axios from '../axiosConfig';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carousel from './Carousel';
import useMediaQuery from '../hooks/useMediaQuery';
import Loader from '../components/BLoader'; // Import the Loader component

const API_URL = process.env.REACT_APP_API_URL;

function Layout({ children }) {
  const [defaultApp, setDefaultApp] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Get the current route
  const isMobileOrTablet = useMediaQuery('(max-width: 768px)');
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  useEffect(() => {
    const storedApp = JSON.parse(localStorage.getItem('defaultApp'));

    if (storedApp) {
      setDefaultApp(storedApp);
      applyTheme(storedApp.themeId);
      setLoading(false);
    } else {
      fetchDefaultApp();
    }
  }, []);

  const fetchDefaultApp = async () => {
    try {
      const response = await axios.get(`${API_URL}/applications/getapp`, {
        params: { isDefault: 'true' },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const appData = response.data.applications[0];
      setDefaultApp(appData);
      localStorage.setItem('defaultApp', JSON.stringify(appData));
      applyTheme(appData.themeId);
    } catch (error) {
      console.error('Failed to fetch default application', error);
    } finally {
      setLoading(false);
    }
  };

  const applyTheme = (themeId) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.id = 'theme-css';
    document.head.appendChild(link);

    link.onload = () => console.log('Theme loaded successfully');
    link.onerror = () => console.error('Failed to load theme CSS');
  };

  useEffect(() => {
    if (defaultApp) {
      document.title = defaultApp.name;
      const appLogo = document.getElementById('app-logo');
      if (appLogo) {
        const logoUrl = `${API_URL.replace('/api', '')}${defaultApp.logo}`;
        appLogo.src = logoUrl;
        appLogo.alt = defaultApp.name;
      }
    }
  }, [defaultApp]);

  if (loading) return <div><Loader /></div>;

  const isLoginPage = location.pathname === '/login'; // Check if the route is login

  return (
    <div className="wrapper">
       {isMobileOrTablet && (
        <img id="app-logo" className="mobile-logo" src="/path/to/logo.png" alt="App Logo" />
      )}
      <Header />
      <Sidebar />
      {!isLoginPage && isMobileOrTablet && <Carousel />} {/* Show carousel only on non-login mobile/tablet views */}
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
