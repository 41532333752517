import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const filterSchema = (schema) => {
  const filteredComponents = schema.components.filter(component => !component.mask);
  return { ...schema, components: filteredComponents };
};

const EditComponent = ({ formSchema, showEditModal, setShowEditModal, handleEditSubmit, editingSubmission }) => {
  const editFormRef = useRef(null);
  const [editFormInstance, setEditFormInstance] = useState(null);

  useEffect(() => {
    if (showEditModal && formSchema && editFormRef.current) {
      const filteredSchema = filterSchema(formSchema);
      window.Formio.createForm(editFormRef.current, filteredSchema).then(formioForm => {
        formioForm.submission = editingSubmission.submission_data;
        formioForm.on('submit', handleEditSubmit);
        setEditFormInstance(formioForm);
      });
    }
  }, [showEditModal, formSchema, editingSubmission]);

  return (
    <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Edit </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div ref={editFormRef}></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => editFormInstance.submit()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditComponent;
