import axios from 'axios';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Configure NProgress
NProgress.configure({ showSpinner: false });

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set your base URL here
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  NProgress.start();

  // Check if the URL contains "icons.json"
  if (config.url && config.url.includes('icons.json')) {
    // Remove baseURL for icons.json
    delete config.baseURL;
  }

  return config;
}, (error) => {
  NProgress.done();
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();

    if (error.response) {
      // Handle 401 Unauthorized
      if (error.response.status === 401) {
        toast.error('Your session has expired. Please log in again.');       
          window.location.href = '/login'; // Redirect to login page      
      }

      // Handle 403 Forbidden
      if (error.response.status === 403) {
        toast.error('You are not authorized to access this resource.');
         setTimeout(() => {
          window.location.href = '/login'; // Redirect to login page
        }, 3000); // 2-second delay
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
