import React, { useState, useEffect,useMemo } from 'react';
import axios from '../../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AIGenerateDrawer from './AiApplicationGenerator.js';
import { Modal, Button, Form ,Dropdown, ButtonGroup  } from 'react-bootstrap';
import {
  useNodesState,
  useEdgesState,
  addEdge,
} from 'react-flow-renderer';
import EntityNode from './EntityNode';
import EntityModal from './EntityModal';
import ERDTable from './ERDTable';
import ApplicationList from './ApplicationList';
import ImportExport from './ImportExport';

import Loader from '../Loader'; // Import the Loader component
import BLoader from '../BLoader'; // Import the Loader component
const API_URL = process.env.REACT_APP_API_URL;
const demoid = process.env.REACT_APP_DEMOID;
//const nodeTypes = {
//  entityNode: EntityNode,
//};

function ERDManagement() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showAddEntityModal, setShowAddEntityModal] = useState(false);
  const [showEditEntityModal, setShowEditEntityModal] = useState(false);
  const [showDeleteEntityModal, setShowDeleteEntityModal] = useState(false);
  const [showJsonModal, setShowJsonModal] = useState(false);
  const [jsonContent, setJsonContent] = useState('');
  const [entityName, setEntityName] = useState('');
  const [attributes, setAttributes] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState('');
  const [defaultApplication, setDefaultApplication] = useState('');
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [applicationName, setApplicationName] = useState('');
  const [showDeployModal, setShowDeployModal] = useState(false);
  const [deployOption, setDeployOption] = useState(null); // Track selected option
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [isBLoading, setIsBLoading] = useState(false); // New state for loading
  const tags = ['Tag1', 'Tag2', 'Tag3'];
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // New state for button disable logic
  const role = localStorage.getItem('role'); // Assuming the role is stored in 'role'
  const toggleDrawer = () => setShowDrawer(!showDrawer);
  const [showDrawer, setShowDrawer] = useState(false);
  useEffect(() => {
    //if (selectedApplication) {
      fetchERDJson(selectedApplication);
      if (role && demoid && role === demoid) { // Adjust role check as needed
        setIsButtonDisabled(true);
      }
   // }
  }, []);
  const nodeTypes = useMemo(
    () => ({
      entityNode: (props) => (
        <EntityNode {...props} onDelete={handleDeleteEntity} onClone={handleCloneEntity}/>
      ),
    }),
    [] // Dependency ensures it updates only if handleDeleteEntity changes
  );
  const handleNewApp = () => {
    setNodes([]); // Clear nodes
    setEdges([]); // Clear edges
    setApplicationName(''); // Reset application name
    toast.info('New app started. ERD cleared.');
  };

  const transformResponse = (nodes) => {
    return nodes.map(({ id, entity, attributes }, index) => ({
      id,
      type: 'entityNode',
      data: {
        id:id,
        label: entity,
        attributes,
      },
      position: { 
        x: 50 + index * 200, // Add 100 to x for each iteration
        y: 50 + index * 50, // Add 100 to y for each iteration
      },
    }));
  };
  const openDeployModal = () => {
    setShowDeployModal(true);
  };
  
  const updateApp = async () => {
    try {
      const organization_id = localStorage.getItem('company');
      const createdBy = localStorage.getItem('user');
      const token = localStorage.getItem('token');
  
      // Check if required localStorage values are available
      if (!organization_id || !createdBy || !token) {
        toast.error('Missing required information for update.');
        return;
      }
  
      // Sending update request
      const response = await axios.post(
        `${API_URL}/build/deploy/update`,
        { organization_id, createdBy },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Check if response data is valid and update is in progress
      if (response.data) {
        toast.success('Update is in Progress');
        setIsLoading(true); // Show the loader while updating
        const interval = setInterval(() => {
          window.location.reload(); // Optionally refresh the page after a period
        }, 15000);
      } else {
        toast.error('Unexpected response from server.');
      }
    } catch (error) {
      console.error('Update error:', error); // Log error for debugging
      const errorMessage = error.response?.data?.message || 'Failed to Update';
      toast.error(errorMessage);
    }
  };
  
  const buildApp = async () => {
    try {
      const organization_id = localStorage.getItem('company');
      const createdBy = localStorage.getItem('user');
      const token = localStorage.getItem('token');
  
      // Check if required localStorage values are available
      if (!organization_id || !createdBy || !token) {
        toast.error('Missing required information for deployment.');
        return;
      }
  
      // Sending deployment request
      const response = await axios.post(
        `${API_URL}/build/deploy`,
        { organization_id, createdBy },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Check if response data is valid and deployment is in progress
      if (response.data ) {
        setIsLoading(true); // Show the loader
        toast.success('Deployment is in Progress');
          const interval = setInterval(() => {
            window.location.reload(); // Refresh the entire page every 30 seconds
          }, 15000); 
      } else {
        toast.error('Unexpected response from server.');
      }
    } catch (error) {
      console.error('Deployment error:', error); // Log error for debugging
      const errorMessage = error.response?.data?.message || 'Failed to Deploy';
      toast.error(errorMessage);
    }
  };
  const validateNodes = (nodes) => {
    return nodes.every(
      (node) =>
        node.position && node.position.x !== undefined && node.position.y !== undefined
    );
  };
  const onApplicationSelect  = async (application) => {
    if (application) {
      const nodes = transformResponse(application.json_data.nodes || []);
      const edges = application.json_data.relationships || [];
      setApplicationName(application.name);
      if (validateNodes(nodes)) {
        setNodes(nodes);
        setEdges(edges);
      } else {
        toast.error('Invalid node structure detected.');
      }
    } else {
      //toast.info('No ERD data available');
    }
  }
  const handleCloneEntity = (entity) => {
    if (!entity || !entity.label || !entity.attributes ) {
      toast.error('Invalid entity structure for cloning.');
      return;
    }
  
    // Generate a cloned entity name
    const clonedEntityName = `${entity.label}_clone`;
  
    // Check if an entity with the cloned name already exists
    const isEntityNameDuplicate = nodes.some(
      (node) => node.data.label.trim().toLowerCase() === clonedEntityName.trim().toLowerCase()
    );
  
    if (isEntityNameDuplicate) {
      toast.error(`An entity with the name "${clonedEntityName}" already exists.`);
      return;
    }
  
    // Create the cloned entity object
    const clonedEntity = {
      id: `${parseInt(entity.id)*100}`, // Append `_clone` to the ID
      type: 'entityNode', // Assuming this is the type
      data: {
        label: clonedEntityName, // Append `_clone` to the name
        attributes: [...entity.attributes], // Clone attributes
      },
      position: {
        x:  0, // Offset to avoid overlap
        y:  0,
      },
    };
  
    // Add the cloned entity to the nodes array
    setNodes((prevNodes) => [...prevNodes, clonedEntity]);
    toast.success(`Entity "${clonedEntityName}" cloned successfully!`);
  };
  

  const fetchERDJson = async (applicationId) => {
    try {
      const response = await axios.get(`${API_URL}/erds/default`, {
        params: { userId : localStorage.getItem('user') },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.data.length > 0) {
        const nodes = transformResponse(response.data[0].json_data.nodes || []);
        const edges = response.data[0].json_data.relationships || [];

        if (validateNodes(nodes)) {
          setNodes(nodes);
          setEdges(edges);
        } else {
          toast.error('Invalid node structure detected.');
        }
      } else {
       // toast.info('No ERD data available');
      }
    } catch (error) {
      toast.error('Failed to fetch ERD JSON');
    }
  };

  const saveERD = async () => {
    setIsBLoading(true);
    if (!applicationName.trim()) {
      toast.error('Application name is required.');
      return;
    }
  
    const json = {
      nodes: nodes.map((node) => ({
        id: node.id,
        entity: node.data.label,
        attributes: node.data.attributes,
      })),
      relationships: edges.map((edge) => ({
        source: edge.source,
        target: edge.target,
        sourceHandle: edge.sourceHandle,
        targetHandle: edge.targetHandle,
        label: edge.label,
      })),
    };
  
    setJsonContent(JSON.stringify(json, null, 2));
  
    try {
      // If application ID is present, update the existing record
      if (selectedApplication) {
        await axios.put(
          `${API_URL}/erds/${selectedApplication.id}`,
          {
            json_data: json,
            updated_by: localStorage.getItem('user'),
            name: applicationName,
            companyId: localStorage.getItem('company'),
            is_default:selectedApplication.is_default
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }
        );
        toast.success('ERD updated successfully!');
      } else {
        // If no application ID, create a new record
        await axios.post(
          `${API_URL}/erds`,
          {
            json_data: json,
            created_by: localStorage.getItem('user'),
            organization: localStorage.getItem('company'),
            name: applicationName,
            is_default: false,
            companyId: localStorage.getItem('company'),
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }
        );
        toast.success('ERD saved successfully!');
      }
      //setShowJsonModal(true);
      setIsBLoading(false);
    } catch (error) {
      setIsBLoading(false);
      toast.error(error.response.data.error);
    }
  };
  const handleDeployAction = async () => {
    if (deployOption === 'refresh') {
      // Call the original deployment (refresh) logic
      await buildApp();
    } else if (deployOption === 'update') {
      // Call the update deployment logic (update only, no deletion)
      await updateApp(); // This will call the new update method in the backend
    }
    setShowDeployModal(false); // Close modal after action
  };

  const checkApplicationNameUnique = async (name) => {
    try {
      const response = await axios.get(`${API_URL}/applications/check-name`, {
        params: { name },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      return response.data.isUnique;
    } catch (error) {
      toast.error('Error checking application name uniqueness.');
      return false;
    }
  };

  const handleSaveEntity = () => {
    if (!entityName.trim()) {
      // Check if the name is empty
      toast.error('Entity name cannot be empty.');
      return;
    }
  
 
  
    // Check for duplicate entity names (case-insensitive)

  
    // Proceed with saving the entity
    if (selectedEntityId) {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === selectedEntityId
            ? {
                ...node,
                data: { label: entityName, attributes: [...attributes] },
              }
            : node
        )
      );
      setShowEditEntityModal(false);
      toast.success('Entity updated successfully!');
    } else {
      // Add new entity logic if required (optional)
      toast.error('No entity selected to update.');
    }
  };
  
  const handleImportJson = (name, jsonData) => {
    try {
      const nodes = transformResponse(jsonData.nodes || []);
      const edges = jsonData.relationships || [];

      if (validateNodes(nodes)) {
        setNodes(nodes);
        setEdges(edges);
        setApplicationName(name);
        toast.success('JSON imported and applied successfully! Save the Data and deploy ');
      } else {
        toast.error('Invalid node structure in JSON.');
      }
    } catch (error) {
      toast.error('Failed to import JSON.');
    }
  };

  const handleExportJson = () => {
    const json = {
      nodes: nodes.map((node) => ({
        id: node.id,
        entity: node.data.label,
        attributes: node.data.attributes,
      })),
      relationships: edges.map((edge) => ({
        source: edge.source,
        target: edge.target,
        sourceHandle: edge.sourceHandle,
        targetHandle: edge.targetHandle,
        label: edge.label,
      })),
    };
    const blob = new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${applicationName || 'erd'}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    toast.success('JSON exported successfully!');
  }
  

  const handleAddEntity = () => {
    const isEntityNameDuplicate = nodes.some(
      node =>
        node.data.label.trim().toLowerCase() === entityName.trim().toLowerCase() &&
        node.id !== selectedEntityId // Ignore the current entity being edited
    );
  
    if (isEntityNameDuplicate) {
      toast.error('Table with this name already exists. Please use a different name.');
      return;
    }

       // Check if the ID attribute is present (case-insensitive)
       const hasID = attributes.some(attr => attr.name.toLowerCase() === 'id');
       if (!hasID) {
         toast.error('The "ID" attribute is mandatory.');
         return;
       }
     
       // Check if the Name attribute is present (case-insensitive)
       const hasName = attributes.some(attr => attr.name.toLowerCase() === 'name');
       if (!hasName) {
         toast.error('The "Name" attribute is mandatory.');
         return;
       }

    if (entityName.trim()) {
      const newEntity = {
        id: (nodes.length + 1).toString(),
        type: 'entityNode',
        data: { label: entityName, attributes: [...attributes] },
        position: { x: Math.random() * 250, y: Math.random() * 250 },
      };
      setNodes((nds) => [...nds, newEntity]);
      setEntityName('');
      setAttributes([]);
      setShowAddEntityModal(false);
      toast.success('Entity added successfully!');
    } else {
      toast.error('Entity name cannot be empty.');
    }
  };

  const handleDeleteEntity = async (id) => {
    try {
      //setNodes((nds) => nds.filter((node) => node.id !== selectedEntityId));
      //setEdges((eds) =>
      //  eds.filter(
      //    (edge) => edge.source !== selectedEntityId && edge.target !== selectedEntityId
      //  )
      //);
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
      setEdges((prevEdges) =>
        prevEdges.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setSelectedEntityId(null);
      setShowDeleteEntityModal(false);
      setContextMenu(null);
      toast.success('Entity deleted successfully!');
    } catch (error) {
      toast.error('Failed to delete entity');
    }
  };

  const handleDeleteEdge = () => {
    setEdges((eds) => eds.filter((edge) => edge.id !== selectedEdge.id));
    setSelectedEdge(null);
    setContextMenu(null);
    toast.success('Edge deleted successfully!');
  };

  const onConnect = (params) => {
    const relationshipType = prompt(
      'Enter the relationship type (1:N for One-to-Many, N:1 for Many-to-One):',
      '1:N'
    );

    const newEdge = {
      ...params,
      label: relationshipType,
    };

    setEdges((eds) => addEdge(newEdge, eds));
  };

  const onLoad = (rfi) => {
    rfi.fitView();
  };

  const handleNodeDoubleClick = (_, node) => {
    setEntityName(node.data.label);
    setAttributes(node.data.attributes);
    setSelectedEntityId(node.id);
    setShowEditEntityModal(true);
  };

  const handleEdgeDoubleClick = (_, edge) => {
    setSelectedEdge(edge);
    handleDeleteEdge();
  };

  const handleContextMenu = (event, edge) => {
    event.preventDefault();
    setSelectedEdge(edge);
    setContextMenu({
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      edgeId: edge.id,
    });
  };
  const handleShowAddEntityModal = () => {
    setEntityName('');  // Reset entity name to empty
    setAttributes([]);  // Clear attributes
    setShowAddEntityModal(true);  // Show the Add Entity modal
  };
  const closeContextMenu = () => setContextMenu(null);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <div className="content1">
          <div className="container-fluid">
          <div className="row mb-3">
              <div className="col-12 d-flex align-items-center" style={{ backgroundColor: 'rgb(81 146 210)',color:'#fff'}}>
              <Form.Group controlId="formApplicationName" className="d-flex align-items-center mr-3">
                <Form.Label style={{ marginRight: '10px', minWidth: '150px', marginTop:'10px'}}>Application Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter application name"
                  value={applicationName}
                  onChange={(e) => setApplicationName(e.target.value)}
                  style={{ width: '200px' }}
                />
              </Form.Group>
              <Dropdown as={ButtonGroup}>
                  {/* Main Button */}
                  <Button variant="primary" disabled={isButtonDisabled} >
                    Generate New App
                  </Button>
                  {/* Dropdown Toggle */}
                  <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="mr-2"/>

                  {/* Dropdown Menu */}
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleNewApp}>Manual Creation</Dropdown.Item>
                    <Dropdown.Item onClick={toggleDrawer}>AI Creation</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button variant="primary" onClick={handleShowAddEntityModal} className="mr-2" disabled={isButtonDisabled}>
                  <i className="fas fa-plus"></i> Add Table
                </Button>
                <Button variant="primary" onClick={saveERD} className="mr-2" disabled={isButtonDisabled}>
                <i className="fas fa-save"></i> Save Application
                </Button>
                <Button variant="primary" className="mr-2" onClick={openDeployModal} disabled={isButtonDisabled}>
                  Deploy Application
                </Button>
                <ApplicationList
        selectedApplication={selectedApplication}
        onApplicationSelect={onApplicationSelect}
        showJsonModal={showJsonModal}
        isButtonDisabled={isButtonDisabled}
        jsonContent={jsonContent}
        applicationName={applicationName}
      />
       <ImportExport onImport={handleImportJson} onExport={handleExportJson} isButtonDisabled={isButtonDisabled}/>
                {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
                {isBLoading && <BLoader />} {/* Show Loader if isLoading is true */}
              </div>
            </div>

            <ERDTable
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onLoad={onLoad}
              onNodeDoubleClick={handleNodeDoubleClick}
              onEdgeDoubleClick={handleEdgeDoubleClick}
              handleContextMenu={handleContextMenu}
              onDeleteEntity={handleDeleteEntity}
            />
          </div>
        </div>
      </div>

    

      {contextMenu && (
        <div
          style={{
            position: 'absolute',
            top: contextMenu.mouseY,
            left: contextMenu.mouseX,
            backgroundColor: '#fff',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
            padding: '10px',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button variant="link" onClick={handleDeleteEdge}>
            Delete Edge
          </Button>
        </div>
      )}

      <EntityModal
        show={showAddEntityModal}
        onHide={() => setShowAddEntityModal(false)}
        entityName={entityName}
        setEntityName={setEntityName}
        attributes={attributes}
        setAttributes={setAttributes}
        handleAddAttribute={() => {}}
        handleAddEntity={handleAddEntity}
        entities={nodes.map((node) => ({ id: node.id, label: node.data.label, attributes: node.data.attributes }))} // Pass entities to modal
      />

      <EntityModal
        show={showEditEntityModal}
        onHide={() => setShowEditEntityModal(false)}
        entityName={entityName}
        setEntityName={setEntityName}
        attributes={attributes}
        setAttributes={setAttributes}
        handleAddAttribute={() => {}}
        handleSaveEntity={handleSaveEntity}
        entities={nodes.map((node) => ({ id: node.id, label: node.data.label, attributes: node.data.attributes }))} // Pass entities to modal
        availableTags={tags}
      />
    <AIGenerateDrawer 
            show={showDrawer} 
            handleClose={() => setShowDrawer(false)} 
            setNodes={setNodes}
            setEdges={setEdges}
          />
      <Modal show={showDeleteEntityModal} onHide={() => setShowDeleteEntityModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Delete Entity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this entity? This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteEntityModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteEntity}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showJsonModal} onHide={() => setShowJsonModal(false)}>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Generated JSON</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              maxHeight: '60vh',
              overflowY: 'auto',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            <pre>{jsonContent}</pre>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowJsonModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeployModal} onHide={() => setShowDeployModal(false)}>
  <Modal.Header closeButton className="custom-modal-header">
    <Modal.Title>Deploy Options</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Select an option for deployment:</p>
    <Form.Check
      type="radio"
      label="Refresh (This will delete existing records and create new ones)"
      name="deployOption"
      value="refresh"
      onChange={() => setDeployOption('refresh')}
    />
    <Form.Check
      type="radio"
      label="Update (This will update existing records or create new ones without deletion)"
      name="deployOption"
      value="update"
      onChange={() => setDeployOption('update')}
    />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowDeployModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleDeployAction}>
      Confirm
    </Button>
  </Modal.Footer>
</Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ERDManagement;
