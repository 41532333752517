import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { Button, Table, Form, DropdownButton, Dropdown, Alert } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import FilterComponent from './FilterComponent';
import CreateComponent from './formutil/CreateComponent';
import EditComponent from './formutil/EditComponent';
import FileUpload from './formutil/FileUpload';
import PaginationComponent from './formutil/PaginationComponent';  // Import PaginationComponent
import Loader from './BLoader'; // Import the Loader component
const API_URL = process.env.REACT_APP_API_URL;

const FormSubmissions = ({ formId, settings, formName, parentId, parentForm, parentField,parentName ,parentFormId}) => {
  const history = useHistory();
  const [submissions, setSubmissions] = useState([]);
  const [formSchema, setFormSchema] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [editingSubmission, setEditingSubmission] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [headerTypes, setHeaderTypes] = useState({});
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [roleSettings, setRoleSettings] = useState(settings);
  const [qryFilters, setQryFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role) {
      fetchRoleSettings(role);
    }
  }, []);

  useEffect(() => {
    if (roleSettings !== null) {
      fetchFormDetails();
      fetchSubmissions();
    }
  }, [formId, page, pageSize, sortBy, sortOrder, roleSettings]);

  const fetchRoleSettings = async () => {
    const role = localStorage.getItem('role'); // Retrieve role from local storage
    const orgId = localStorage.getItem('company'); // Retrieve organization ID
  
    try {
      // Validate role and orgId
      if (!formId || !role || !orgId) {
        toast.error('Form ID, Role, and Organization ID are required');
        return;
      }
  
      const response = await axios.get(
        `${API_URL}/role-settings/${formId}`, 
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}` 
          },
          params: { 
            role, // Pass role as a query parameter
            orgId // Pass organization ID as a query parameter
          }
        }
      );
  
      const settings = response.data;
  
      if (settings && settings.length > 0) {
        setRoleSettings(settings[0]); // Populate role settings if found
        setIsAuthorized(true);
      } else {
        setRoleSettings([]); // Clear settings if none are found
        setIsAuthorized(false); // Mark as unauthorized
      }
    } catch (error) {
      console.error('Failed to fetch role settings:', error);
      toast.error('Failed to fetch role settings');
      setIsAuthorized(false);
    }
  };


  const fetchFormDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const form = response.data;
      setFormSchema(form.form_schema);
      setHeaderTypes(extractHeaderTypes(form.form_schema));
    } catch (error) {
      toast.error('Failed to fetch details');
    }
  };

  const extractHeaderTypes = (schema) => {
    const types = {};
    schema.components.forEach(component => {
      types[component.key] = component.type;
    });
    return types;
  };

  const extractHeadersFromSchema = (schema) => {
    const headers = [];
    const extractFromComponents = (components) => {
      components.forEach(component => {
        if (['panel', 'column', 'fieldset', 'tabs'].includes(component.type)) {
          if (component.components && component.components.length > 0) {
            extractFromComponents(component.components);
          }
        } else if (component.type === 'table') {
          component.rows.forEach(row => {
            row.forEach(cell => {
              if (Array.isArray(cell.components)) {
                extractFromComponents(cell.components);
              }
            });
          });
        } else {
          if (component.tableView) {
            headers.push({ key: component.key, label: component.label });
          }
          if (component.components && component.components.length > 0) {
            extractFromComponents(component.components);
          }
        }
      });
    };
    extractFromComponents(schema.components);
    return headers;
  };

  const fetchSubmissions = async (filters = []) => {
    setIsLoading(true); 
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}/childsubmissions`, {
        params: { page, limit: pageSize, filters: JSON.stringify(filters), sortBy, sortOrder, formName: parentForm, parentId, parentField },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSubmissions(response.data.submissions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to fetch data');
    }
    setIsLoading(false); 
  };

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const handleSubmit = async (submission) => {
    setIsLoading(true); 
    try {
      submission.data[parentField] = parentId;
      //submission.data[parentField.replace(/_id/i, '').trim()+" name"]={"parentId":parentId,"parentName":parentName,"form_id":parentFormId}
      await axios.post(`${API_URL}/forms/${formId}/submissions`, { submission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      //toast.success('Submission saved successfully');
      fetchSubmissions();
      setShowModal(false);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to save submission');
    }
    setIsLoading(false); 
  };

  const handleEditSubmit = async (submission) => {
    setIsLoading(true); 
    try {
      submission.data[`${parentForm}_Id`] = parentId;
     // await axios.put(`${API_URL}/forms/${formId}/childsubmissions/${editingSubmission.id}`, { submission }, {
     //   headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
     // });
      await axios.put(`${API_URL}/forms/${formId}/submissions/${editingSubmission.id}`, { submission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Data updated successfully');
      fetchSubmissions();
      setShowEditModal(false);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to update Data');
    }
    setIsLoading(false); 
  };

  const handleDeleteSubmission = async (id) => {
    setIsLoading(true); 
    try {
      await axios.delete(`${API_URL}/forms/${formId}/submissions/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Data deleted successfully');
      fetchSubmissions();
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to delete Data');
    }
    setIsLoading(false); 
  };

  const handleEditSubmission = (submission) => {
    setEditingSubmission(submission);
    setShowEditModal(true);
  };

  const handleCloneSubmission = async (submission) => {
    setIsLoading(true); 
    try {
      const clonedSubmission = { ...submission.submission_data };
      await axios.post(`${API_URL}/forms/${formId}/submissions`, { submission: clonedSubmission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Data cloned successfully');
      fetchSubmissions();
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to clone Data');
    }
    setIsLoading(false); 
  };

  const openCreateModal = () => {
    setShowModal(true);
  };

  const handleViewDetails = (submissionId) => {
    history.push(`/forms/${formId}/submissions/${submissionId}`);
    window.location.reload();
  };

  const headers = formSchema ? extractHeadersFromSchema(formSchema) : [];

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPage(1);
  };

  const downloadFile = (format) => {
    setIsLoading(true); 
    axios.get(`${API_URL}/forms/${formId}/download`, {
      params: { parentForm, parentId, format, filters: JSON.stringify(qryFilters) },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    }).then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : `submissions_${formId}.${format}`;
      saveAs(new Blob([response.data]), filename);
    }).catch(() => {
      setIsLoading(false); 
      toast.error(`Failed to download ${format} file`);
    });
    setIsLoading(false); 
  };

  const applyFilters = (filters) => {
    fetchSubmissions(filters);
    setQryFilters(filters);
  };

  if (!isAuthorized) {
    return (
      <Alert variant="danger">
        You are not authorized to view this page.
      </Alert>
    );
  }

  return (
    <div>
       {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
      <div className="d-flex justify-content-end mb-3 child-button">
      {roleSettings?.is_create &&(  <Button variant="primary" onClick={openCreateModal} className="me-2">
          <i className="fas fa-plus"></i> Create
        </Button>)}
        {(roleSettings?.is_csv_download || roleSettings?.is_xlsx_download || roleSettings?.is_pdf_download) && (
          <DropdownButton title="Download" variant="secondary" className="me-2">
            {roleSettings.is_csv_download && (
              <Dropdown.Item onClick={() => downloadFile('csv')}>
                <i className="fas fa-file-csv"></i> CSV
              </Dropdown.Item>
            )}
            {roleSettings.is_xlsx_download && (
              <Dropdown.Item onClick={() => downloadFile('xlsx')}>
                <i className="fas fa-file-excel"></i> XLSX
              </Dropdown.Item>
            )}
            {roleSettings.is_pdf_download && (
              <Dropdown.Item onClick={() => downloadFile('pdf')}>
                <i className="fas fa-file-pdf"></i> PDF
              </Dropdown.Item>
            )}
          </DropdownButton>
        )}
        {(roleSettings?.is_csv_upload || roleSettings?.is_excel_upload) && (
          <Button variant="secondary" onClick={() => setShowUploadModal(true)} className="me-2">
            <i className="fas fa-upload"></i> Upload
          </Button>
        )}
        <FilterComponent headers={headers.map(header => header.key)} applyFilters={applyFilters} headerTypes={headerTypes} />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '500px' }}>
              <Table bordered className="table table-bordered table-hover table-striped ">
                  <thead>
                    <tr>
                      {headers.map(header => (
                        <th key={header.key} onClick={() => handleSort(header.key)}>
                          {header.label} {renderSortIcon(header.key)}
                        </th>
                      ))}
                      {roleSettings?.is_audit && (
                        <>
                          <th onClick={() => handleSort('created_by_user')}>Created By {renderSortIcon('created_by_user')}</th>
                          <th onClick={() => handleSort('created_at')} style={{ width: '174px' }}>
                            Created At {renderSortIcon('created_at')}
                          </th>
                          <th onClick={() => handleSort('updated_by_user')}>Updated By {renderSortIcon('updated_by_user')}</th>
                          <th onClick={() => handleSort('updated_at')} style={{ width: '174px' }}>
                            Updated At {renderSortIcon('updated_at')}
                          </th>
                        </>
                      )}
                      <th style={{ width: '214px' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                      {submissions.map(submission => (
                        <tr key={submission.id}>
                          {headers.map(header => {
                            let value = submission.submission_data.data[header.key];

                            // Check if value is undefined, null, or an object that resolves to "[object Object]"
                            if (value === undefined || value === null || value.toString() === '[object Object]') {
                              value = ''; // Set to empty string if undefined, null, or [object Object]
                            }

                            // Check if value is an array
                            if (Array.isArray(value)) {
                              // Check if the array contains objects with a 'name' property
                              if (value.length > 0 && typeof value[0] === 'object' && value[0] !== null) {
                                // Extract the 'name' property from each object in the array
                                value = value.map(item => item.name || '').join(', ');
                              } else {
                                // For a simple array (e.g., array of strings or numbers), join values directly
                                value = value.join(', ');
                              }
                            }

                            const isInteger = Number.isInteger(value);

                            return (
                              <td
                                key={header.key}
                                className="formtrwidth-min"
                                style={{ textAlign: isInteger ? 'right' : 'left' }}
                              >
                                {header.key === 'photo' && Array.isArray(submission.submission_data.data[header.key]) && submission.submission_data.data[header.key].length > 0 ? (
                                  <img
                                    className="img-circle elevation-3"
                                    src={submission.submission_data.data[header.key][0].url}
                                    alt={submission.submission_data.data[header.key][0].originalName}
                                    style={{ width: '35px', height: '35px' }}
                                  />
                                ) : (
                                  value + "" // Coerce value to string to handle all types
                                )}
                              </td>
                            );
                          })}
                          {roleSettings?.is_audit && (
                            <>
                              <td>{submission.created_by_user}</td>
                              <td>{new Date(submission.created_at).toLocaleString()}</td>
                              <td>{submission.updated_by_user}</td>
                              <td>{new Date(submission.updated_at).toLocaleString()}</td>
                            </>
                          )}
                          <td>
                            <Button variant="primary" onClick={() => handleEditSubmission(submission)} className="me-1">
                              Edit
                            </Button>
                            <Button variant="primary" onClick={() => handleDeleteSubmission(submission.id)} className="me-1">
                             Delete
                            </Button>
                            <Button variant="primary" onClick={() => handleCloneSubmission(submission)} className="me-1">
                             Clone
                            </Button>
                            <Button variant="primary" onClick={() => handleViewDetails(submission.id)}>
                             info
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>                 
                </Table>
              </div>
            </div>
            <div className="card-footer">
            
                
                {/* Use PaginationComponent */}
                <PaginationComponent 
                  page={page} 
                  totalPages={totalPages} 
                  handlePageChange={handlePageChange} 
                  pageSize={pageSize} 
                  handlePageSizeChange={handlePageSizeChange} 
                />
              
            </div>
          </div>
        </div>
      </div>

      {/* Use your existing CreateComponent, EditComponent, and FileUpload components */}
      <CreateComponent
        formSchema={formSchema}
        showModal={showModal}
        setShowModal={setShowModal}
        handleSubmit={handleSubmit}
        formName={formName}
      />
      <EditComponent
        formSchema={formSchema}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        handleEditSubmit={handleEditSubmit}
        editingSubmission={editingSubmission}
      />
      <FileUpload
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        fetchSubmissions={fetchSubmissions}
        formId={formId}
        API_URL={API_URL}
      />

    
    </div>
  );
};

export default FormSubmissions;
