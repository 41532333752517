import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from '../axiosConfig';
import { toast } from 'react-toastify';
import './Login.css';
import Toaster from '../ui-components/Toaster';
import Carousel from './Carousel';
import Loader from './BLoader';
const API_URL = process.env.REACT_APP_API_URL;

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [appName, setAppName] = useState('');
  const [appLogo, setAppLogo] = useState('');
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation(); // Hook to access the current URL

  useEffect(() => {
    // Clear all local storage data when the page loads
    localStorage.clear();

    const fetchAppSettings = async () => {
      try {
        const response = await axios.get(`${API_URL}/applications/getapp`, {
          params: { isDefault: 'true' },
        });

        const appData = response.data.applications[0];
        const appLogoUrl = `${API_URL.replace('/api/', '/')}${appData.logo}`;
        setAppName(appData.name);
        setAppLogo(appLogoUrl);
        setTheme(appData.themeId);

        document.title = appData.name;

        // Save application settings in local storage
        localStorage.setItem('appName', appData.name);
        localStorage.setItem('appLogo', appLogoUrl);
        localStorage.setItem('theme', appData.themeId);

        // Dynamically update the theme CSS
        const themeLink = document.getElementById('theme-css');
        if (themeLink) {
          themeLink.href = `/themes/theme${appData.themeId}.css`;
        } else {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `/themes/theme${appData.themeId}.css`;
          link.id = 'theme-css';
          document.head.appendChild(link);
        }

        // Dynamically update the favicon
        const faviconLink = document.getElementById('favicon');
        if (faviconLink) {
          faviconLink.href = appLogoUrl;
        } else {
          const link = document.createElement('link');
          link.rel = 'icon';
          link.href = appLogoUrl;
          link.id = 'favicon';
          document.head.appendChild(link);
        }

      } catch (error) {
        console.error('Failed to fetch application settings', error);
        toast.error('Failed to load application settings');
      }
    };

    fetchAppSettings();

    // Extract username and password from the URL
    const params = new URLSearchParams(location.search);
    const queryUsername = params.get('username');
    const queryPassword = params.get('password');

    if (queryUsername) setUsername(queryUsername);
    if (queryPassword) setPassword(queryPassword);

  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}/auth/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', response.data.user.id);
      localStorage.setItem('role', response.data.user.role_id);
      localStorage.setItem('company', response.data.user.company_id);
      setIsLoading(false);

      history.push(response.data.page);

    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div className="auth-page">
      {isLoading && <Loader />}
      <div className="login-wrapper">
        <div className="login-box">
          <div className="login-logo">
            <div className="centered-logo-container">
              <div className="logo"></div>
            </div>
            <b>{appName}</b>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember" style={{ marginLeft: 5 }}>
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                  </div>
                </div>
              </form>
              <p className="mb-0">
                <Link to="/register" className="text-center">Register a new membership</Link>
              </p>
              <p className="mb-1">
                <Link to="/forgot-password">I forgot my password</Link>
              </p>
              <p className="mb-0">
                <a href="https://www.doc.appgenc.com" className="text-center" target="_blank" rel="noopener noreferrer">
                  <span className="fas fa-info" style={{ fontSize: '22px', color: 'red' }}></span> &nbsp;About Me
                </a>
              </p>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    </div>
  );
}

export default Login;
