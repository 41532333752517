import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = process.env.REACT_APP_API_URL; // Assuming you're using .env for API base URL

const DashboardTile = () => {
  const [formCounts, setFormCounts] = useState([]);

  useEffect(() => {
    // Fetch menu and form data from the backend
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('user');
        const organizationId = localStorage.getItem('company'); // Assuming organization ID is stored in localStorage
        const token = localStorage.getItem('token'); // Fetch token from localStorage
  
        const response = await axios.get(`${API_URL}/tiles/${organizationId}/menus-with-form-counts`, {
          headers: { Authorization: `Bearer ${token}` }, // Pass the token in the request headers
          params: { userId } // Include the userId as a query parameter
        });
        setFormCounts(response.data.formCounts);
      } catch (error) {
        toast.error('Failed to fetch menu data');
        console.error('Error fetching menu data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="row">
      {formCounts.map((form) => (
        <div className="col-lg-3 col-6" key={form.form_id}>
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{form.record_count}</h3>
              <p>{form.menu_name}</p>
            </div>
            <div className="icon">
              <i className={`ion ${form.icon}`}></i> {/* Using ion icons from AdminLTE */}
            </div>
            <Link to={form.url} className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right"></i>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardTile;
