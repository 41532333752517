import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../../axiosConfig';

const DeleteComponent = ({ formId, submissionId, fetchSubmissions, roleSettings }) => {
  const handleDeleteSubmission = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/forms/${formId}/submissions/${submissionId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Submission deleted successfully');
      fetchSubmissions();
    } catch (error) {
      toast.error('Failed to delete submission');
    }
  };

  return (
    roleSettings?.is_delete && (
      <Button variant="primary" onClick={handleDeleteSubmission} className="me-1">Delete       
      </Button>
    )
  );
};

export default DeleteComponent;
