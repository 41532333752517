import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useHistory } from 'react-router-dom';
import Layout from './Layout';
import { Button, Table, Alert } from 'react-bootstrap';
import FilterComponent from './FilterComponent';
import FileUpload from './formutil/FileUpload';
import FileDownload from './formutil/FileDownload';
import CreateComponent from './formutil/CreateComponent';
import EditComponent from './formutil/EditComponent';
import CloneComponent from './formutil/CloneComponent';
import DeleteComponent from './formutil/DeleteComponent';
import PaginationComponent from './formutil/PaginationComponent';
import Loader from './BLoader'; // Import the Loader component

const API_URL = process.env.REACT_APP_API_URL;

function FormViewer() {
  const { formId } = useParams();
  const history = useHistory();
  const [submissions, setSubmissions] = useState([]);
  const [formSchema, setFormSchema] = useState(null);
  const [formName, setFormName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [editingSubmission, setEditingSubmission] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('asc');
  const [roleSettings, setRoleSettings] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [headerTypes, setHeaderTypes] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [isLoading, setIsLoading] = useState(false); 
  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const role = (localStorage.getItem('role'));
    if (role) {
      fetchRoleSettings(role);
    }
  }, []);

  useEffect(() => {
   // if (roleSettings !== null) {
      fetchFormDetails();
      fetchSubmissions();
   // }
  }, [formId, page, pageSize, sortBy, sortOrder, roleSettings]);

  const fetchRoleSettings = async () => {
    const role = localStorage.getItem('role'); // Retrieve role from local storage
    const orgId = localStorage.getItem('company'); // Retrieve organization ID
  
    try {
      // Validate role and orgId
      if (!formId || !role || !orgId) {
        toast.error('Form ID, Role, and Organization ID are required');
        return;
      }
  
      const response = await axios.get(
        `${API_URL}/role-settings/${formId}`, 
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}` 
          },
          params: { 
            role, // Pass role as a query parameter
            orgId // Pass organization ID as a query parameter
          }
        }
      );
  
      const settings = response.data;
  
      if (settings && settings.length > 0) {
        setRoleSettings(settings[0]); // Populate role settings if found
        setIsAuthorized(true);
      } else {
        setRoleSettings([]); // Clear settings if none are found
        setIsAuthorized(false); // Mark as unauthorized
      }
    } catch (error) {
      console.error('Failed to fetch role settings:', error);
      toast.error('Failed to fetch role settings');
      setIsAuthorized(false);
    }
  };
  
  

  const fetchFormDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const form = response.data;
      setFormSchema(form.form_schema);
      setFormName(form.name);
    } catch (error) {
      toast.error('Failed to fetch  details');
    }
  };

  const fetchSubmissions = async (filters = []) => {
    try {
      setIsLoading(true); 
      const response = await axios.get(`${API_URL}/forms/${formId}/submissions`, {
        params: { page, limit: pageSize, filters: JSON.stringify(filters), sortBy, sortOrder },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setIsLoading(false); 
      setSubmissions(response.data.submissions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to fetch Data');
    }
  };

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? ' ▲' : ' ▼';
    }
    return '';
  };

  const handleSubmit = async (submission) => {
    try {
      setIsLoading(true); 
      await axios.post(`${API_URL}/forms/${formId}/submissions`, { submission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setIsLoading(false); 
      toast.success('Data saved successfully');
      fetchSubmissions();
      setShowModal(false);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to save Data');
    }
  };

  const handleEditSubmit = async (submission) => {
    try {
      setIsLoading(true); 
      await axios.put(`${API_URL}/forms/${formId}/submissions/${editingSubmission.id}`, { submission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setIsLoading(false); 
      toast.success('Data updated successfully');
      fetchSubmissions();
      setShowEditModal(false);
    } catch (error) {
      setIsLoading(false); 
      toast.error('Failed to update data');
    }
  };

  const handleEditSubmission = (submission) => {
    setEditingSubmission(submission);
    setShowEditModal(true);
  };

  const handleViewDetails = (submissionId) => {
    history.push(`/forms/${formId}/submissions/${submissionId}`);
  };

// Helper function to recursively find the component by its key
const findComponentByKey = (components, key) => {
  for (const component of components) {
    if (component.key === key) {
      return component; // Return the component if the key matches
    }

    // Recursively search in nested components (if present)
    if (component.components && component.components.length > 0) {
      const nestedComponent = findComponentByKey(component.components, key);
      if (nestedComponent) {
        return nestedComponent; // Return the nested component if found
      }
    }

    // Recursively search in table rows (for 'table' type components)
    if (component.rows && component.rows.length > 0) {
      for (const row of component.rows) {
        for (const cell of row) {
          if (cell.components && cell.components.length > 0) {
            const nestedComponent = findComponentByKey(cell.components, key);
            if (nestedComponent) {
              return nestedComponent; // Return the nested component in the table if found
            }
          }
        }
      }
    }
  }

  return undefined; // Return undefined if no component is found with the given key
};


    const extractHeadersFromSchema = (schema) => {
      const headers = [];
  
      const extractFromComponents = (components) => {
          components.forEach(component => {
              if (['panel', 'column', 'fieldset', 'tabs'].includes(component.type)) {
                  // Handle these container types by recursively processing their components
                  if (component.components && component.components.length > 0) {
                      extractFromComponents(component.components);
                  }
              } else if (component.type === 'table') {
                  // Handle the table component by processing each row
                  component.rows.forEach(row => {
                      row.forEach(cell => {
                          if (Array.isArray(cell.components)) {
                              extractFromComponents(cell.components);
                          }
                      });
                  });
              } else {
                  if (component.tableView) {
                      headers.push({ key: component.key, label: component.label });
                  }
                  // Check if the component has nested components
                  if (component.components && component.components.length > 0) {
                      extractFromComponents(component.components);
                  }
              }
          });
      };
  
      // Start the extraction with the root level components
      extractFromComponents(schema.components);
  
      return headers;
  };
  



  const headers = formSchema ? extractHeadersFromSchema(formSchema) : [];

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPage(1);
  };

  const applyFilters = (filters) => {
    fetchSubmissions(filters);
    setCurrentFilters(filters)
  };

  if (!isAuthorized) {
    return (
      <Layout>
         {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Not Authorized</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <Alert variant="danger">
                You are not authorized to view this page.
              </Alert>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-end mb-3">
            {roleSettings?.is_create &&(
                <Button variant="primary" onClick={() => setShowModal(true)} className="me-2">
                  <i className="fas fa-plus"></i> Create
                </Button>          
              )}
              {(roleSettings?.is_csv_download || roleSettings?.is_xlsx_download || roleSettings?.is_pdf_download) && (
                <FileDownload formId={formId} API_URL={API_URL} roleSettings={roleSettings} filters={currentFilters}  />
              )}
              {(roleSettings?.is_csv_upload || roleSettings?.is_excel_upload) && (
                <Button variant="secondary" onClick={() => setShowUploadModal(true)} className="me-2">
                  <i className="fas fa-upload"></i> Upload
                </Button>
              )}
              <FilterComponent headers={headers.map(header => header.key)} applyFilters={applyFilters} headerTypes={headerTypes} />
            </div>
            <div className="row">
            <div className="col-12">
            <div className="card">
            <div class="card-header">
<h3 class="card-title">{formName}</h3>
</div>
                  <div className="card-body ">
                  <div className="table-responsives">
                  <Table bordered className="table table-bordered table-hover table-striped ">
  <thead>
    <tr>
      {headers.map(header => (
        <th className="formtrwidth-min" key={header.key} onClick={() => handleSort(header.key)}>
          {header.label} {renderSortIcon(header.key)}
        </th>
      ))}
      {roleSettings?.is_audit && (
        <>
          <th onClick={() => handleSort('created_by_user')} className="formtrwidth-min">
            Created By {renderSortIcon('created_by_user')}
          </th>
          <th onClick={() => handleSort('created_at')} className="formtrwidth-min" style={{ width: "174px" }}>
            Created At {renderSortIcon('created_at')}
          </th>
          <th onClick={() => handleSort('updated_by_user')} className="formtrwidth-min">
            Updated By {renderSortIcon('updated_by_user')}
          </th>
          <th onClick={() => handleSort('updated_at')} className="formtrwidth-min" style={{ width: "174px" }}>
            Updated At {renderSortIcon('updated_at')}
          </th>
        </>
      )}
      <th style={{ width: "180px" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
  {submissions.map(submission => (
    <tr key={submission.id}>
      {headers.map(header => {
        let value = submission.submission_data.data[header.key];

        // Use the helper function to find the component by key (recursive search)
        const component = findComponentByKey(formSchema.components, header.key);
        const componentType = component ? component.type : null;

       
        
        //Json lookup Object
        //console.log("=======================componentType=========>"+componentType+"------"+value+"------"+JSON.stringify(value));
        
        // Check if value is undefined, null, or an object that resolves to "[object Object]"
        // if (value === undefined || value === null || (value.toString() === '[object Object]'&&componentType != 'file')) {
        //  value = ''; // Set to empty string if undefined, null, or [object Object]
        //}

        // Perform validation based on component type
        if (componentType === 'datetime') {
          // Check if the value is a valid date and format it as MM-DD-YYYY
          const dateValue = new Date(value);
          if (!isNaN(dateValue.getTime())) {
            // Format date to MM-DD-YYYY
            value = dateValue.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
          } else {
            value = ''; // If it's not a valid date, set it to an empty string
          }
        } else if (componentType === 'select') {
          // Handle validation for select fields (if needed)
          if (value && value.id && value.name && value.form_id) {
            value = (
              <a className="style-link"
                href={`/forms/${value.form_id}/submissions/${value.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value.name}
              </a>
            );
          } else {
            value = value; // If object doesn't have required fields, set to empty string
          }
        } else if (componentType === 'file') {
          // Handle file-related logic (e.g., rendering an image)
          if (header.key === 'photo' && Array.isArray(value) && value.length > 0) {
            // Extract the first image's URL and original name to display the image
            value = (
              <img
                className="img-circle elevation-3"
                src={value[0].url} // Use the image URL
                alt={value[0].originalName || 'Photo'} // Use the image original name as alt text
                style={{ width: '35px', height: 'auto' }} // Style the image
              />
            );
          } else {
            value = ''; // If no file is present, set to empty string
          }
        }
        if (header.key === 'name' && typeof value === 'string') {
          value = (
            <a
              className="style-link"
              href={`/forms/${formId}/submissions/${submission.id}`}
              rel="noopener noreferrer"
            >
              {value}
            </a>
          );
        } 

        const isInteger = Number.isInteger(value);

        return (
          <td
            key={header.key}
            className="formtrwidth-min"
            style={{ textAlign: isInteger ? 'right' : 'left' }}
          >
            {typeof value === 'string' || typeof value === 'number' ? value : value} {/* Render string, number, or JSX (for image) */}
          </td>
        );
      })}

      {roleSettings?.is_audit && (
        <>
          <td className="formtrwidth-min">{submission.created_by_user}</td>
          <td className="formtrwidth-min">{new Date(submission.created_at).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</td>
          <td className="formtrwidth-min"> {submission.updated_by_user}</td>
          <td className="formtrwidth-min">{new Date(submission.updated_at).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</td>
        </>
      )}

      <td >
      {roleSettings?.is_edit &&(<Button variant="primary" onClick={() => handleEditSubmission(submission)} className="me-1"> Edit</Button>)}
        <DeleteComponent formId={formId} submissionId={submission.id} fetchSubmissions={fetchSubmissions} roleSettings={roleSettings} />
        <CloneComponent formId={formId} submission={submission} fetchSubmissions={fetchSubmissions} roleSettings={roleSettings} />
        {/*roleSettings?.is_view && (
          <Button variant="primary" onClick={() => handleViewDetails(submission.id)}>Info          
          </Button>
        )*/}
      </td>
    </tr>
  ))}
</tbody>




</Table>



              </div>
              {<div class="card-footer clearfix">

<PaginationComponent page={page} totalPages={totalPages} handlePageChange={handlePageChange} pageSize={pageSize} handlePageSizeChange={handlePageSizeChange} />
         
</div>}
              </div>
              </div>
              </div>
            </div>
           </div>
        </div>
      </div>
      <CreateComponent formSchema={formSchema} showModal={showModal} setShowModal={setShowModal} handleSubmit={handleSubmit} formName={formName} />
      <EditComponent formSchema={formSchema} showEditModal={showEditModal} setShowEditModal={setShowEditModal} handleEditSubmit={handleEditSubmit} editingSubmission={editingSubmission} />
      <FileUpload showUploadModal={showUploadModal} setShowUploadModal={setShowUploadModal} fetchSubmissions={fetchSubmissions} formId={formId} API_URL={API_URL} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default FormViewer;
