import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../../axiosConfig';
import { saveAs } from 'file-saver';

const FileDownload = ({ formId, API_URL, roleSettings, filters }) => {
  const downloadFile = (format) => {
    axios.get(`${API_URL}/forms/${formId}/download`, {
      params: { format, filters: JSON.stringify(filters) },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob',
    }).then((response) => {
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : `submissions_${formId}.${format}`;
      saveAs(new Blob([response.data]), filename);
    }).catch(() => {
      toast.error(`Failed to download ${format} file`);
    });
  };

  return (
    <DropdownButton title="Download" variant="secondary" className="me-2">
      {roleSettings.is_csv_download && (
        <Dropdown.Item onClick={() => downloadFile('csv')}>
          <i className="fas fa-file-csv"></i> CSV
        </Dropdown.Item>
      )}
      {roleSettings.is_xlsx_download && (
        <Dropdown.Item onClick={() => downloadFile('xlsx')}>
          <i className="fas fa-file-excel"></i> XLSX
        </Dropdown.Item>
      )}
      {roleSettings.is_pdf_download && (
        <Dropdown.Item onClick={() => downloadFile('pdf')}>
          <i className="fas fa-file-pdf"></i> PDF
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
};

export default FileDownload;
