import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { Modal, Button } from 'react-bootstrap';

const EntityNode = ({ data, onDelete, onClone }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  if (!data) return null;

  const entityNameStyle = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '1px solid #222',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const attributeStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#e6e6e6',
    padding: '5px',
    textAlign: 'left',
    borderBottom: '1px solid #ccc',
    position: 'relative',
  });

  const handleDeleteClick = () => {
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmDelete = () => {
    if (onDelete) {
      onDelete(data.id); // Call the delete function with the node's ID
    }
    setShowConfirmModal(false); // Close modal after confirming
  };

  const cancelDelete = () => {
    setShowConfirmModal(false); // Close modal without deleting
  };

  const handleCloneClick = () => {
    if (onClone) {
      onClone(data); // Call the clone function with the current node data
    }
  };

  return (
    <div style={{ border: '1px solid #222', borderRadius: '3px', backgroundColor: '#fff' }}>
      <div style={entityNameStyle}>
        <span>{data.label || 'Entity'}</span>
        <div>
          {/* Clone Button */}
          <button
            style={{
              backgroundColor: 'blue',
              color: 'white',
              border: 'none',
              padding: '3px 8px',
              borderRadius: '3px',
              cursor: 'pointer',
              fontSize: '12px',
              marginRight: '5px',
            }}
            onClick={() => onClone(data)} // Ensure `data` is passed correctly
          >
            <i className="fas fa-clone"></i>
          </button>
          {/* Delete Button */}
          <button
            style={{
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              padding: '3px 8px',
              borderRadius: '3px',
              cursor: 'pointer',
              fontSize: '12px',
            }}
            onClick={handleDeleteClick}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
        {(data.attributes || []).map((attr, index) => (
          <li key={index} style={attributeStyle(index)}>
            {index + 1}. {attr.name} : {attr.datatype}
            <Handle
              type="source"
              position="right"
              id={`${data.label}-${attr.name}`}
              style={{ top: '50%', transform: 'translateY(-50%)', right: '-5px' }}
            />
            <Handle
              type="target"
              position="left"
              id={`${data.label}-${attr.name}`}
              style={{ top: '50%', transform: 'translateY(-50%)', left: '-5px' }}
            />
          </li>
        ))}
      </ul>
      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this table?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EntityNode;
