import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css'; // Use the same CSS file for consistency
import Carousel from './Carousel'; // Import the Carousel component
import Loader from './BLoader'; // Import the Loader component
const API_URL = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [appName, setAppName] = useState('');
  const [appLogo, setAppLogo] = useState(''); // Default logo
  const [theme, setTheme] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  useEffect(() => {
    const fetchAppSettings = async () => {
      try {
        const response = await axios.get(`${API_URL}/applications/getapp`, {
          params: { isDefault: 'true' },
        });

        const appData = response.data.applications[0];
        const appLogoUrl = `${API_URL.replace('/api', '')}${appData.logo}`;
        setAppName(appData.name.replace(/_/g, ' ')); // Remove underscores and replace with spaces
        setAppLogo(appLogoUrl);
        setTheme(appData.themeId);

        // Save these to local storage
        localStorage.setItem('appName', appData.name.replace(/_/g, ' '));
        localStorage.setItem('appLogo', appLogoUrl);
        localStorage.setItem('theme', appData.themeId);

        // Update the document title
        document.title = appData.name;
        // Dynamically set the theme if needed
        const themeLink = document.getElementById('theme-css');
        if (themeLink) {
          themeLink.href = `/themes/theme${appData.themeId}.css`;
        } else {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `/themes/theme${appData.themeId}.css`;
          link.id = 'theme-css';
          document.head.appendChild(link);
        }

        // Set the favicon
        const faviconLink = document.getElementById('favicon');
        if (faviconLink) {
          faviconLink.href = appLogoUrl;
        } else {
          const link = document.createElement('link');
          link.rel = 'icon';
          link.href = appLogoUrl;
          link.id = 'favicon';
          document.head.appendChild(link);
        }

      } catch (error) {
        console.error('Failed to fetch application settings', error);
        toast.error('Failed to load application settings');
      }
    };

    fetchAppSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true); // Show the loader while updating     
      await axios.post(`${API_URL}/auth/forgot-password`, { email });
      setIsLoading(false); // Show the loader while updating
     
      toast.success('Password reset link sent');
    } catch (error) {
      setIsLoading(false); // Show the loader while updating
     
      toast.error('Failed to send password reset link');
    }
  };

  return (
    <div className="auth-page">
        {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
     {/* <Carousel />Display carousel on the left */}
    <div className="login-wrapper">
      <div className="login-box">
        <div className="login-logo">
        <div class="centered-logo-container">
  <div class="logo"></div>
</div>    
             <b>{appName}</b>
        </div>
        <div className="card">
          <div className="card-body auth-card-body">
            <p className="auth-box-msg">You forgot your password? <br></br>
            Here you can easily retrieve a new password.</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-block">Request new password</button>
                </div>
              </div>
            </form>
            <p className="mt-3 mb-1">
              <Link to="/login">Login</Link>
            </p>
            <p className="mb-0">
              <Link to="/register" className="text-center">Register a new membership</Link>
            </p>
          </div>
        </div>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
    </div>
  );
}

export default ForgotPassword;
