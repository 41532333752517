import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import AttributeDetailsForm from './AttributeDetailsForm';
import AttributesTable from './AttributesTable';

const EntityModal = ({
  show,
  onHide,
  entityName,
  setEntityName,
  attributes,
  setAttributes,
  handleAddEntity,
  handleSaveEntity,
  entities,
}) => {
  const [attributeName, setAttributeName] = useState('');
  const [attributeDatatype, setAttributeDatatype] = useState('String');
  const [attributeVisibility, setAttributeVisibility] = useState(true);
  const [attributeNullability, setAttributeNullability] = useState(true);
  const [attributeOptions, setAttributeOptions] = useState('');
  const [minLength, setMinLength] = useState('');
  const [maxLength, setMaxLength] = useState('');
  const [caps, setCaps] = useState(false);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [formulaValue, setFormulaValue] = React.useState('');
  const [isEditing, setIsEditing] = useState(false);  // New state to track edit mode
  const [editIndex, setEditIndex] = useState(null);  // Index of the attribute being edited

  // Add attribute to the list when "Add" or "Update" button is clicked
  const onAddOrUpdateAttribute = () => {
    if (attributeName.trim()) {
      const newAttribute = {
        name: attributeName,
        datatype: attributeDatatype,
        visibility: attributeVisibility ? 'True' : 'False',
        nullability: attributeNullability ? 'True' : 'False',
        options: attributeOptions || null,
        formula: attributeDatatype === 'Formula' ? formulaValue : null, // Separate field for formula      
        minLength: minLength || null,
        maxLength: maxLength || null,
        caps: caps ? 'True' : 'False',
        tags: selectedTag,
        entity: selectedEntity ? selectedEntity.label : '',  // Use the selected entity's label
        entityAttribute: selectedAttribute,  // Use the selected attribute
      };

      if (isEditing) {
        // If editing, update the existing attribute
        const updatedAttributes = [...attributes];
        updatedAttributes[editIndex] = newAttribute;
        setAttributes(updatedAttributes);
        setIsEditing(false);
        setEditIndex(null);
      } else {
        // If adding, add the new attribute
        setAttributes([...attributes, newAttribute]);
      }

      // Reset fields after adding/updating
      resetAttributeForm();
    } else {
      alert("Please fill in the required fields and select an entity/attribute.");
    }
  };

  // Function to reset the form after add/update
  const resetAttributeForm = () => {
    setAttributeName('');
    setAttributeDatatype('String');
    setAttributeVisibility(true);
    setAttributeNullability(true);
    setAttributeOptions('');
    setMinLength('');
    setMaxLength('');
    setCaps(false);
    setSelectedTag('');
    setSelectedEntity(null);  // Reset entity selection
    setSelectedAttribute('');  // Reset attribute selection
  };

  // Handle editing an attribute
  const handleEditAttribute = (index) => {
    const attributeToEdit = attributes[index];
    setAttributeName(attributeToEdit.name);
    setAttributeDatatype(attributeToEdit.datatype);
    setAttributeVisibility(attributeToEdit.visibility === 'True');
    setAttributeNullability(attributeToEdit.nullability === 'True');
    setAttributeOptions(attributeToEdit.options || '');
    setMinLength(attributeToEdit.minLength || '');
    setMaxLength(attributeToEdit.maxLength || '');
    setCaps(attributeToEdit.caps === 'True');
    setSelectedTag(attributeToEdit.tags || '');
    setSelectedEntity(entities.find((entity) => entity.label === attributeToEdit.entity) || null);
    setSelectedAttribute(attributeToEdit.entityAttribute || '');

    setIsEditing(true);  // Set editing mode to true
    setEditIndex(index);  // Store the index of the attribute being edited
  };

  const deleteAttribute = (index) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="entity-modal" size="xl">
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>{handleAddEntity ? 'Add Entity' : 'Edit Entity'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Entity Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entity Name"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
            />
          </Form.Group>
          <hr />
          <AttributeDetailsForm
            entities={entities}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={setSelectedAttribute}
            attributeName={attributeName}
            setAttributeName={setAttributeName}
            attributeDatatype={attributeDatatype}
            setAttributeDatatype={setAttributeDatatype}
            attributeVisibility={attributeVisibility}
            setAttributeVisibility={setAttributeVisibility}
            attributeNullability={attributeNullability}
            setAttributeNullability={setAttributeNullability}
            minLength={minLength}
            setMinLength={setMinLength}
            maxLength={maxLength}
            setMaxLength={setMaxLength}
            caps={caps}
            setCaps={setCaps}
            tags={tags}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            newTag={newTag}
            setNewTag={setNewTag}
            handleAddTag={handleAddTag}
            onAddAttribute={onAddOrUpdateAttribute}  // Handle add or update attribute
            attributeOptions={attributeOptions}
            setAttributeOptions={setAttributeOptions}
            isEditing={isEditing}  // Pass editing state
            setIsEditing={setIsEditing}  // Set editing state
            resetAttributeForm={resetAttributeForm}  // Pass reset form function
            formulaValue={formulaValue}
            setFormulaValue={setFormulaValue}
            currentAttributes={attributes}
          />
          <hr />
          <AttributesTable
            attributes={attributes}
            setAttributes={setAttributes}
            deleteAttribute={deleteAttribute}
            selectedEntity={selectedEntity}
            selectedAttribute={selectedAttribute}
            onEditAttribute={handleEditAttribute}  // Handle attribute edit
            entities={entities}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddEntity || handleSaveEntity}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EntityModal;
