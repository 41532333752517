import React, { useEffect } from 'react';
import axios from '../axiosConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function VerifyEmail() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token'); // Extract token from URL

  useEffect(() => {
    const VerifyEmail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-email`, { params: { token } });
        toast.success(response.data.message);
        history.push('/login'); // Redirect to login page after verification
      } catch (error) {
        toast.error(error.response.data.error);
      }
    };

    VerifyEmail();
  }, [token, history]);

  return (
    <div className="verification-wrapper">
      <h2>Verifying your email...</h2>
    </div>
  );
}

export default VerifyEmail;
