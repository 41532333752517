import React, { useRef, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const filterSchema = (schema) => {
  const filteredComponents = schema.components.filter((component) => !component.mask);
  return { ...schema, components: filteredComponents };
};

const EditViewComponent = ({ formSchema, isEditable, handleEditSubmit, editingSubmission }) => {
  const editFormRef = useRef(null);
  const [editFormInstance, setEditFormInstance] = useState(null);

  useEffect(() => {
    if (formSchema && editFormRef.current) {
      const filteredSchema = filterSchema(formSchema);

      window.Formio.createForm(editFormRef.current, {
        ...filteredSchema,
        components: filteredSchema.components.map((component) => ({
          ...component,
          disabled: !isEditable, // Use `isEditable` prop from parent to control read-only or editable state
        })),
      }).then((formioForm) => {
        formioForm.submission = editingSubmission.submission_data;
        if (isEditable) {
          formioForm.on('submit', handleEditSubmit); // Handle submit if editable
        }
        setEditFormInstance(formioForm);
      });
    }
  }, [formSchema, editingSubmission, isEditable]);

  return <div ref={editFormRef}></div>;
};

export default EditViewComponent;
