import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, Col, Row } from 'react-bootstrap';
import Layout from './Layout';

const API_URL = process.env.REACT_APP_API_URL;

function PasswordPolicy() {
  const [policy, setPolicy] = useState({
    minLength: '',
    maxLength: '',
    requireUppercase: false,
    requireLowercase: false,
    requireDigit: false,
    requireSpecialChar: false,
    passwordHistory: 5,
    passwordExpirationDays: 60,
    requireMFA: false,
    lockoutThreshold: 5,
    passwordBlacklist: []
  });

  useEffect(() => {
    fetchPasswordPolicy();
  }, []);

  const fetchPasswordPolicy = async () => {
    try {
      const response = await axios.get(`${API_URL}/password-policy`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setPolicy({
        minLength: response.data.min_length,
        maxLength: response.data.max_length,
        requireUppercase: response.data.require_uppercase,
        requireLowercase: response.data.require_lowercase,
        requireDigit: response.data.require_digit,
        requireSpecialChar: response.data.require_special_char,
        passwordHistory: response.data.password_history,
        passwordExpirationDays: response.data.password_expiration_days,
        requireMFA: response.data.require_mfa,
        lockoutThreshold: response.data.lockout_threshold,
        passwordBlacklist: response.data.password_blacklist});
    } catch (error) {
      toast.error('Failed to fetch password policy');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPolicy({
      ...policy,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSavePolicy = async () => {
    try {
      await axios.put(`${API_URL}/password-policy`, policy, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Password policy updated successfully');
    } catch (error) {
      toast.error('Failed to update password policy');
    }
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Password Policy</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <Form>
              <Row>
                <Col>
                  <Form.Group controlId="minLength">
                    <Form.Label>Minimum Length</Form.Label>
                    <Form.Control
                      type="number"
                      name="minLength"
                      value={policy.minLength}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="maxLength">
                    <Form.Label>Maximum Length</Form.Label>
                    <Form.Control
                      type="number"
                      name="maxLength"
                      value={policy.maxLength}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="requireUppercase">
                <Form.Check
                  type="checkbox"
                  label="Include at least one uppercase letter (A-Z)"
                  name="requireUppercase"
                  checked={policy.requireUppercase}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="requireLowercase">
                <Form.Check
                  type="checkbox"
                  label="Include at least one lowercase letter (a-z)"
                  name="requireLowercase"
                  checked={policy.requireLowercase}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="requireDigit">
                <Form.Check
                  type="checkbox"
                  label="Include at least one digit (0-9)"
                  name="requireDigit"
                  checked={policy.requireDigit}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="requireSpecialChar">
                <Form.Check
                  type="checkbox"
                  label="Include at least one special character (e.g., !, @, #, $, %, etc.)"
                  name="requireSpecialChar"
                  checked={policy.requireSpecialChar}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="passwordHistory">
                <Form.Label>Password History</Form.Label>
                <Form.Control
                  type="number"
                  name="passwordHistory"
                  value={policy.passwordHistory}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="passwordExpirationDays">
                <Form.Label>Password Expiration (days)</Form.Label>
                <Form.Control
                  type="number"
                  name="passwordExpirationDays"
                  value={policy.passwordExpirationDays}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="requireMFA">
                <Form.Check
                  type="checkbox"
                  label="Require Multi-Factor Authentication (MFA)"
                  name="requireMFA"
                  checked={policy.requireMFA}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="lockoutThreshold">
                <Form.Label>Account Lockout Threshold</Form.Label>
                <Form.Control
                  type="number"
                  name="lockoutThreshold"
                  value={policy.lockoutThreshold}
                  onChange={handleInputChange}
                />
              </Form.Group>
           
              <Button variant="primary" onClick={handleSavePolicy}>
                Save Policy
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default PasswordPolicy;
