import React from 'react';
import { useHistory } from 'react-router-dom';
import Layout from './Layout';
import { Button } from 'react-bootstrap';

function Unauthorized() {
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/'); // Redirect to the home page or login page
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Unauthorized Access</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body text-center">
                    <h2 className="text-danger">403 - You do not have permission to view this page.</h2>
                    <p className="lead">It seems like you are trying to access a page that you do not have access to.</p>
                    <Button variant="primary" onClick={handleGoBack}>
                      Go Back to Home
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Unauthorized;
