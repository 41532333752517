import React, { useEffect, useState, useRef } from 'react';
import axios from '../axiosConfig';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function MenuStructure({ refreshKey }) {
  const [menuItems, setMenuItems] = useState([]);
  const userRole = localStorage.getItem('role');
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      const fetchMenuData = async () => {
        try {
         // const response = await axios.get(`${API_URL}/menus/user`, {
         //   params: { user: localStorage.getItem('user') },
          //  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          //});
          const response = await axios.get(`${API_URL}/menus/org`, {
            params: { company_id: localStorage.getItem('company') ,user: localStorage.getItem('user') },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          const allMenus = response.data;
          const structuredMenus = buildMenuTree(allMenus);

          setMenuItems(structuredMenus);
          isMounted.current = true;
          
        } catch (error) {
          console.error('Failed to fetch menu items', error);
        }
      };

      fetchMenuData();
    }
  }, [refreshKey,userRole]);

  const buildMenuTree = (menus) => {
    const menuMap = {};
    const roots = [];

    menus.forEach(menu => {
      menuMap[menu.id] = { ...menu, children: [] };
    });

    menus.forEach(menu => {
      if (menu.parent_id) {
        menuMap[menu.parent_id].children.push(menuMap[menu.id]);
      } else {
        roots.push(menuMap[menu.id]);
      }
    });

    return roots;
  };

  const renderMenuItems = (items) => {
    return items.map((item) => (
      <li className="nav-item" key={item.id}>
        <Link
          className="nav-link"
          to={item.url.startsWith('/') ? item.url : `/${item.url}`} // Ensure absolute path
        >
          <i className={`nav-icon fa ${item.icon}`}></i> {item.name}
        </Link>
        {item.children.length > 0 && (
          <ul>
            {renderMenuItems(item.children)}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {renderMenuItems(menuItems)}
      </ul>
    </nav>
  );
}

export default React.memo(MenuStructure);
