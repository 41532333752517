import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { useParams, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout';
import { Button, Modal, Alert } from 'react-bootstrap';
import FormTabs from './FormTabs'; // Ensure that this component is correctly imported
import EditViewComponent from './formutil/EditViewComponent';
const API_URL = process.env.REACT_APP_API_URL;

function SubmissionDetail() {
  const { formId, submissionId } = useParams();
  const history = useHistory();
  const [submission, setSubmission] = useState(null);
  const [formSchema, setFormSchema] = useState(null);
  const [formName, setFormName] = useState(null);
  const [parentName, setParentName] = useState(null);
  const [childForms, setChildForms] = useState([]);
  const [roleSettings, setRoleSettings] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [editingSubmission, setEditingSubmission] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role) {
      fetchRoleSettings(role);
    }
  }, []);

  useEffect(() => {
    if (roleSettings !== null) {
      fetchFormDetails();
    }
  }, [formId, roleSettings]);

  useEffect(() => {
    if (formSchema) {
      fetchSubmissionDetails();
    }
  }, [formSchema, submissionId]);

  const fetchRoleSettings = async () => {
    const role = localStorage.getItem('role'); // Retrieve role from local storage
    const orgId = localStorage.getItem('company'); // Retrieve organization ID
  
    try {
      // Validate role and orgId
      if (!formId || !role || !orgId) {
        toast.error('Form ID, Role, and Organization ID are required');
        return;
      }
  
      const response = await axios.get(
        `${API_URL}/role-settings/${formId}`, 
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}` 
          },
          params: { 
            role, // Pass role as a query parameter
            orgId // Pass organization ID as a query parameter
          }
        }
      );
  
      const settings = response.data;
  
      if (settings && settings.length > 0) {
        setRoleSettings(settings[0]);  // Populate role settings if found
        setIsAuthorized(true);
      } else {
        setRoleSettings([]); // Clear settings if none are found
        setIsAuthorized(false); // Mark as unauthorized
      }
    } catch (error) {
      console.error('Failed to fetch role settings:', error);
      toast.error('Failed to fetch role settings');
      setIsAuthorized(false);
    }
  };

  const fetchFormDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const form = response.data;
      setFormSchema(form.form_schema);
      setChildForms(form.children); // Set child forms
      setFormName(form.name);
    } catch (error) {
      toast.error('Failed to fetch form details');
    }
  };

  const fetchSubmissionDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/forms/${formId}/submissions/${submissionId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setParentName(response.data.submission_data.data.name)
      setSubmission(response.data);
      setEditingSubmission(response.data);
    } catch (error) {
      toast.error('Failed to fetch submission details');
    }
  };

  // Handle edit functionality in parent
  const handleEditClick = () => {
    setIsEditable(true); // Set the form to editable mode
  };

  // Handle save functionality in parent
  const handleSaveClick = async (submissionData) => {
    try {
      await axios.put(
        `${API_URL}/forms/${formId}/submissions/${submission.id}`,
        { submission: submissionData },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      toast.success('Submission updated successfully');
      fetchSubmissionDetails(); // Refresh the submission after saving
      setIsEditable(false); // Set form back to read-only after saving
    } catch (error) {
      toast.error('Failed to update submission');
    }
  };

  const handleDeleteSubmission = async () => {
    try {
      await axios.delete(`${API_URL}/forms/${formId}/submissions/${submissionId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Submission deleted successfully');
      setShowDeleteModal(false);
      history.push(`/forms/${formId}`);
    } catch (error) {
      toast.error('Failed to delete submission');
      setShowDeleteModal(false);
    }
  };

  const handleCloneSubmission = async () => {
    try {
      const clonedSubmission = { ...submission.submission_data };
      await axios.post(`${API_URL}/forms/${formId}/submissions`, { submission: clonedSubmission }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Submission cloned successfully');
      fetchSubmissionDetails();
      setShowCloneModal(false);
    } catch (error) {
      toast.error('Failed to clone submission');
      setShowCloneModal(false);
    }
  };

  if (!isAuthorized) {
    return (
      <Layout>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Not Authorized</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <Alert variant="danger">You are not authorized to view this page.</Alert>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (!submission) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{formName}</h1>
              </div>
              <div className="col-sm-6 text-right">
                <Button variant="secondary" className="me-2" onClick={() => history.goBack()}>
                  Back to Form
                </Button>
                {roleSettings?.is_edit && (
                  <>
                    {!isEditable ? (
                      <Button variant="primary" onClick={handleEditClick} className="me-2">
                         Edit
                      </Button>
                    ) : (
                      <Button variant="primary" onClick={() => handleSaveClick(editingSubmission.submission_data)} className="me-2">
                         Save
                      </Button>
                    )}
                  </>
                )}
                {roleSettings?.is_delete && (
                  <Button variant="primary" onClick={() => setShowDeleteModal(true)} className="me-2">
                    Delete
                  </Button>
                )}
                {roleSettings?.is_clone && (
                  <Button variant="primary" onClick={() => setShowCloneModal(true)} className="me-2">
                   Clone
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <EditViewComponent
              formSchema={formSchema}
              isEditable={isEditable}
              handleEditSubmit={handleSaveClick}
              editingSubmission={editingSubmission}
            />
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <FormTabs formId={formId} childForms={childForms} parentId={submissionId} parentForm={formName} parentName={parentName}/>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this submission?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSubmission}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Clone Confirmation Modal */}
      <Modal show={showCloneModal} onHide={() => setShowCloneModal(false)}>
        <Modal.Header closeButton >
          <Modal.Title>Confirm Clone</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to clone this submission?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCloneModal(false)}>
            Cancel
          </Button>
          <Button variant="info" onClick={handleCloneSubmission}>
            Clone
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </Layout>
  );
}

export default SubmissionDetail;
