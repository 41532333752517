import React, { useState } from 'react';
import axios from '../../axiosConfig';
import { toast } from 'react-toastify';
import { Button, Offcanvas, Form } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

const AIGenerateDrawer = ({ show, handleClose, setNodes, setEdges }) => {
  const [userStory, setUserStory] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  // Helper Function: Validates Nodes
  const validateNodes = (nodes) => {
    return nodes.every(node => node.id && node.entity && Array.isArray(node.attributes));
  };

  // Helper Function: Transforms API Response to UI Node Format
  const transformResponse = (nodes) => {
    return nodes.map(({ id, entity, attributes }, index) => ({
      id,
      type: 'entityNode',
      data: {
        id,
        label: entity,
        attributes,
      },
      position: { 
        x: 50 + index * 200,
        y: 50 + index * 50,
      },
    }));
  };

  // AI ERD Generation
  const generateERD = async () => {
    if (!userStory.trim()) {
      toast.error("Please enter a user story!");
      return;
    }

    setIsGenerating(true);

    try {
      const response = await axios.post(`${API_URL}/ai/generateapp`, { user_story: userStory }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      toast.success("ERD Generated Successfully!");

      // Transform JSON to UI format
      const nodes = transformResponse(response.data.erd.nodes);
      const edges = response.data.erd.relationships;

      if (validateNodes(nodes)) {
        setNodes(nodes);
        setEdges(edges);
      } else {
        toast.error("Invalid AI-generated ERD structure.");
      }

    } catch (error) {
      toast.error("Failed to generate ERD.");
    }

    setIsGenerating(false);
    handleClose(); // Close the drawer after generation
  };

  return (
    <div
 
  >
    <Offcanvas show={show} onHide={handleClose} placement="end"  style={{
      marginTop:'-80px',
      padding:'5px',
      position: 'fixed',
      top: '0px',
      width: '1000px',
      height: '660px', // Full height of the viewport
      backgroundColor: '#f8f9fa',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px', // Add a shadow for a floating effect
      overflowY: 'auto', // Scroll if content overflows
    }}>
      <Offcanvas.Header closeButton className="custom-modal-header">
        <Offcanvas.Title>Generate Application using AI </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form.Group controlId="userStory">
          <Form.Label>Describe Your Use Case / User story</Form.Label>
          <Form.Control
            as="textarea"
            rows={20}
            placeholder="Describe the business process..."
            value={userStory}
            onChange={(e) => setUserStory(e.target.value)}
          />
        </Form.Group>
        <Button 
          variant="primary" 
          onClick={generateERD} 
          disabled={true} 
          className="mt-3"
        >
          {isGenerating ? "Generating..." : "Generate Application - under testing"}
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
    </div>
  );
};

export default AIGenerateDrawer;
