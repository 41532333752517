import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../../axiosConfig';

const FileUpload = ({ showUploadModal, setShowUploadModal, fetchSubmissions, formId, API_URL }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error('Please select a file');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`${API_URL}/forms/${formId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      toast.success('File uploaded successfully');
      fetchSubmissions();
      setShowUploadModal(false);
    } catch (error) {
      toast.error('Failed to upload file');
    }
  };

  return (
    <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} size="lg">
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title>Upload Submissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Select CSV or XLSX File</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFileUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUpload;
