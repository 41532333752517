import React, { useState, useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import axios from '../axiosConfig';
import MessageNotification from './MessageNotification';

const API_URL = process.env.REACT_APP_API_URL;

function Header() {
  const [cuser, setCuser] = useState(null); // State for current user
  const [userPhoto, setUserPhoto] = useState(null); // State for user photo
  const history = useHistory();

  // Fetch user data on component mount
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('currentUser'));
    const storedUserPhoto = localStorage.getItem('userPhoto');

    if (storedUser) {
      // Check if first_name or last_name is null
      if (!storedUser.first_name || !storedUser.last_name) {
        fetchCurrentUser(); // Fetch user data if any name field is missing
      } else {
        setCuser(storedUser);
        setUserPhoto(storedUserPhoto);
      }
    } else {
      fetchCurrentUser();
    }
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/current`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const user = response.data.user;
      const userPhotoUrl = user.profile_photo
        ? `${API_URL.replace('/api/', '/')}${user.profile_photo}`
        : null;

      setCuser(user);
      setUserPhoto(userPhotoUrl);

      // Store user data and photo in localStorage
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('userPhoto', userPhotoUrl || '');
    } catch (error) {
      console.error('Failed to fetch current user', error);
    }
  };

  const handleLogout = () => {
    localStorage.clear(); // Clears all data from localStorage
    history.push('/login');
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <button className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars icon-colored"></i>
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link"></Link>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li><MessageNotification /></li>
        <li className="nav-item dropdown">
          <button className="nav-link" data-toggle="dropdown" role="button">
            {/* Display user photo if available, otherwise show default user icon */}
            {userPhoto ? (
              <img
                id="usr-logo"
                src={userPhoto}
                alt="User"
                className="brand-image img-circle elevation-3 icon-colored "
                style={{ width: '30px', height: '30px' }}
              />
            ) : (
              <i className="fas fa-user-circle fa-lg icon-colored "     style={{ color:'#292b3c!important' }}  ></i>
            )}
         {/*   <span className="ml-2">{cuser ? cuser.username : 'Guest'}</span>*/}
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <Link to="/profile" className="dropdown-item">
              <i  className="fas fa-user icon-colored mr-2"></i> {cuser ? cuser.first_name + " " + cuser.last_name : 'Guest'}
            </Link>
            <Link to="/change-password" className="dropdown-item">
              <i  className="fas fa-key icon-colored mr-2"></i> Change Password
            </Link>
            <div className="dropdown-divider"></div>
            <button onClick={handleLogout} className="dropdown-item">
              <i   className="fas fa-sign-out-alt icon-colored icon-colored  mr-2"></i> Logout
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default withRouter(Header);
