import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';
import Loader from './BLoader'; // Import the Loader component
import zxcvbn from 'zxcvbn';
import Carousel from './Carousel';

const API_URL = process.env.REACT_APP_API_URL;

function Register() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [usernameExists, setUsernameExists] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get('orgId');

  useEffect(() => {
    // Fetch settings here if needed
  }, []);

  useEffect(() => {
    const evaluatePasswordStrength = () => {
      if (password) {
        const evaluation = zxcvbn(password);
        setPasswordStrength(evaluation);
      } else {
        setPasswordStrength(null);
      }
    };

    evaluatePasswordStrength();
  }, [password]);

  const checkUsernameAvailability = async () => {
    if (username) {
      try {
        const response = await axios.get(`${API_URL}/auth/check-username`, {
          params: { username },
        });
        setUsernameExists(response.data.exists);
        if (response.data.exists) {
          toast.error('Username is already taken.');
        }
      } catch (error) {
        toast.error('Failed to check username availability.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(''); // Clear previous success message

    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      setIsLoading(true); // Show the loader while updating
      await axios.post(`${API_URL}/auth/register`, { username, email, password, orgId });
      setIsLoading(false); // Show the loader while updating
      setSuccessMessage('Registration successful. Please verify your email address to login.');
      setTimeout(() => {
      //  history.push('/login'); // Redirect to login after a delay if needed
      }, 3000);
    } catch (error) {
      setIsLoading(false); // Show the loader while updating
      toast.error(error.response?.data?.error || 'Registration failed.');
    }
  };

  const getStrengthColor = (score) => {
    switch (score) {
      case 0:
        return 'red';
      case 1:
        return 'orange';
      case 2:
        return 'yellow';
      case 3:
        return 'lightgreen';
      case 4:
        return 'green';
      default:
        return 'grey';
    }
  };

  const handleShowTermsModal = () => setShowTermsModal(true);
  const handleCloseTermsModal = () => setShowTermsModal(false);

  return (
    <div className="auth-page">
       {isLoading && <Loader />} {/* Show Loader if isLoading is true */}
      {/* Optional carousel or any other component */}
      <div className="login-wrapper">
        <div className="login-box">
          <div className="login-logo">
            <div className="centered-logo-container">
              <div className="logo"></div>
            </div>
            <b>App Gen C</b>
          </div>
          <div className="card">
            <div className="card-body auth-card-body">
              <p className="auth-box-msg">Register a new membership</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={checkUsernameAvailability}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>

                {passwordStrength && (
                  <div className="password-strength-bar">
                    <div
                      className="password-strength-indicator"
                      style={{ backgroundColor: getStrengthColor(passwordStrength.score), width: `${(passwordStrength.score + 1) * 20}%` }}
                    ></div>
                  </div>
                )}

                <div className="row mt-2">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input type="checkbox" id="agreeTerms" name="terms" value="agree" required />
                      <label htmlFor="agreeTerms">
                        I agree to the <a href="#" onClick={handleShowTermsModal}>terms</a>
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                  </div>
                </div>
              </form>

              {successMessage && (
                <div className="alert alert-success mt-3" role="alert">
                  {successMessage}
                </div>
              )}

              <p className="mb-1">
                <Link to="/login">I already have a membership</Link>
              </p>
            </div>
          </div>
        </div>

        <ToastContainer 
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {/* Terms and Conditions Modal */}
        <Modal show={showTermsModal} onHide={handleCloseTermsModal}>
          <Modal.Header closeButton className="custom-modal-header">
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p><strong>Terms and Conditions</strong></p>
<ol>
  <li><strong>Open Source Nature:</strong> This application is open-source and free to use, modify, and distribute under the applicable open-source license. There is no fee associated with the use of this application.</li>
  
  <li><strong>No Warranty:</strong> This application is provided "as is", without any warranty of any kind, either express or implied, including but not limited to the warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>

  <li><strong>Data Backup Responsibility:</strong> The user is solely responsible for maintaining backups of any data used or generated by this application. We do not provide any form of data recovery or backup services.</li>

  <li><strong>Limitation of Liability:</strong> In no event shall the authors or copyright holders be liable for any claim, damages, or other liability, whether in an action of contract, tort, or otherwise, arising from, out of, or in connection with the software or the use or other dealings in the software.</li>

  <li><strong>Usage of Third-Party Services:</strong> This application may integrate with third-party services. The user is responsible for complying with the terms of service and privacy policies of such third-party services.</li>

  <li><strong>Privacy and Data Collection:</strong> We do not collect or store any personal data unless explicitly stated. However, third-party services used by this application may collect user data. Please review their respective privacy policies.</li>

  <li><strong>No Guarantee of Updates:</strong> We do not guarantee that this application will be updated regularly or at all. New features, bug fixes, or security patches may or may not be provided.</li>

  <li><strong>User Contributions:</strong> Any contributions (such as code or bug reports) submitted by users may be used, modified, and incorporated into the project under the same open-source license.</li>

  <li><strong>License Compliance:</strong> Users must comply with the terms of the open-source license under which this application is provided. Misuse or violation of the license may result in legal action.</li>

  <li><strong>Termination of Use:</strong> We reserve the right to terminate or restrict access to the application without prior notice, especially in cases of misuse or violation of terms.</li>
</ol>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTermsModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Register;
