import React from 'react';
//import ChatIcon from './ChatIcon';

const Footer = () => {
  // Retrieve the default app from localStorage
  const defaultApp = (localStorage.getItem('appName'));

  // Set a fallback value in case `defaultApp` is not available
  const appName = defaultApp || 'Your App'; // Replace 'Your App' with your fallback

  return (
    <footer className="main-footer">
    {/*  <ChatIcon />*/}
      <div className="pull-right hidden-xs">
        <b>Version</b> 1.0.0
      </div>
      <strong>
        Copyright &copy; 2024 <a href="">{appName}</a>.
      </strong>{' '}
      All rights reserved.
    </footer>
  );
};

export default Footer;
