import React from 'react';
import { Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'ATTRIBUTE';

const DragHandle = () => <span style={{ cursor: 'grab' }}>☰</span>;

const DraggableRow = ({ index, attr, moveRow, onDelete, onEdit, entities }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const popover = (
    <Popover id={`popover-options-${index}`}>
      <Popover.Header as="h3">Options</Popover.Header>
      <Popover.Body>
        {attr.options ? (
          <div>
            {attr.options.split(',').map((option, idx) => (
              <div key={idx}>{option.trim()}</div>
            ))}
          </div>
        ) : (
          ''
        )}
      </Popover.Body>
    </Popover>
  );

  const formulaPopover = (
    <Popover id={`popover-options-${index}`}>
      <Popover.Header as="h3">Formula</Popover.Header>
      <Popover.Body>
        {attr.formula ? (
          <div>
            {attr.formula}          
          </div>
        ) : (
          ''
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <tr ref={preview} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <td>
        <div ref={ref}>
          <DragHandle />
        </div>
      </td>
      <td>{index + 1}</td>
      <td>{attr.name}</td>
      <td>{attr.datatype}</td>
      <td>{(attr.datatype === 'Lookup' || attr.datatype === 'Multi-Lookup') && attr.entity}</td>
      <td>{(attr.datatype === 'Lookup' || attr.datatype === 'Multi-Lookup') && attr.entityAttribute}</td>
      <td>{attr.visibility === 'True' ? '✔' : '✘'}</td>
      <td>{attr.nullability === 'True' ? '✔' : '✘'}</td>
      <td>{attr.minLength !== null ? attr.minLength : ''}</td>
      <td>{attr.maxLength !== null ? attr.maxLength : ''}</td>
      <td>{attr.caps === 'True' ? '✔' : '✘'}</td>
      <td>
        {attr.formula ? (
          <OverlayTrigger trigger="click" placement="right" overlay={formulaPopover}>
            <Button variant="link" style={{ padding: 0 }}>
              Formula
            </Button>
          </OverlayTrigger>
        ) : (
          ''
        )}
      </td>
      <td>
        {attr.options ? (
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <Button variant="link" style={{ padding: 0 }}>
              Options
            </Button>
          </OverlayTrigger>
        ) : (
          ''
        )}
      </td>
      
      <td>
        <Button variant="primary" size="sm" onClick={() => onEdit(index)}>
          Edit
        </Button>{' '}
        <Button variant="primary" size="sm" onClick={() => onDelete(index)}>
          Delete
        </Button>
      </td>
    </tr>
  );
};

function AttributesTable({
  attributes,
  setAttributes,
  handleAttributeChange,
  deleteAttribute,
  entities = [],
  selectedEntity,
  selectedAttribute,
  onEditAttribute,
}) {
  const moveRow = (dragIndex, hoverIndex) => {
    const updatedAttributes = attributes.slice();
    const [removed] = updatedAttributes.splice(dragIndex, 1);
    updatedAttributes.splice(hoverIndex, 0, removed);
    setAttributes(updatedAttributes);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table striped bordered hover style={{ width: 'max-content' }}>
        <thead>
          <tr>
            <th>Order</th>
            <th>#</th>
            <th>Name</th>
            <th>Datatype</th>
            <th>Entity</th>
            <th>Attribute</th>
            <th>Visibility</th>
            <th>NULL</th>
            <th>Min Length</th>
            <th>Max Length</th>
            <th>Caps</th>
            <th style={{ width: '100px' }}>Formula</th> {/* New column */}
            <th>Options</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {attributes.map((attr, index) => (
            <DraggableRow
              key={index}
              index={index}
              attr={attr}
              moveRow={moveRow}
              onDelete={deleteAttribute}
              onEdit={() => onEditAttribute(index)}
              entities={entities}
            />
          ))}
        </tbody>
      </Table>
    </DndProvider>
  );
}

export default AttributesTable;
