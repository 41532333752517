import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import axios from '../axiosConfig';
import Layout from './Layout';
import Toaster from '../ui-components/Toaster';
import { toast } from 'react-toastify';
import ReusableAccordion from '../ui-components/Accordion';

const API_URL = process.env.REACT_APP_API_URL;

const IntegrationManagement = () => {
    const [integrationCreated, setIntegrationCreated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [integrationDetails, setIntegrationDetails] = useState(null);

    // Check if the integration user exists
    const checkIntegrationUser = async () => {
        try {
            const companyId = localStorage.getItem('company');
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            const username = `${currentUser.username}`;
            const token = localStorage.getItem('token');

            const response = await axios.get(`${API_URL}/integration/exists`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Company-ID': companyId,
                },
                params: { username }, // Pass username as a query parameter
            });

            if (response.data.exists) {
                setIntegrationCreated(true);
                setIntegrationDetails({ userId: response.data.userId, username });
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setIntegrationCreated(false); // User not found
            } else {
                console.error('Error checking integration user:', error.message);
                //toast.error('Failed to check integration user');
            }
        }
    };

    useEffect(() => {
        checkIntegrationUser(); // Check on component mount
    }, []);

    const handleCreateIntegration = async () => {
        try {
            setIsLoading(true);

            const companyId = localStorage.getItem('company');
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            const username = `${currentUser.username}`;
            const token = localStorage.getItem('token');

            const response = await axios.post(
                `${API_URL}/integration/create`,
                { username },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'X-Company-ID': companyId,
                    },
                }
            );

            const { userId, username: integrationUsername, password } = response.data;

            setIntegrationCreated(true);
            setIntegrationDetails({ userId, integrationUsername, password });

            toast.success('Integration created successfully');
        } catch (error) {
            console.error('Error creating integration:', error.message);
            toast.error('Failed to create integration');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSendIntegrationDetails = async () => {
        try {
            setIsLoading(true);

            if (!email.trim()) {
                throw new Error('Email is required');
            }

            await axios.post(
                `${API_URL}/integration/email`,
                {
                    email,
                    userId: integrationDetails.userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            setShowModal(false);
            setEmail('');
            toast.success('Integration details sent successfully');
        } catch (error) {
            console.error('Error sending integration details:', error.message);
            toast.error('Failed to send integration details');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className="content-wrapper1">
                <div className="content1">
                   
                        {!integrationCreated ? (
                            <Button
                                variant="primary"
                                onClick={handleCreateIntegration}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Creating...' : 'Create Integration'}
                            </Button>
                        ) : (
                            <>
                                <p>Integration Ready</p>
                                <Button variant="secondary" onClick={() => setShowModal(true)}>
                                    Share Integration Details
                                </Button>
                            </>
                        )}
                 

                    {/* Modal for Email Input */}
                    <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        aria-labelledby="integration-details-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="integration-details-modal">
                                Share Integration Details
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="integrationEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setShowModal(false)}
                                disabled={isLoading}
                            >
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSendIntegrationDetails}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Sending...' : 'Send'}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Toaster />
                </div>
            </div>
        </div>
    );
};

export default IntegrationManagement;
