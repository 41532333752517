import React from 'react';
import './BLoader.css'; // Import the CSS for the animation

function Loader() {
  return (
    <div className="loader-overlay">
      <div className="rotating-logo"></div>
    </div>
  );
}

export default Loader;
