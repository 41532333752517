import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Layout from './Layout';

const API_URL = process.env.REACT_APP_API_URL;

function FormList() {
  const [forms, setForms] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      const organization = localStorage.getItem('company');
      const response = await axios.get(`${API_URL}/forms`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } ,params: { organization },
      });
      setForms(response.data);
    } catch (error) {
      toast.error('Failed to fetch forms');
    }
  };

  const handleDeleteForm = async (formId) => {
    try {
      const organization = localStorage.getItem('company'); // Assuming the organization ID is stored in localStorage
      await axios.delete(`${API_URL}/forms/${formId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, params: { organization },
      });
      toast.success('Form deleted successfully');
      fetchForms();
    } catch (error) {
      toast.error('Failed to delete form');
    }
  };

  const handleEditForm = (formId) => {
    history.push(`/forms/edit/${formId}`);
  };

  const handleViewForm = (formId) => {
    history.push(`/forms/view/${formId}`);
  };

  const handleCloneForm = async (formId) => {
    try {
      await axios.post(`${API_URL}/forms/clone/${formId}`, null, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Form cloned successfully');
      fetchForms();
    } catch (error) {
      toast.error('Failed to clone form');
    }
  };

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pages List</h1>
              </div>
              <div className="col-sm-8">
                <Button variant="primary" onClick={() => history.push('/forms/create')}>
                  <i className="fas fa-plus"></i> Create Page
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Icon</th>
                  <th>Name</th>
                 
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {forms.map((form) => (
                  <tr key={form.id}>
                    <td style={{width:'10px'}}>{form.id}</td>
                    <td style={{width:'20px'}}><i className={form.icon}></i></td>
                    <td>{form.name}</td>
                    
                    <td style={{width:'200px'}}> 
                      <Button variant="warning" onClick={() => handleEditForm(form.id)}>
                        <i className="fas fa-edit"></i>
                      </Button>
                      <Button variant="danger" onClick={() => handleDeleteForm(form.id)} className="mx-2">
                        <i className="fas fa-trash-alt"></i>
                      </Button>
                      <Button variant="info" onClick={() => handleViewForm(form.id)} className="mx-2">
                        <i className="fas fa-eye"></i>
                      </Button>
                      <Button variant="secondary" onClick={() => handleCloneForm(form.id)}>
                        <i className="fas fa-clone"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default FormList;
